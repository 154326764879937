import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"


export const Features = ({ handleRedirects, features, setIsOpen, bgColor, heading, subHeading }) => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:500px)');
    const navigate = useNavigate()
    const { blogs, products } = useSelector(state => state.appReducer)
    const { user } = useSelector(state => state.userReducer);

    const handleClick = (feature) => {
        if (feature?.link.type === 'Blogs') {
            let data = blogs.filter(workout => workout.id === feature.link.id)
            navigate(`/journal/${feature.link.id}`, { state: { blog: data[0] } })
        } else if (feature?.link.type === "Products") {
            let data = products.filter(workout => workout.id === feature.link.id)
            navigate(`/product/${feature.link.id}`, { state: { product: data[0] } })
        } else if (user) {
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.changeContent());

            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.redirectUser({ id: feature?.link.id, type: feature?.link.type }));
            })
            handleRedirects(user, feature?.link.type, feature?.link.id)
        }
        else {
            setIsOpen(true);
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.changeContent());

            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.redirectUser({ id: feature?.link.id, type: feature?.link.type }));

            })
        }
    }



    return (
        <Grid item container direction='column' className='workout-section main' style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }}>
            <p>{subHeading}</p>
            <h2>{heading}</h2>
            <Grid item container direction={`${matches ? 'column' : 'row'}`} className='workout-section' style={{ margin: '0', padding: '0' }} >
                {features.map((feature, idx) => {
                    return <React.Fragment key={idx} >
                        <Grid item container flex={1} className='recipe' onClick={() => {
                            if (feature.link.type === 'Blogs') {
                                let data = blogs.filter(workout => workout.id === feature.link.id)
                                navigate(`/journal/${feature.link.id}`, { state: { blog: data[0] } })
                            } else if (feature.link.type === "Products") {
                                let data = products.filter(workout => workout.id === feature.link.id)
                                navigate(`/product/${feature.link.id}`, { state: { product: data[0] } })
                            } else if (user) {
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.changeContent());

                                })
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.redirectUser({ id: feature.link.id, type: feature.link.type }));

                                })
                                handleRedirects(user, feature.link.type, feature.link.id)
                            }
                            else {
                                setIsOpen(true);
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.changeContent());

                                })
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.redirectUser({ id: feature.link.id, type: feature.link.type }));

                                })
                            }
                        }}>
                            <div className='imge-container'>
                                <img src={feature.url} alt="fetures" />
                            </div>
                            {feature.heading && feature.subHeading &&
                                <Link to={`${feature.link.type === "Blogs" ? `journal/${feature.link.id}` :
                                    feature.link.type === "Programs" ? 'programs' :
                                        feature.link.type === "Education" ? `education/${feature.link.id}` :
                                            feature.link.type === "Products" ? `product/${feature.link.id}` :
                                                feature.link.type === "Recipes" ? `recipes/${feature.link.id}` :
                                                    feature.link.type === "Workouts" ? `workouts/${feature.link.id}` :
                                                        null
                                    }`}
                                    className='abso'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleClick()
                                    }}>
                                    <h1>{feature.heading}</h1>
                                    <h5>{feature.subHeading}</h5>
                                </Link>
                            }
                        </Grid>
                    </React.Fragment>
                })}
            </Grid>
        </Grid >
    )
}


import styled, { keyframes } from "styled-components";
import { colors } from '../../UI/Theme'
import { slideOutRight } from 'react-animations'
import { slideInRight } from 'react-animations'


const slideRight = keyframes`${slideInRight}`;
const slideOut = keyframes`${slideOutRight}`;

export const Wrapper = styled.section`
.main-container{
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    margin:0 ;
    z-index: 2;
    height: 100vh;
    background:rgba(206,208,212,0.5) ;
    .cart{
        width:29%;
        height:100vh;
        position:absolute;
        right:0;
        z-index: 4;
        background-color:rgba(255,255,255,1) ;
        animation:0.3s ${slideRight};
        /* animation:0.3s ${slideOut}; */
        div{
            width:90%;
            margin:0 auto;
        }
        .heading-section{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex:1;
            flex-shrink:1 ;
            border-bottom:1px solid #ece4d4 ;
            h2{
                font-family:${colors.fonts.garacie} ;
                font-weight: 500;
                font-size: 20px;
                line-height: 14px;
                letter-spacing: 0.02em;
            }
            .close-icon{
                color:#404040;
                margin-bottom:0.2em;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .cart-items{
            display: flex;
            flex-direction:column ;
            flex:5;
            gap:20px;
            overflow: auto;
            width: 100%;
            margin:2em 0 ;
            .item{
                display: flex;
                position: relative;
                gap:20px;
                .button{
                    position: absolute;
                    right:0;
                    width:27px;
                    height:27px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius:5px;
                    border:none;
                    background-color:#fff;
                    top:5px;
                    &:hover{
                        cursor: pointer;
                        background-color:${colors.themeColor.color} ;
                        color:#fff;
                    }
                    .remove{
                        font-size:25px;
                        font-weight:bold;
                    }
                }
                .img-container{
                    width:90px;
                    display: flex;
                    height:120px;
                    img{
                        width:100%;
                        height:100%;
                        object-fit: cover;
                    }
                }
                .info-container{
                    display: flex;
                    flex-direction:column;
                    height:70%;
                    margin:auto 0;
                    margin-right:1em;
                    flex:1;
                    justify-content:space-between;
                    .bottom{
                        display: flex;
                        justify-content:space-between;
                        align-items: center;
                        width:100%;
                        .btn-container{
                            display: flex;
                            flex:1;
                            height:40px;
                            width:100px;
                            align-items: center;
                            justify-content: center;
                            border:1px solid ${colors.themeColor.color};
                            button{
                                flex:1;
                                height:101%;
                                width:103%;
                                position:relative;
                                margin-left:-1px;
                                border: none;
                                &:first-child{
                                    border-left:1px solid ${colors.themeColor.color};
                                }
                                background:#fff ;
                                &:hover{
                                    background-color:${colors.themeColor.color} ;
                                    color:#fff;
                                    cursor:pointer ;
                                }
                                .btn-icon{
                                    font-size:16px ;
                                    margin-top:0.2em;
                                }
                            }
                            p{
                                flex:2;
                                text-align: center;
                            }
                        }
                        p{
                            flex:2;
                            text-align:right;
                        }
                    }
                }
            }
            &::-webkit-scrollbar {
                        width: 5px;
                    }
                &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px ${colors.themeColor.themeBackgroundColor};
                        border-radius: 15px;
                    }
                &::-webkit-scrollbar-thumb {
                        background: ${colors.themeColor.color};
                        border-radius: 10px;
                    }
            .no-items{
                height:100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap:10px;
                button{
                    height:30px;
                    padding:0 10px;
                    border-radius:5px;
                    border:none ;
                    background-color:${colors.themeColor.color} ;
                    color:#fff;
                    &:hover{
                        cursor: pointer ;
                    }
                }
            }
        }
        .total-container{
            flex:1.8;
            border-top:1px solid #ece4d4 ;
            .sub-total{
                margin-top:1em;
                width:100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    font-family:${colors.fonts.garacie} ;
                    margin-top:0.3em;
                }
                .amt{
                    font-family:${colors.fonts.commutersBold} ;
                    margin-top:0 ;
                }
            }
            .shipping{
                font-size:11px;
                text-align:center;
                margin:1em;
                color:#333;
            }
            button{
                width:100%;
                height:45px;
                font-family:${colors.fonts.commutersBold} ;
                font-weight:800 ;
                font-size:18px;
                border:none;
                margin-bottom:1em;
                background-color:${colors.themeColor.color} ;
                color:#fff;
                &:hover{
                    cursor:pointer;
                }
            }
            .btn-disabled{
                background-color:#999 ;
            }
        }
    }
    .close{
        animation:0.3s ${slideOut};
    }
}

@media only screen and (max-width:1200px){
    .main-container{
    .cart{
        width:40%;
    }
}
}
@media only screen and (max-width:993px){
    .main-container{
    .cart{
        width:48%;
        .cart-items{
            gap:15px;
            margin:1em 0 ;
            .item{
                gap:10px;
                .img-container{
                    width:80px;
                    height:100px;
                }
                .info-container{
                    .bottom{
                        .btn-container{
                            height:30px;
                            button{
                                .btn-icon{
                                    font-size:14px ;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}
@media only screen and (max-width:768px){
    .main-container{
    .cart{
        width:58%;
        .heading-section{
            h2{
                font-size:18px;
            }
        }
        .cart-items{
            gap:15px;
            margin:1em 0 ;
            .item{
                width:95%;
                gap:7px;
                .button{
                    top:0;
                }
                .img-container{
                    width:50px;
                    height:70px;
                }
                .info-container{
                    height:80%;
                    margin-right:1.3em;
                    a{
                        width:fit-content;
                        font-size:14px;
                        color:#111;
                    }
                    .bottom{
                        .btn-container{
                            height:25px;
                            button{
                                .btn-icon{
                                    font-size:14px ;
                                }
                            }
                            p{
                                font-size:14px;
                            }
                        }
                        p{
                            font-size:14px;
                        }
                    }
                }
            }
        }
        .total-container{
            .sub-total{
                p{
                    font-size:14px;
                }
            }
            .shipping{
                font-size:10px;
                margin:0.5em;
            }
            button{
                height:40px;
                font-size:16px;
            }
        }
    }
}
}


@media only screen and (max-width:500px){
    .main-container{
    .cart{
        width:100%;
        .heading-section{
            h2{
                font-size:18px;
            }
        }
        .cart-items{
            gap:15px;
            margin:1em 0 ;
            .item{
                width:95%;
                gap:7px;
                .button{
                    top:0;
                }
                .img-container{
                    width:60px;
                    height:90px;
                }
                .info-container{
                    height:90%;
                    margin-right:1.3em;
                    a{
                        width:fit-content;
                        font-size:14px;
                        color:#111;
                    }
                    .bottom{
                        .btn-container{
                            height:35px;
                            button{
                                .btn-icon{
                                    font-size:14px ;
                                }
                            }
                            p{
                                font-size:14px;
                            }
                        }
                        p{
                            font-size:14px;
                        }
                    }
                }
            }
        }
        .total-container{
            margin-bottom:1em;
            .sub-total{
                p{
                    font-size:14px;
                }
            }
            .shipping{
                font-size:10px;
                margin:0.5em;
            }
            button{
                height:40px;
                font-size:16px;
            }
        }
    }
}
}
`;
import { Mixpanel } from "./mixPanel";

async function addMixpanelEvent({ uid, firstName, lastName, event }) {
    return new Promise((resolve) => {
        Mixpanel.identify(uid);
        Mixpanel.track(event);
        Mixpanel.people.set({
            $first_name: firstName,
            $last_name: lastName,
        });
        resolve(true);
    })

}

export default addMixpanelEvent;
// import { httpsCallable } from 'firebase/functions';
import React from 'react'
// import { useEffect } from 'react';
// import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import { functions } from '../firebase-config';
import { Wrapper } from './styles/subscriptionModalStyles'

const SubscriptionModal = ({ setIsSubscriptionModalOpen }) => {
    // const [subscriptionDuration, setSubscriptionDuration] = useState('Monthly');
    // const [subscription, setSubscription] = useState('Free');
    const navigate = useNavigate();
    // const [finalSubscription, setFinalSubscription] = useState('Free-Plan-AUD-Monthly')
    // const chargeBeeCheckout = httpsCallable(functions, 'chargebeeCheckout');
    // const cbInstance = window.Chargebee.init({
    //     site: "inflowactive-test"
    // })
    // useEffect(() => {
    //     setFinalSubscription(`${subscription}-Plan-AUD-${subscriptionDuration}`)
    // }, [subscription, subscriptionDuration])

    // const handleSubscriptionSelection = (sub) => {
    //     setSubscription(sub);
    // }


    const handleSubscriptionBuy = async (e) => {
        e.preventDefault();
        setIsSubscriptionModalOpen(false)
        navigate('/classes')
        // const uid = localStorage.getItem('@auth-token')
        // console.log('ascascaasc', uid);
        // if (!uid) return;

        // if (finalSubscription.match('Free-Plan')) {
        //     setIsSubscriptionModalOpen(false)
        //     return;
        // }
        // console.log({ uid });
        // cbInstance.openCheckout({
        //     hostedPage: async () => {
        //         let data = {
        //             plan_id: finalSubscription,
        //             uid: uid,
        //             redirect_url: window.location.origin + '/subscription-success/werty653_zxcvbn',
        //             cancel_url: window.location.origin + '/subscription-fail/werty653_zxcvbnbaisjbcius',
        //         }
        //         return chargeBeeCheckout(data).then((res) => {
        //             return res.data.result
        //         })
        //     },
        //     success: () => {
        //         navigate('/');
        //     },
        //     close: () => {
        //         console.log("checkout new closed");
        //     },
        //     error(error) {
        //         window.alert('Payment was unsuccessfull');
        //     }
        // })
    }

    return (
        <Wrapper>
            <div className="main-container">
                <p className='note'>Welcome to Core Sculpt</p>
                <p className='note-2'>We are so excited to take you on the Core Sculpt Journey. As a thank you for signing up we have created a FREE membership for you to enjoy, with tasters of each of our Core Sculpt elements. We know you will love it so much you'll keep coming back for more!</p>
                <div className="subscriptions-container">
                    <div className="sub selectedSub">
                        <h2 className='sub-head'>Free Membership</h2>
                        <p className='abso'>Default</p>
                        <p className='sub-para'><span className='dot' ></span> Unlimited access to over 15 classes.</p>
                        <p className='sub-para'><span className='dot' /> Step by step program to get you started</p>
                        <p className='sub-para'><span className='dot' /> Taster of our nutritious recipes.</p>
                        <p className='sub-para'><span className='dot' /> Flexible upgrade and downgrade options</p>
                        <p className='sub-para'><span className='dot' /> A$0/month</p>
                    </div>
                    <div className="sub" >
                        <h2 className='sub-head'>Gold Membership</h2>
                        <p className='sub-para'><span className='dot' ></span> Unlimited access to over 100 classes.</p>
                        <p className='sub-para'><span className='dot' />Unlimited access to step by step program to target your goals</p>
                        <p className='sub-para'><span className='dot' /> Access to our beginner educational library</p>
                        <p className='sub-para'><span className='dot' /> Access to our library of nutritious recipes.</p>
                        <p className='sub-para'><span className='dot' /> Flexible upgrade and downgrade options</p>
                        <p className='sub-para'><span className='dot' /> A$25.95/month or $175/year</p>
                    </div>
                    <div className="sub " >
                        <h2 className='sub-head'>Platinum Membership</h2>
                        <p className='sub-para'><span className='dot' ></span> Unlimited access to over 150 classes.</p>
                        <p className='sub-para'><span className='dot' />Unlimited access to step by step and specialist programs to target your goals</p>
                        <p className='sub-para'><span className='dot' /> Access to our full educational library</p>
                        <p className='sub-para'><span className='dot' />Access to our full library of nutritious recipes.</p>
                        <p className='sub-para'><span className='dot' /> Flexible upgrade and downgrade options</p>
                        <p className='sub-para'><span className='dot' /> A$45.95/month or $225/year</p>
                    </div>
                </div>
                {/* <div className='btn-container duration'>
                    <div className={subscriptionDuration === 'Monthly' ? 'selected' : 'choice'} onClick={() => setSubscriptionDuration('Monthly')} >Monthly</div>
                    <div className={subscriptionDuration !== 'Monthly' ? 'selected' : 'choice'} onClick={() => setSubscriptionDuration('Yearly')}>Yearly</div>
                </div> */}
                <div className='btn-container' >
                    <Link to="/subscriptions" onClick={handleSubscriptionBuy} className='btn'>Your Core Sculpt Journey Starts here!</Link>
                </div>
            </div>
        </Wrapper >
    )
}

export default SubscriptionModal
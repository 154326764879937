import { Alert, CircularProgress, Grid, Snackbar, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { Navigation } from '../../components/Navigation'
import { Wrapper } from './PaymentSuccessFailedStyles'
import paymentSuccess2 from "../../assets/payment-success-2.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDoc, doc, where, getDocs, query, collection, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Loading } from '../../components/Loading'

const PaymentSuccess = () => {
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [lastOrderId, setLastOrderId] = useState(null)
    const { fromCart } = useSelector(state => state.cartReducer)
    const [deliveryNote, setDeliveryNote] = useState('')
    const [isSent, setIsSent] = useState(false);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [productMsgs, setProductMsgs] = useState([])
    const [msgLoading, setMsgLoading] = useState(false)

    const fetchLastOrderId = async () => {
        const lastOrderId = await getDoc(doc(db, "ordersMetaData", "metaData"));
        setLastOrderId(lastOrderId.data().lastOrderId);
    }

    const fetchProductMessages = async () => {
        try {

            setMsgLoading(true)
            let arr = id.split('&');
            let arr2 = []
            for (const ids of arr) {
                const data = await getDoc(doc(db, 'Products', ids));
                let res = data.data();
                if (res.successfulPurchaseMmsg) {
                    arr2.push({ name: res.name, msg: res.successfulPurchaseMmsg })
                }
            }
            setProductMsgs(arr2)
            setMsgLoading(false)
        } catch (error) {
            setMsgLoading(false)
        }
    }

    console.log({ productMsgs });

    useEffect(() => {
        fetchLastOrderId()
        fetchProductMessages()
        if (fromCart === "true") {
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.clearCart())
            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.makeFromCartFalse())
            })
        }
    }, []);


    const handleAddDeliveryNote = async () => {
        let arr = [];
        if (!lastOrderId) return window.alert('Something went wrong!');
        setLoading(true)
        const q = query(collection(db, "Orders"), where("orderId", "==", lastOrderId))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.id)
        });
        await setDoc(doc(db, "Orders", arr[0]), { deliveryNote: deliveryNote }, { merge: true })
        setDeliveryNote('')
        setLoading(false)
        setIsSent(true)
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    }

    if (msgLoading) return <Loading />

    return (
        <>
            <Navigation heading="PAYMENT SUCCESS" />
            <Wrapper>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open} autoHideDuration={5000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Delivery Note Added.
                    </Alert>
                </Snackbar>
                <Grid container className='main-container'>
                    <div className='images-container success-images'>
                        <div className="left-image-container">
                            <img src={paymentSuccess2} className='image2' alt="" />
                        </div>
                    </div>
                    <div className='message-container success'>


                        <h4>Payment Success</h4>
                        <p>Your payment was successful</p>

                        {productMsgs.length !== 0 &&
                            <div className='msg-container'>
                                <ul>
                                    {productMsgs.map((item, idx) => {
                                        return <li style={{ marginTop: "1em" }}> <span>{item.name}</span> - {item.msg}</li>
                                    })}
                                </ul>
                            </div>
                        }

                        <button
                            onClick={() => navigate('/shop')}
                        >
                            <Link to='/shop'>
                                Browse Products
                            </Link>
                        </button>
                        {!isSent &&
                            <div className='note'>
                                <input type="text" value={deliveryNote} onChange={(e => setDeliveryNote(e.target.value))} placeholder='Add a delivery note' />
                                <button className='btn' onClick={handleAddDeliveryNote}>{loading ?
                                    <CircularProgress
                                        size={25}
                                        style={{ marginTop: '4px', color: '#fff' }}
                                    />
                                    : 'Submit'}</button>
                            </div>
                        }

                    </div>
                </Grid>

            </Wrapper>
        </>
    )
}
export default PaymentSuccess;
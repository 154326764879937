import styled from "styled-components";
import { colors } from "../../UI/Theme"

export const Wrapper = styled.div`
    position: relative ;
    .container{
        background-color: ${colors.themeColor.color} ;
        height:40px ;
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            .heading{
                color:#fff ;
                font-size:15px;
            }
        }
    }
`;
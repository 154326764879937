import { Collapse, Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { Wrapper } from './styles/SidemenuStyles'
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import logo from "../assets/logo.png"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FiPhoneCall } from "react-icons/fi";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import {
    signOut,
} from "firebase/auth";
import { auth } from '../firebase-config';

export const Sidemenu = ({ setIsOpen }) => {
    const { homeData, menuCheck } = useSelector(state => state.appReducer);
    const { user } = useSelector(state => state.userReducer);
    const [openMyAccount, setOpenMyAccount] = useState(false);
    const [openMyAccess, setOpenMyAccess] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const handleClose = () => {
        import('../app/actions/userActions').then(userActions => {
            return dispatch(userActions.menuClose())
        })
        setTimeout(() => {
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.toggleMenu())
            })
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.menuOpen())
            })
        }, 300);
    }
    const handleMyAccount = () => {
        setOpenMyAccount(!openMyAccount);
    };
    const handleMyAccess = () => {
        setOpenMyAccess(!openMyAccess);
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.logoutUser())
            })
            localStorage.removeItem('@auth-token')
            handleClose();
            navigate('/')
        }).catch(error => {
            console.log('logout err', error);
        })
    }

    if (!homeData) return null;
    const footer = homeData.sections.filter(section => section.widgetType?.toLowerCase() === 'footer')[0].items[0];
    return (
        <Wrapper>
            <Grid container className='main-container' direction='column'>
                <Grid item container direction='column' className={`${menuCheck ? 'cart close' : 'cart'}`}>
                    <div className="heading-section">
                        <img src={logo} alt="" />
                    </div>
                    <div className="links-container">
                        <List
                            sx={{ width: '100%', bgcolor: '#fff' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <ListItemButton onClick={handleMyAccount}>
                                <Link to='/' onClick={e => e.preventDefault()} className={`${openMyAccount ? 'main-link-selected' : null}`}>
                                    My Account
                                    {openMyAccount ? <RemoveIcon /> : <AddIcon />}
                                </Link>
                            </ListItemButton>
                            <Collapse in={openMyAccount} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {user ?
                                        <>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link
                                                    className='nested-links'
                                                    onClick={handleClose} to="/profile"> <span></span>Profile</Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link
                                                    className='nested-links'
                                                    onClick={handleClose} to="/orders-history"> <span></span>My Orders</Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link
                                                    className='nested-links'
                                                    style={{ marginBottom: '1em' }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleLogout();
                                                    }}
                                                    to='/'
                                                > <span></span>Logout</Link>
                                            </ListItemButton>
                                        </>
                                        :
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <Link
                                                to='/'
                                                className='nested-links'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClose();
                                                    setIsOpen(true);
                                                }}>
                                                <span></span> Login/Signup
                                            </Link>
                                        </ListItemButton>
                                    }
                                </List>
                            </Collapse>

                            <ListItemButton>
                                <Link onClick={handleClose} to='/' className={`${location.pathname === '/' ? 'main-link-selected' : null}`}>
                                    Home
                                </Link>
                            </ListItemButton>
                            {user &&
                                <>
                                    <ListItemButton onClick={handleMyAccess}>
                                        <Link onClick={e => e.preventDefault()} to='/'
                                            className={`${location.pathname === '/classes' ||
                                                location.pathname.search(new RegExp('recipes')) === 1 ||
                                                location.pathname.search(new RegExp('workouts')) === 1 ||
                                                location.pathname.search(new RegExp('program')) === 1 ||
                                                location.pathname.search(new RegExp('education')) === 1 ||
                                                location.pathname.search(new RegExp('classes')) === 1 ||
                                                location.pathname.search(new RegExp('calender')) === 1 ?
                                                'main-link-selected' :
                                                null
                                                }`}
                                        >
                                            My Access
                                            {openMyAccess ? <RemoveIcon /> : <AddIcon />}
                                        </Link>
                                    </ListItemButton>
                                    <Collapse in={openMyAccess} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link className={`${location.pathname.search(new RegExp('classes')) === 1 ||
                                                    location.pathname === '/classes' ? 'nested-links selected'
                                                    : 'nested-links'
                                                    }`} onClick={handleClose} to="/classes">
                                                    <span></span> Classes
                                                </Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link
                                                    className={`${location.pathname.search(new RegExp('programs')) === 1 ?
                                                        "nested-links selected"
                                                        : 'nested-links'
                                                        }`}
                                                    onClick={handleClose} to="/programs">
                                                    <span></span>Programs
                                                </Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link className={`${location.pathname.search(new RegExp('recipes')) === 1 ||
                                                    location.pathname === '/recipes' ? 'nested-links selected'
                                                    : 'nested-links'
                                                    }`} onClick={handleClose} to="/recipes">
                                                    <span></span>Recipes
                                                </Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link className={`${location.pathname.search(new RegExp('education')) === 1 ||
                                                    location.pathname === '/education' ? 'nested-links selected'
                                                    : 'nested-links'
                                                    }`} onClick={handleClose} to="/education"> <span></span>Education</Link>
                                            </ListItemButton>
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <Link className={`${location.pathname.search(new RegExp('calender')) === 1 ||
                                                    location.pathname === '/calender' ? 'nested-links selected'
                                                    : 'nested-links'
                                                    }`} style={{ marginBottom: '1em' }} onClick={handleClose} to="/calender">
                                                    <span></span>
                                                    Calender
                                                </Link>
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </>
                            }

                            <ListItemButton>
                                <Link onClick={handleClose} to='/journal' className={`${location.pathname.search(new RegExp('journal')) === 1 ? 'main-link-selected' : null}`} >
                                    JOURNAL
                                </Link>
                            </ListItemButton>
                            <ListItemButton>
                                <Link onClick={handleClose} to='/shop' className={`${location.pathname.search(new RegExp('shop')) === 1 ||
                                    location.pathname.search(new RegExp('product')) === 1
                                    ? 'main-link-selected' : null}`}>
                                    SHOP
                                </Link>
                            </ListItemButton>
                            <ListItemButton className='last-item'>
                                <Link to='/' onClick={(e) => e.preventDefault()} className='help'>
                                    <h3>Need Help ?</h3>
                                    <div className="contact-info">
                                        {footer.contactUs.phoneNo &&
                                            <p> <FiPhoneCall className='phone' />{footer.contactUs.phoneNo}</p>
                                        }
                                        {footer.contactUs.email &&
                                            <p> <MailOutlinedIcon className='mail' />{footer.contactUs.email}</p>
                                        }
                                    </div>
                                </Link>
                            </ListItemButton>
                        </List>
                    </div>
                </Grid>
            </Grid>
            <CloseIcon onClick={() => {
                handleClose()
            }} className='close-icon' />
        </Wrapper >
    )
}

import { ActionTypes } from "../constants/action-types"

const getLocalStorage = () => {
    let cart = localStorage.getItem('cart')
    if (cart) {
        return JSON.parse(localStorage.getItem('cart'))
    } else {
        return []
    }
}
const getFromLocalStorage = () => {
    let check = localStorage.getItem('cart-check')
    if (check) {
        return check;
    } else {
        return false
    }
}

const initialState = {
    cart: getLocalStorage(),
    total_items: 0,
    total_amount: 0,
    shipping_fee: 0,
    isCartOpen: false,
    closeCheck: false,
    fromCart: getFromLocalStorage()
}


export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CART_OPEN:
            return { ...state, isCartOpen: true }
        case ActionTypes.CART_CLOSE:
            return { ...state, isCartOpen: false }
        case ActionTypes.ADD_TO_CART:
            const { id, amount, product, shippingCost, selectProgramId } = action.payload
            const tempItem = state.cart.find(i => i.id === id)
            if (tempItem) {
                const tempCart = state.cart.map(cartItem => {
                    if (cartItem.id === id) {
                        let newAmount = cartItem.quantity + amount
                        if (newAmount > product.stock) {
                            newAmount = product.stock
                        }
                        return { ...cartItem, quantity: newAmount }
                    } else {
                        return cartItem
                    }
                })
                return { ...state, cart: tempCart }
            } else {
                const newItem = {
                    id: id,
                    quantity: amount,
                    price: product.discountedPrice || product.price,
                    image: product.coverImage,
                    name: product.name,
                    shippingCost: shippingCost,
                    selectProgramId
                }
                return { ...state, cart: [...state.cart, newItem] }
            }
        case ActionTypes.REMOVE_CART_ITEMS:
            const tempCart = state.cart.filter(item => item.id !== action.payload)
            return { ...state, cart: tempCart }

        case ActionTypes.TOGGLE_CART_ITEM_AMOUNT:
            const { productId, value, data } = action.payload
            const temp = state.cart.map(item => {
                if (item.id === productId) {
                    if (value === 'inc') {
                        let newAmount = item.quantity + 1
                        if (newAmount > data.stock) {
                            newAmount = data.stock
                        }
                        return { ...item, quantity: newAmount }
                    }
                    if (value === 'dec') {
                        if (value === 'dec') {
                            let newAmount = item.quantity - 1
                            if (newAmount < 1) {
                                newAmount = 1
                            }
                            return { ...item, quantity: newAmount }
                        }
                    }
                }
                return item
            })
            return { ...state, cart: temp }

        case ActionTypes.COUNT_CART_TOTALS:
            const { total_items, total_amount } = state.cart.reduce(
                (total, cartItem) => {
                    const { quantity, price } = cartItem
                    total.total_items += quantity
                    total.total_amount += quantity * price
                    return total
                },
                {
                    total_items: 0,
                    total_amount: 0
                }
            )
            return { ...state, total_items, total_amount }
        case ActionTypes.CLOSE:
            return { ...state, closeCheck: true }
        case ActionTypes.OPEN:
            return { ...state, closeCheck: false }
        case ActionTypes.CLEAR_CART:
            return { ...state, cart: [] }
        case ActionTypes.FROM_CART:
            return { ...state, fromCart: true }
        case ActionTypes.FROM_CART_FALSE:
            return { ...state, fromCart: false }

        case ActionTypes.SET_CART:
            return { ...state, cart: action.payload }
        default:
            return state
    }
}
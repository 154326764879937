import { ActionTypes } from "../constants/action-types"

const initialState = {
    type: '',
    id: ''
}

export const redirectReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.REDIRECTS:
            return {
                ...state, type: action.payload.type, id: action.payload.id
            }
        default:
            return state
    }
}
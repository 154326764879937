import React, { useEffect, useState, Suspense, lazy } from "react";
import "@stripe/stripe-js";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Header } from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { DemoPage } from "./pages/DemoPage";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db, functions } from "./firebase-config";
import PrivateRoute from "./components/PrivateRoute";
import Cart from "./components/Cart";

import { Loading } from "./components/Loading";

import { Default } from "./pages/Default.jsx";
import { Sidemenu } from "./components/Sidemenu";
import { getCart } from "./app/actions/userActions";
import { Section0 } from "./components/Home/Section0";
import { useCallback } from "react";
import Footer from "./components/Footer";
import SubscriptionFailed from "./pages/Subscriptions/SubscriptionFailed"
import SubscriptionSuccess from "./pages/Subscriptions/SubscriptionSuccess"
import PaymentSuccess from "./pages/Shop/PaymentSuccess";
// import PaymentFailed from "./pages/Shop/PaymentFailed";
import { Backdrop } from "@mui/material";
import SubscriptionModal from "./components/SubscriptionModal";
import { httpsCallable } from "firebase/functions";
import SeatMap from "./pages/seatmap/SeatMap";
import { setLastAccessedDate } from "./utils/services";

const Classes = lazy(() => import("./pages/Classes/Classes"));
const SubscriptionSelection = lazy(() =>
  import("./pages/Subscriptions/SubscriptionSelection")
);
const WorkoutInfoScreen = lazy(() =>
  import("./pages/Workout/WorkoutInfoScreen")
);
const ProgramWorkouts = lazy(() => import("./pages/Workout/ProgramWorkouts"));
const AllWorkouts = lazy(() => import("./pages/Workout/AllWorkouts"));
const WorkoutPlayScreen = lazy(() =>
  import("./pages/Workout/WorkoutPlayScreen")
);
const ProgramAll = lazy(() => import("./pages/Program/ProgramAll"));
const Programs = lazy(() => import("./pages/Program/Programs"));
const NutritionSelection = lazy(() =>
  import("./pages/Recipes/NutritionSelection")
);
const AllRecipes = lazy(() => import("./pages/Recipes/AllRecipes"));
const ProgramRecipes = lazy(() => import("./pages/Recipes/ProgramRecipes"));
const Recipe = lazy(() => import("./pages/Recipes/Recipe"));
const Calender = lazy(() => import("./pages/Calender/Calender"));
const Education = lazy(() => import("./pages/Education/Education"));
const Article = lazy(() => import("./pages/Journal/Article"));
const Product = lazy(() => import("./pages/Shop/Product"));
const EducationInfoScreen = lazy(() =>
  import("./pages/Education/EducationInfoScreen")
);
const Journal = lazy(() => import("./pages/Journal/Journal"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Recipes = lazy(() => import("./pages/Recipes/Recipes"));

const Profile = lazy(() => import("./pages/Profile/Profile"));
// const PaymentSuccess = lazy(() => import("./pages/Shop/PaymentSuccess"));
const OrderDetails = lazy(() => import("./pages/Shop/OrderDetails"));
const OrderHistory = lazy(() => import("./pages/Shop/OrderHistory"));
// const PaymentFailed = lazy(() => import("./pages/Shop/PaymentFailed"));
const ProgramInfoScreen = lazy(() =>
  import("./pages/Program/ProgramInfoScreen")
);
const AllPrograms = lazy(() => import("./pages/Program/AllPrograms"));

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)
  const { cart, isCartOpen, fromCart } = useSelector(
    (state) => state.cartReducer
  );
  const { isMenuOpen, homeData } = useSelector((state) => state.appReducer);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const fetchAllPlans = httpsCallable(functions, 'chargebeeFetchAllPlans')


  const fetchProducts = async () => {
    try {
      const collectionRef = query(
        collection(db, `Products`),
        where("status", "==", true)
      );
      const querySnapshot = await getDocs(collectionRef);
      let arr = [];
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      import("./app/actions/userActions").then((userActions) => {
        return dispatch(userActions.productsSuccess(arr));
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function getHomeData() {
    await getHome();
  }
  const getHome = async () => {
    try {
      setLoading(true);
      const userRef = doc(db, "Pages", "home");
      const docs = await getDoc(userRef);
      import("./app/actions/userActions").then((userActions) => {
        return dispatch(userActions.homePage(docs.data()));
      });
      const journalRef = doc(db, "Pages", "journal");
      const journalDoc = await getDoc(journalRef);
      import("./app/actions/userActions").then((userActions) => {
        return dispatch(userActions.getJournalData(journalDoc.data()));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscriptions = async () => {
    const res = await fetchAllPlans();
    import('./app/actions/userActions').then(userActions => dispatch(userActions.allPlans(res.data.list)))
  }

  useEffect(() => {
    getHomeData();
    getSubscriptions();
    if (user) {
      cartData();
    }
    setLastAccessedDate()
    // return ;
  }, []);

  const cartData = useCallback(async () => {
    const uid = localStorage.getItem("@auth-token");
    const docRef = doc(db, `Users/${uid}/Cart`, "cart");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      getCart(docSnap.data());
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }, []);

  useEffect(() => {
    import("./app/actions/userActions").then((userActions) => {
      return dispatch(userActions.countCartTotal());
    });
    localStorage.setItem("cart", JSON.stringify(cart));
    if (user) {
      setCartToDatabase();
    }
  }, [cart]);

  const setCartToDatabase = async () => {
    console.log("inside set cart");
    const uid = localStorage.getItem("@auth-token");
    try {
      await setDoc(doc(db, `Users/${uid}/Cart`, "cart"), { cart });
      console.log("doc added");
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    localStorage.setItem("cart-check", fromCart);
  }, [fromCart]);

  const checkUserInDb = async (uid) => {
    setLoading(true);
    await fetchProducts();
    if (uid) {
      const unsub = onSnapshot(doc(db, "Users", uid), (doc) => {
        if (!doc.exists) {
          setLoading(false);
          return false;
        } else {
          import("./app/actions/userActions").then((userActions) => {
            return dispatch(userActions.userFound(doc.data()));
          });
          setLoading(false);
          return true;
        }
      });
    } else {
      setLoading(false);
      return;
    }
  };

  React.useEffect(() => {
    try {
      const users = localStorage.getItem("@auth-token");
      if (users) {
        checkUserInDb(users);
      }
    } catch (error) {
      console.log("cant find user", error);
    }
  }, []);

  const toggleOpen = () => {
    if (isCartOpen) {
      import("./app/actions/userActions").then((userActions) => {
        return dispatch(userActions.cartClose());
      });
    } else {
      import("./app/actions/userActions").then((userActions) => {
        return dispatch(userActions.cartOpen());
      });
    }
  };

  const body = document.querySelector("body");
  const showModal = useCallback(() => {
    if (isCartOpen || isMenuOpen || isSubscriptionModalOpen) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  }, [isCartOpen, isMenuOpen, body.style, isSubscriptionModalOpen]);

  useEffect(() => {
    showModal();
  }, [isCartOpen, isMenuOpen, showModal]);

  if (loading && !user && !homeData) {
    return <Loading />;
  }





  const seats = [
    [
      {
        id: "21A",
        available: true
      },
      {
        id: "21B",
        available: true
      },
      {
        id: "21C",
        available: true
      },
      {
        id: "21D",
        available: true
      },
      {
        id: "21E",
        available: true
      },
      {
        id: "21F",
        available: true
      },
    ],
    [
      {
        id: "22A",
        available: true
      },
      {
        id: "22B",
        available: true
      },
      {
        id: "22C",
        available: true
      },
      {
        id: "22D",
        available: true
      },
      {
        id: "22E",
        available: false
      },
      {
        id: "22F",
        available: false
      },
    ],
  ]

  return (
    <div className="App">
      <Section0 />
      {isCartOpen && (
        <Cart
          check={open}
          setIsOpen={setIsOpen}
          setOpen={setOpen}
          open={isCartOpen}
          toggleOpen={toggleOpen}
        />
      )}
      {isMenuOpen && <Sidemenu setIsOpen={setIsOpen} />}
      <Header isOpen={isOpen} setIsOpen={setIsOpen} setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
      <Backdrop
        style={{ zIndex: '2' }}
        open={isSubscriptionModalOpen}
      >
        <SubscriptionModal setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
      </Backdrop>

      <Suspense fallback={<Loading />}>
        <Routes>
          <>
            {/* classes and workouts */}

            {user && (
              <>
                <Route
                  path="/"
                  element={<Home isOpen={isOpen} setIsOpen={setIsOpen} />}
                />
                <Route
                  path="/classes"
                  element={
                    <PrivateRoute user={user}>

                      <Classes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workouts/:id"
                  element={
                    <PrivateRoute user={user}>
                      <WorkoutInfoScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workouts/all"
                  element={
                    <PrivateRoute user={user}>
                      <AllWorkouts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/program/workouts/:id"
                  element={
                    <PrivateRoute user={user}>
                      <ProgramWorkouts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workouts/play/:id"
                  element={
                    <PrivateRoute user={user}>
                      <WorkoutPlayScreen />
                    </PrivateRoute>
                  }
                />

                {/* recipes  */}
                <Route
                  path="/recipes/all"
                  element={
                    <PrivateRoute user={user}>
                      <AllRecipes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/recipes"
                  element={
                    <PrivateRoute user={user}>
                      <NutritionSelection />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/recipes/:id"
                  element={
                    <PrivateRoute user={user}>
                      <Recipes />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/recipes/:id/:id"
                  element={
                    <PrivateRoute user={user}>
                      <Recipe />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/program/recipes/:id"
                  element={
                    <PrivateRoute user={user}>
                      <ProgramRecipes />
                    </PrivateRoute>
                  }
                />

                {/* education */}
                <Route
                  path="/education"
                  element={
                    <PrivateRoute user={user}>
                      <Education />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/education/:id"
                  element={
                    <PrivateRoute user={user}>
                      <EducationInfoScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/education/all"
                  element={
                    <PrivateRoute user={user}>
                      <AllWorkouts />
                    </PrivateRoute>
                  }
                />

                {/* calender */}
                <Route
                  path="/calender"
                  element={
                    <PrivateRoute user={user}>
                      <Calender />
                    </PrivateRoute>
                  }
                />

                {/* journal */}
                <Route path="/journal" element={<Journal />} />
                <Route path="/journal/:id" element={<Article />} />

                {/* shop */}
                <Route path="/shop" element={<Shop />} />
                <Route
                  path="/product/:id"
                  element={<Product setIsOpen={setIsOpen} />}
                />
                <Route path="/orders-history" element={<OrderHistory />} />
                <Route path="/order-details/:id" element={<OrderDetails />} />
                <Route
                  path="/payment-success/xV_12907_f8F765s_123/:id"
                  element={<PaymentSuccess />}
                />

                {/* programs */}
                <Route
                  path="/programs"
                  element={
                    <PrivateRoute user={user}>
                      <Programs />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/program/:id"
                  element={
                    <PrivateRoute user={user}>
                      <ProgramInfoScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/program/all"
                  element={
                    <PrivateRoute user={user}>
                      <ProgramAll />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/program/all-programs"
                  element={
                    <PrivateRoute user={user}>
                      <AllPrograms />
                    </PrivateRoute>
                  }
                />

                {/* Subscriptions */}
                <Route
                  path="/subscriptions"
                  element={
                    <SubscriptionSelection
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                    />
                  }
                />
                <Route
                  path="/subscription-success/werty653_zxcvbn"
                  element={
                    <PrivateRoute user={user}>
                      <SubscriptionSuccess />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/subscription-fail/werty653_zxcvbnbaisjbcius"
                  element={
                    <PrivateRoute user={user}>
                      <SubscriptionFailed />
                    </PrivateRoute>
                  }
                />

                {/* <Route path='/demo' element={<DemoPage />} /> */}
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Default />} />
              </>
            )}


            {/* <Route
              path="/seats"
              element={
                <SeatMap seats={seats} />
              }
            >

            </Route> */}

            <Route
              path="/subscription-success/werty653_zxcvbn"
              element={
                <SubscriptionSuccess />
              }
            />
            <Route
              path="/subscription-fail/werty653_zxcvbnbaisjbcius"
              element={
                <SubscriptionFailed />
              }
            />

            <Route
              path="/payment-success/xV_12907_f8F765s_123/:id"
              element={<PaymentSuccess />}
            />

            <Route
              path="/"
              element={<Home isOpen={isOpen} setIsOpen={setIsOpen} />}
            />

            <Route path="/journal" element={<Journal />} />
            <Route path="/journal/:id" element={<Article />} />

            <Route path="/shop" element={<Shop />} />
            <Route
              path="/product/:id"
              element={<Product setIsOpen={setIsOpen} />}
            />
            <Route
              path="/demo"
              element={<DemoPage />}
            />
            <Route path="*" element={<Default />} />
          </>
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;

import styled, { keyframes } from "styled-components";
import { colors } from '../../UI/Theme'
import { slideInRight } from 'react-animations'
import { slideOutRight } from 'react-animations'
const slideRight = keyframes`${slideInRight}`;
const slideOut = keyframes`${slideOutRight}`;

export const Wrapper = styled.section`
.main-container{
    width: 100%;
    height: 100%;
    align-items: center;
    position: relative;
    .search-bar{
        position: absolute;
        display: flex;
        width: 100%;
        height:100%;
        align-items: center;
        justify-content: center;
        animation:0.5s ${slideRight};
        .close-search{
            position: absolute;
            right:1em;
            top:1.5em;
            z-index: 1;
            font-size:22px;
            &:hover{
                cursor:pointer;
            }
        }
        .search{
            position: absolute;
            height: 90px;
            display: flex;
            top:0;
            width: 100%;
            border:none;
            align-items: center;
            input[type='text']{
                width: 100%;
                font-size:18px;
                outline:none;
                height: 100%;
                border:none;
                background-color:#fff;
                padding-left:3em ;
                &::placeholder{
                    color:#b8b1b1;
                }
            }
        }
        .flaticon-search{
            position:absolute;
            font-size:22px;
            left:0.5em;
            top:1.3em;
            margin-top:5px;
            color:#b8b1b1;
        }
    }
    .close{
        animation:0.5s ${slideOut};
    }
}

.search-items-container{
    position:absolute;
    margin-top:0px;
    width:95%;
    top:90px;
    left:2.5%;
    background-color:rgba(255,255,255,0.6);
    border-radius:0 0 10px 10px;
    padding: 0 1em;
    overflow: auto;
    /* right:25%; */
    z-index:2;
    height:auto;
    max-height:70vh;
    display: flex;
    flex-direction: column;
    gap:10px;
    .order{
        &:first-child{
            margin-top:10px;
        }
        &:last-child{
            margin-bottom:10px;
        }
            background:#fff ;
            position:relative;
            width:100%;
            height:100px;
            display: flex;
            align-items: center;
            .img-container{
                height:100%;
                width:5%;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover ;
                }
            }
            .order-info{
                display: flex;
                width:90%;
                height:100%;
                .product-info{
                    flex:11;
                    display: flex;
                    flex-direction:column;
                    justify-content: center;
                    padding:10px;
                    gap:20px;
                    .info{
                        display: flex;
                        justify-content:space-between ;
                    }
                }
            }
        }
        .no-result{
            height:100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
}

.header{
    height:90px;
    align-items:center;
    .logo{
        width:170px;
        max-width:200px;
    }
};
.link{
    &:first-child{margin-left:5em;};
    padding:4px 0;
    margin: 0 0.6em;
    color:#111;
}
.selected{
    color:${colors.themeColor.color};
}
.header-links{
    display: flex;
    gap:35px;
    a{
        text-decoration:none;
        font-family: ${colors.fonts.commuterRegular};
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

    }
}

.icon-size{
    font-size:26px;
}
.margin{
    margin-right:3em;
}
.right-header{
    .flaticon-search,
    .flaticon-user,
    .flaticon-shopping-bag
    {
        font-size:25px;
        &:hover{
            cursor: pointer;
        }
    }
    .flaticon-down-arrow{
        font-size:22px;
        &:hover{
            cursor: pointer;
        }
    }
    .flaticon-shopping-bag,
    .flaticon-search{
        margin-top:6px ;
    }
    .flaticon-shopping-bag{
        margin-right:35px ;
        position: relative;
        .dot{
            position: absolute;
            width: 10px;
            height: 10px;
            background-color:red;
            top:0;
            border-radius:100%;
            right:0;
        }
    }
    
    .button{
        display:flex;
        align-items: center;
        gap:3px;
        border:none;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.02em;
        margin:0 1.5em;
        background-color: #fff;
        &:hover{
            cursor:pointer;
        }
    }
}


@media only screen and (max-width: 1206px) {
    .main-container{
    width: 100%;
    height: 100%;
    align-items: center;
}

.search-items-container{
    .order{
            .img-container{
                width:10%;
            }
            .order-info{
                .product-info{
                    gap:15px;
                }
            }
        }
}
.header{
    height:90px;
    align-items:center;
    .logo{
        width:170px;
        max-width:200px;
    }
};
.link{
    &:first-child{margin-left:1em;};
    padding:4px 0;
    margin: 0 0.6em;
    color:#111;
}
.header-links{
    display: flex;
    gap:10px;
    a{
        text-decoration:none;
        font-family: ${colors.fonts.commuterRegular};
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 19px;
/* identical to box height */

letter-spacing: 0.02em;

    }
}
}
@media only screen and (max-width: 992px) {
    .main-container{
        .search-bar{
        .close-search{
            top:1.2em;
        }
        .search{
            height: 80px;
        }
        .flaticon-search{
            top:1.1em;
        }
    }
}


.search-items-container{
    top:80px;
    left:2.5%;
    .order{
            .img-container{
                width:12%;
            }
        }
}



    .header{
    height:80px;
    .logo{
        width:140px;
    }
}
.link{
    &:first-child{margin-left:0.5em;};
}
.header-links{
    gap:5px;
    a{
        font-size:14px;
    }
}
.icon-size{
    font-size:23px;
}
.right-header{
    height:100%;
    button{
        font-size:13px;
    }
}

}
@media only screen and (max-width: 768px) {
    .menu{
        .ripples{
            margin-left:0.7em ;
            border-radius:100%;
        .menu-btn{
            border-radius:100%;
            background-color: #fff;
            border: none;
            width: 25px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 85%;
                height:50%;
                object-fit: cover;
            };
        }
    }
    }
    .main-container{
        .search-bar{
        .close-search{
            top:0.9em;
        }
        .search{
            height: 70px;
        }
        .flaticon-search{
            top:0.7em;
        }
    }
}


.search-items-container{
    top:70px;
    left:2.5%;
    .order{
            .img-container{
                width:15%;
            }
        }
}

.header{
    height:65px;
    .logo{
        width:40px;
    }
}
.link{
    &:first-child{margin-left:0.2em;};
}
.header-links{
    gap:0px;
    a{
        font-size:10px;
        margin: 0 5px;
    }
}
.icon-size{
    font-size:26px;
}
.right-header{
    /* background-color:#111; */
    .links-container{
        width:100% ;
        display: flex;
        justify-content: end;
        gap:15px ;
        padding-right: 1em;
    }
    .flaticon-search,
    .flaticon-user,
    .flaticon-down-arrow,
    .flaticon-shopping-bag
    {
        font-size:24px;
    }
    .flaticon-shopping-bag{
        margin-right:10px ;
    }
    height:100%;
    .button{
        font-size:12px;
        margin:0 5px;
        line-height:10px;
    }
}

}


@media only screen and (max-width: 500px) {
    .menu{
        .ripples{
            margin-left:0.7em ;
            border-radius:100%;
        .menu-btn{
            border-radius:100%;
            background-color: #fff;
            border: none;
            width: 25px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 85%;
                height:50%;
                object-fit: cover;
            };
        }
    }
    }


    .main-container{
        .search-bar{
        .close-search{
            top:0.9em;
        }
        .search{
            height: 65px;
        }
        .flaticon-search{
            top:0.7em;
        }
    }
}


.search-items-container{
    top:65px;
    left:2.5%;
    .order{
            .img-container{
                width:25%;
            }
            .order-info{
                .product-info{
                    h4{
                        font-size:16px;
                    }
                    .info{
                        p{
                            font-size:12px;
                        }
                    }
                }
            }
        }
}


    .header{
    height:65px;
    .logo{
        width:70px;
    }
}
.link{
    &:first-child{margin-left:0.3em;};
}
.header-links{
    display:none;
    gap:4%;
    a{
        font-size:10px;
        margin:0;
    }
}
.responsive-links{
    background-color: rgba(236, 101, 36,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0.2em 0;
    margin:0 auto 0em;
    gap:4%;
    a{
        font-size:12px;
        margin:0;
    }
}
.icon-size{
    font-size:20px;
}
.right-header{
    .flaticon-search,
    .flaticon-user,
    .flaticon-down-arrow,
    .flaticon-shopping-bag
    {
        font-size:20px;
    }

    .flaticon-shopping-bag{
        margin-right:10px ;
        .dot{
            position: absolute;
            width: 7px;
            height: 7px;
            background-color:red;
            top:0;
            border-radius:100%;
            right:-4px;
        }
    }
    height:100%;
    .button{
        font-size:12px;
        margin:0 5px;
        line-height:10px;
    }
    div:first-child {
        display: flex;
        align-items: center;
        width: 100%;
    gap: 12px;
    justify-content: flex-end;
    margin-right: 0.3em;
    }
}

}
`;
import React from 'react'
import { Wrapper } from '../styles/Section0Styles'
import { isExternalLink } from "../../utils/services";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export const Section0 = ({ setIsOpen }) => {
    const { homeData, blogs, products, education, workouts, recipes } = useSelector(state => state.appReducer);
    const { user } = useSelector(state => state.userReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let origin = window.location.origin;
    const { id, type } = useSelector(state => state.redirectReducer)


    const handleRedirects = (user, dataType, dataId) => {
        let data;
        switch (dataType || type) {
            case "Education":
                data = education.filter(workout => workout.id === dataId || id)
                return navigate(`/education/${data[0].id}`, { state: { workout: data[0], heading: 'EDUCATION' } })
            case "Products":
                data = products.filter(workout => workout.id === dataId || id)
                return console.log(data[0]);
            case "Programs":
                return navigate('/programs')
            case "Blogs":
                data = blogs.filter(workout => workout.id === dataId || id)
                return navigate(`/journal/article/${data[0].id}`, { state: { blog: data[0] } });
            case "Recipes":
                data = recipes.filter(workout => workout.id === dataId || id)
                if (user.accessType.toLowerCase() === 'platinum') {
                    return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                } else if (user.accessType.toLowerCase() === 'gold') {
                    if (data[0].accessType.toLowerCase() === 'platinum ') {
                        return navigate(`/shop`);
                    } else {
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    }
                } else {
                    if (data[0].accessType.toLowerCase() === 'free') {
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    } else {
                        return navigate('/shop')
                    }
                }
            case "Classes":
                data = workouts.filter(workout => workout.id === dataId || id)
                return navigate(`/workouts/${data[0].id}`, { state: { workout: data[0], heading: 'CLASSES' } })
            default:
        }
    }





    if (!homeData) return null;
    const promotion = homeData.sections.filter(section => section.widgetType?.toLowerCase() === 'promotion-banners')[0].items[0]
    let url = promotion.link.externalLink;
    return (
        <Wrapper>
            <div className='container' style={{ backgroundColor: promotion.backgroundColor ? `${promotion.backgroundColor}` : null }}>
                {isExternalLink(url) ?
                    <a href={url} target='_blank' rel="noopener noreferrer">
                        <h4 className='heading'>{promotion.promotionalMsg}</h4>
                    </a>
                    :
                    <Link to={url.slice(origin.length)}
                        onClick={(e) => {
                            e.preventDefault();
                            if (promotion.link.type === 'Blogs') {
                                let data = blogs.filter(workout => workout.id === promotion.link.id)
                                navigate(`/journal/${promotion.link.id}`, { state: { blog: data[0] } })
                            } else if (promotion.link.type === "Products") {
                                let data = products.filter(workout => workout.id === promotion.link.id)
                                navigate(`/product/${promotion.link.id}`, { state: { product: data[0] } })
                            } else if (user) {
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.redirectUser({ id: promotion.link.id, type: promotion.link.type }));
                                })
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.changeContent());
                                })
                                handleRedirects(user, promotion.link.type,promotion.link.id)
                            }
                            else {
                                setIsOpen(true);
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.changeContent());
                                })
                                import('../../app/actions/userActions').then(userActions => {
                                    return dispatch(userActions.redirectUser({ id: promotion.link.id, type: promotion.link.type }));
                                })
                            }
                        }}
                    ><h4 className='heading'>{promotion.promotionalMsg}</h4></Link>
                }
            </div>
        </Wrapper >
    )
}

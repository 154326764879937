import styled from "styled-components";
import { colors } from '../../UI/Theme'

export const Wrapper = styled.section`
    .main-container{
        background-color:#fff;
        width:80% ;
        border-radius:20px ;
        margin:0 auto ;
        padding-bottom:2em ;
        padding-top:1em ;
        .note{
            margin:0.2em 0 0.5em;
            text-align:center ;
            font-size:28px ;
            font-family:${colors.fonts.garacie} ;
            font-weight:bold ;
        }
        .note-2{
            margin:0 0 1em;
            text-align:center ;
            color:grey;
        }
    
        .subscriptions-container{
            padding: 0em 2em  ;
            display: flex;
            gap:10px;
            .sub{
                position:relative ;
                flex:1 ;
                background-color:#f5f4f2;
                padding:10px 20px 10px 5px ;
                .abso{
                    position:absolute ;
                    top:10px;
                    right:10px ;
                    color: ${colors.themeColor.color};
                }
                .sub-head{
                    font-size:20px ;
                    margin-bottom:10px ;
                }
                .sub-para{
                    display: flex;
                    align-items: flex-start;
                    gap:5px;
                    font-size:14px ;
                    line-height:22px ;
                    .dot{
                        display: block;
                        width:5px ;
                        height:5px ;
                        margin-top:0.5em ;
                        border-radius:100%;
                        background: #000;
                    }
                }
            }
            .selectedSub{
                    border:2px dashed ${colors.themeColor.color} ;
            }
        }
        .duration{
            margin-bottom:1em ;
            margin-top:1em ;
            gap:1em;
            .choice{
                background-color:#f5f4f2 ;
                padding:10px 20px ;
                border-radius:5px ;
                &:hover{
                    cursor: pointer;
                }
            }
            .selected{
                padding:10px 20px ;
                border-radius:5px ;
                background-color:${colors.themeColor.color} ;
                color:#fff;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .btn-container{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top:1.5em ;
            
            .btn{
                width:auto ;
                display: flex;
                align-items: center;
                padding: 0 20px;
                height:40px ;
                background-color:${colors.themeColor.color} ;
                border:none ;
                color:#fff;
                border-radius:5px ;
                &:hover{
                    cursor: pointer;
                }
            }
        }

        @media only screen and (max-width:1180px) {
            width:90%
        }
        @media only screen and (max-width:1050px) {
            width:98%
        }
        @media only screen and (max-width:993px) {
            width:90%;
            height:80vh ;
            overflow:scroll ;
        .subscriptions-container{
            flex-direction:column ;
            .sub{
                .sub-head{
                    text-align:center ;
                }
                .sub-para{
                    text-align:center ;
                }
            }
        }
        
        .btn-container{
            .btn{
                width:40% ;
            }
        }
        }
        @media only screen and (max-width:768px) {
            width:95%;
            .note{
                font-size:14px ;
            }
        .subscriptions-container{
            .sub{
                .abso{
                    font-size:12px ;
                }
                .sub-head{
                    font-size:16px ;
                    margin-bottom:3px ;
                }
                .sub-para{
                    font-size:13px ;
                }
            }
        }

        .duration{
            .choice,.selected{
                font-size:12px ;
            }
        }
        
        .btn-container{
            .btn{
                width:40% ;
                font-size:14px ;
            }
        }
        }
        @media only screen and (max-width:500px) {
            width:95%;
            height:70vh ;
            .note{
                font-size:12px ;
                padding: 0 10px ;
            }
        .subscriptions-container{
            .sub{
                .abso{
                    font-size:12px ;
                }
                .sub-head{
                    font-size:14px ;
                    margin-bottom:3px ;
                }
                .sub-para{
                    font-size:11px ;
                }
            }
        }

        .duration{
            .choice,.selected{
                font-size:12px ;
            }
        }
        
        .btn-container{
            .btn{
                width:40% ;
                height:35px ;
                font-size:12px ;
            }
        }
        }
    }
`;
import styled from "styled-components";
import { colors } from '../../UI/Theme'

export const Wrapper = styled.section`
.main-container{
    width: 30vw;
    position: relative;
    border-radius:20px;
    height: 90vh;
    gap:40px;
    background-color:#fff;
    overflow: hidden;
    p{
        text-align: center;
        margin-top:1.2em;
        font-weight:bold;
    }
    .inner-section{
        width:90% ;
        margin:-1.8em auto 1em;
        height:85%;
        overflow: auto;
        border: 2px solid #e9ebee;
        .tab-container{
            display: flex;
            margin-top:-0.1em;
            .login-btn{
                background-color:#e9ebee ;
                flex:1;
                display: flex;
                justify-content: center;
                align-items: center;
                height:40px;
                font-weight:bold;
                color:grey;
                &:hover{
                    cursor:pointer ;
                }
            }
            .selected{
                background-color:#fff ;
                color:${colors.themeColor.color};
                border-top:2px solid ${colors.themeColor.color} ;
            }
        }
    }
    .close{
        color:#111;
        position: absolute;
        top:15px;
        right:15px;
        font-size:30px;
        color:${colors.themeColor.color};
        &:hover{
            cursor: pointer;
        }
    }
    .login-container{
        /* position: relative; */
        /* align-items: center; */
        margin:0.6em auto 0;
        overflow: hidden;
        h4{
            text-align: center;
            margin: 0em 0 1em;
            font-weight:200 ;
        }
        .logo{
            width:140px;
            margin:0 auto 2em;
        }
        .logo2{
            width:140px;
            margin:0 auto 1em;
        }
        input{
            width:70%;
            border-radius:5px;
            border:none;
            box-shadow:0 0 2px 0px #111;
            height:40px;
            margin:5px auto;
            padding:5px;
        }
        p{
            font-size:13px;
            width:70%;
            font-weight:100 ;
            margin: auto;
            text-align:right;
            text-decoration:underline;
            /* padding-right:10px; */
            color:${colors.themeColor.color}
        }
    }
    .error-container{
        width:90% ;
        margin:0 auto ;
        .error{
            margin:0;
            width:100% ;
            color:red;
            text-decoration:none ;
            text-align:center ;
        }
    }
    .signin-btn{
        border: none;
        padding: 0 15px;
        background-color: ${colors.themeColor.color};
        height: 25px;
        color: #fff;
        border-radius: 8px;
        width: 30%;
        margin: 20px auto;
        height: 37px;
        font-size: 16px;
        &:hover{
            cursor:pointer;
            background-color:${colors.themeColor.fontColor}
        }
    }
    .hover{
        font-size:40px;
        &:hover{
            cursor:pointer ;
        }
    }
    .size{
        width:43px;
    }
    .or{
        display: flex;
        justify-content : space-between;
        align-items: center;
        width:70%;
        margin:0 auto;
        p{
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color:#000;
        }
        .line{
            flex:3;
            width:40%;
            height:2px;
            background-color:#e9ebee;
        }
    }
    .logos-container{
        display: flex;
    justify-content: space-around;
    margin: 1em auto 2em;
    width: 70%;
    }
}
@media only screen and (max-width: 1200px) {
    .main-container{
    width: 40vw;
    height: 95vh;
    gap:30px;
    .login-container{
        .logo2{
            width:120px;
        }
        .logo{
            width:120px;
        }
    }
    .inner-section{
        margin-top:-20px;
    }
}
}
@media only screen and (max-width: 993px) {
    .main-container{
    width: 50vw;
    height: 95vh;
    gap:30px;
    .login-container{
        .logo,.logo2{
            width:100px;
        }
        input{
            height:35px;
        }
    }
    .signin-btn{
        width: 34%;
    }
}
}
@media only screen and (max-width: 768px) {
    .main-container{
    width: 70vw;
    .logo{
        width:120px;
    }
    .login-container{
        /* position: relative; */
        /* align-items: center; */
        input{
            height:30px;
        }
    }
    .signin-btn{
        width: 38%;
    }
    .hover{
        font-size:34px;
        &:hover{
            cursor:pointer ;
        }
    }
}
}
@media only screen and (max-width: 500px) {
    .main-container{
    width: 90vw;
    height:auto;
    .logo{
        width:120px;
    }
    .login-container{
        /* position: relative; */
        /* align-items: center; */
        input{
            height:30px;
        }
    }
    .signin-btn{
        width: 38%;
    }
    .hover{
        font-size:34px;
        &:hover{
            cursor:pointer ;
        }
    }
}
}
`;
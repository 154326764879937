import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyAbrbIFYL8s8cOQpZa0QG9nUQRgn_eKJPU",
    authDomain: "inflow-active.firebaseapp.com",
    databaseURL: "https://inflow-active-default-rtdb.firebaseio.com",
    projectId: "inflow-active",
    storageBucket: "inflow-active.appspot.com",
    messagingSenderId: "253982062132",
    appId: "1:253982062132:web:f78692cd0419cb9c787c61",
    measurementId: "G-9XX5PE9T7T"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

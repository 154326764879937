import { ActionTypes } from "../constants/action-types"
// export const userLogin = (payload) => {
//     return async (dispatch) => {
//         const response = await fetch("https://test-portal-339608.as.r.appspot.com/Login_Signup/studentLogin/", {
//             method: "POST",
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify(payload)
//         })
//         const res = await response.json();
//     }
// }


export const userFound = (user) => {
    console.log('ascascas', [user]);
    return {
        type: ActionTypes.USER_SUCCESS,
        payload: user
    }
}
export const allPlans = (data) => {
    return {
        type: ActionTypes.PLANS,
        payload: data
    }
}
export const gettingBlogs = (blogs) => {
    return {
        type: ActionTypes.BLOGS,
        payload: blogs
    }
}
export const logoutUser = () => {
    return {
        type: ActionTypes.LOGOUT
    }
}
export const getJournalData = (data) => {
    return {
        type: ActionTypes.GET_JOURNAL_DATA,
        payload: data
    }
}

//getting workouts 
export const gettingWorkouts = (workouts) => {
    return {
        type: ActionTypes.WORKOUTS,
        payload: workouts
    }
}
export const getActiveProgram = (activeProgram) => {
    return {
        type: ActionTypes.ACTIVE_PROGRAM,
        payload: activeProgram
    }
}
export const getUserProgram = (userProgram) => {
    return {
        type: ActionTypes.USER_PROGRAMS,
        payload: userProgram
    }
}
export const workoutsId = (ids) => {
    return {
        type: ActionTypes.WORKOUTS_ID,
        payload: ids
    }
}
export const getAllPrograms = (allPrograms) => {
    return {
        type: ActionTypes.ALL_PROGRAMS,
        payload: allPrograms
    }
}
export const getPaidPrograms = (paidPrograms) => {
    return {
        type: ActionTypes.PAID_PROGRAMS,
        payload: paidPrograms
    }
}
export const othersProgram = (otherPrograms) => {
    return {
        type: ActionTypes.OTHER_PROGRAMS,
        payload: otherPrograms
    }
}
export const setActiveProgramWorkouts = (activeWorkouts) => {
    return {
        type: ActionTypes.ACTIVE_WORKOUTS,
        payload: activeWorkouts
    }
}
export const allWorkouts = (workouts, type) => {
    if (type === 'gold') {
        return {
            type: ActionTypes.GOLD_WORKOUTS,
            payload: workouts
        }
    }
    if (type === 'premium') {
        return {
            type: ActionTypes.PREMIUM_WORKOUTS,
            payload: workouts
        }
    }
    if (type === 'free') {
        return {
            type: ActionTypes.FREE_WORKOUTS,
            payload: workouts
        }
    }
}

// recipes
export const gettingRecipes = (recipes, nutrition) => {
    return {
        type: ActionTypes.RECIPES,
        payload: {
            recipes: recipes, nutrition: nutrition
        }
    }
}
export const educationGet = (education) => {
    return {
        type: ActionTypes.EDUCATION,
        payload: education
    }
}
export const setActiveProgramRecipes = (activeRecipes) => {
    return {
        type: ActionTypes.ACTIVE_RECIPES,
        payload: activeRecipes
    }
}
export const activeProgramUser = (data) => {
    return {
        type: ActionTypes.ACTIVE_PROGRAMS_USER_INFORMATION,
        payload: data
    }
}
export const calenderData = (data) => {
    return {
        type: ActionTypes.CALENDER_DATA,
        payload: data
    }
}
export const switchDay = (data, type) => {
    if (type === 'prev') {
        return {
            type: ActionTypes.PREVIOUS_DAY,
            payload: data
        }
    }
    return {
        type: ActionTypes.NEXT_DAY,
        payload: data
    }
}

export const allRecipes = (recipes, type) => {
    if (type === 'gold') {
        return {
            type: ActionTypes.GOLD_RECIPES,
            payload: recipes
        }
    }
    if (type === 'premium') {
        return {
            type: ActionTypes.PREMIUM_RECIPES,
            payload: recipes
        }
    }
    if (type === 'free') {
        return {
            type: ActionTypes.FREE_RECIPES,
            payload: recipes
        }
    }
}
export const allEducation = (education, type) => {
    if (type === 'gold') {
        return {
            type: ActionTypes.GOLD_EDUCATION,
            payload: education
        }
    }
    if (type === 'premium') {
        return {
            type: ActionTypes.PREMIUM_EDUCATION,
            payload: education
        }
    }
    if (type === 'free') {
        return {
            type: ActionTypes.FREE_EDUCATION,
            payload: education
        }
    }
}

// products
export const productsSuccess = (products) => {
    return {
        type: ActionTypes.PRODUCTS,
        payload: products
    }
}
export const homePage = (home) => {
    return {
        type: ActionTypes.HOME_PAGE,
        payload: home
    }
}


// redirects
export const redirectUser = (data) => {
    return {
        type: ActionTypes.REDIRECTS,
        payload: { id: data.id, type: data.type }
    }
}

export const addToCart = (id, color, amount, product, shippingCost, selectProgramId) => {
    if (selectProgramId) {
        return { type: ActionTypes.ADD_TO_CART, payload: { id, color, amount, product, shippingCost, selectProgramId } }
    } else {
        return { type: ActionTypes.ADD_TO_CART, payload: { id, color, amount, product, shippingCost } }
    }
}

export const removeItem = id => {
    return { type: ActionTypes.REMOVE_CART_ITEMS, payload: id }
}
export const toggleAmount = (id, value, data) => {
    return { type: ActionTypes.TOGGLE_CART_ITEM_AMOUNT, payload: { productId: id, value, data } }
}
export const countCartTotal = () => {
    return { type: ActionTypes.COUNT_CART_TOTALS }
}

export const clearCart = () => {
    return { type: ActionTypes.CLEAR_CART }
}

export const cartOpen = () => {
    return {
        type: ActionTypes.CART_OPEN
    }
}
export const cartClose = () => {
    return {
        type: ActionTypes.CART_CLOSE
    }
}
export const close = () => {
    return { type: ActionTypes.CLOSE }
}
export const open = () => {
    return { type: ActionTypes.OPEN }
}
export const changeContent = () => {
    return { type: ActionTypes.CONTENT }
}

export const menuClose = () => {
    return { type: ActionTypes.MENU_CLOSE }
}
export const menuOpen = () => {
    return { type: ActionTypes.MENU_OPEN }
}
export const toggleMenu = () => {
    return { type: ActionTypes.TOGGLE_MENU }
}

export const orders = (orders) => {
    return {
        type: ActionTypes.USER_ORDERS,
        payload: orders
    }
}

export const getCart = (cart) => {
    return {
        type: ActionTypes.SET_CART,
        payload: cart
    }
}

export const makeFromCartTrue = () => {
    return { type: ActionTypes.FROM_CART }
}
export const makeFromCartFalse = () => {
    return { type: ActionTypes.FROM_CART_FALSE }
}
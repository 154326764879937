import { ActionTypes } from "../constants/action-types"
const initialState = {
    workouts: null,
    recipes: null,
    education: null,
    goldEducation: null,
    premiumEducation: null,
    freeEducation: null,
    programs: null,
    workoutsLoading: false,
    recipesLoading: false,
    programsLoading: false,
    activeProgram: null,
    userPrograms: null,
    userProgramsWorkoutsId: null,
    allPrograms: null,
    otherPrograms: null,
    activeProgramWorkouts: null,
    freeWorkouts: null,
    goldWorkouts: null,
    premiumWorkouts: null,
    activeProgramRecipes: null,
    freeRecipes: null,
    goldRecipes: null,
    premiumRecipes: null,
    nutrition: '',
    activeProgramUserInfo: null,
    currentPhase: null,
    currentDay: null,
    currentSelectedDay: 0,
    workoutData: null,
    mealData: null,
    products: null,
    homeData: null,
    blogs: null,
    content: false,
    isMenuOpen: false,
    paidPrograms: null,
    menuCheck: false,
    allPlans: null,
    journalPage: null,
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PROGRAMS_LOADING,
            ActionTypes.RECIPES_LOADING,
            ActionTypes.RECIPES_LOADING
            :
            return {
                ...state, loading: true
            }
        case ActionTypes.WORKOUTS:
            return {
                ...state, workoutsLoading: false, workouts: action.payload
            }
        case ActionTypes.PLANS:
            return {
                ...state, allPlans: action.payload
            }
        case ActionTypes.BLOGS:
            return {
                ...state, blogs: action.payload
            }
        case ActionTypes.EDUCATION:
            return {
                ...state, education: action.payload
            }
        case ActionTypes.RECIPES:
            return {
                ...state, recipesLoading: false, recipes: action.payload.recipes, nutrition: action.payload.nutrition
            }
        case ActionTypes.PROGRAMS:
            return {
                ...state, programsLoading: false
            }
        case ActionTypes.HOME_PAGE:
            return {
                ...state, homeData: action.payload
            }
        case ActionTypes.ACTIVE_PROGRAM:
            return {
                ...state, activeProgram: action.payload
            }
        case ActionTypes.USER_PROGRAMS:
            return {
                ...state, userPrograms: action.payload
            }
        case ActionTypes.ALL_PROGRAMS:
            return {
                ...state, allPrograms: action.payload
            }
        case ActionTypes.PAID_PROGRAMS:
            return {
                ...state, paidPrograms: action.payload
            }
        case ActionTypes.PRODUCTS:
            return {
                ...state, products: action.payload
            }
        case ActionTypes.OTHER_PROGRAMS:
            return {
                ...state, otherPrograms: action.payload
            }
        case ActionTypes.ACTIVE_WORKOUTS:
            return {
                ...state, activeProgramWorkouts: action.payload
            }
        case ActionTypes.FREE_WORKOUTS:
            return {
                ...state, freeWorkouts: action.payload
            }
        case ActionTypes.GOLD_WORKOUTS:
            return {
                ...state, goldWorkouts: action.payload
            }
        case ActionTypes.PREMIUM_WORKOUTS:
            return {
                ...state, premiumWorkouts: action.payload
            }
        case ActionTypes.FREE_EDUCATION:
            return {
                ...state, freeEducation: action.payload
            }
        case ActionTypes.GOLD_EDUCATION:
            return {
                ...state, goldEducation: action.payload
            }
        case ActionTypes.PREMIUM_EDUCATION:
            return {
                ...state, premiumEducation: action.payload
            }
        case ActionTypes.ACTIVE_RECIPES:
            return {
                ...state, activeProgramRecipes: action.payload
            }
        case ActionTypes.FREE_RECIPES:
            return {
                ...state, freeRecipes: action.payload
            }
        case ActionTypes.GOLD_RECIPES:
            return {
                ...state, goldRecipes: action.payload
            }
        case ActionTypes.PREMIUM_RECIPES:
            return {
                ...state, premiumRecipes: action.payload
            }
        case ActionTypes.ACTIVE_PROGRAMS_USER_INFORMATION:
            return {
                ...state, activeProgramUserInfo: action.payload
            }
        case ActionTypes.GET_JOURNAL_DATA:
            return {
                ...state, journalPage: action.payload
            }
        case ActionTypes.NEXT_DAY:
            return {
                ...state, currentSelectedDay: action.payload
            }
        case ActionTypes.PREVIOUS_DAY:
            return {
                ...state, currentSelectedDay: action.payload
            }
        case ActionTypes.CALENDER_DATA:
            const { currentPhase, currentDay, mealData, workoutData } = action.payload
            return {
                ...state, currentPhase, currentDay, mealData, workoutData
            }
        case ActionTypes.CONTENT:
            return { ...state, content: !state.content }
        case ActionTypes.WORKOUTS_ID:
            return { ...state, userProgramsWorkoutsId: action.payload }
        case ActionTypes.TOGGLE_MENU:
            return { ...state, isMenuOpen: !state.isMenuOpen }
        case ActionTypes.MENU_CLOSE:
            return { ...state, menuCheck: true }
        case ActionTypes.MENU_OPEN:
            return { ...state, menuCheck: false }
        default:
            return state
    }
}
import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'

export const InlineBanner = ({ heading, subHeading, inline, bgColor }) => {
  const matches = useMediaQuery('(max-width:500px)');
  return (
    <Grid item container className='offer-container' style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }} >
      <p>{subHeading}</p>
      <h2>{heading}</h2>
      <Grid item container direction={`${matches ? 'column' : 'row'}`} className='row-container'>
        {inline.map((inline, idx) => {
          return <Grid key={idx} item container flex={1} direction='column' className='inline' alignItems='center'>
            <img src={inline.icon} alt="icon" />
            <h3>{inline.title}</h3>
            <p>{inline.description}</p>
          </Grid>
        })}
      </Grid>
    </Grid >
  )
}

import { Grid } from '@mui/material'
import React, { useState } from 'react'
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import Carousel from 'react-elastic-carousel'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux'
import { changeContent, redirectUser } from '../../app/actions/userActions';
import { Link } from 'react-router-dom'

export const ProgramSection = ({ handleRedirects, heading, subHeading, bgColor, setIsOpen, programs }) => {
  const matches = useMediaQuery('(max-width:768px)');
  const { user } = useSelector(state => state.userReducer)
  const dispatch = useDispatch()
  return (
    <Grid item container className='training-section' style={{ backgroundColor: `${bgColor ? bgColor : '#FCF1F5'}` }} direction='column' alignItems='center'>
      <p>{subHeading}</p>
      <h2>{heading}</h2>
      <Grid item container className='carousel-container'>
        {matches ?
          <Carousel itemsToShow={1} className='carousel' >
            {programs && programs.map((program, idx) => {
              return <Grid key={idx} item container direction='column' alignItems='center' className='image'>
                <img src={program.imageUrl} alt="" />
                <div className="section">
                  <div className="info">
                    <p>{program.displayName.toUpperCase()}</p>
                    <p>
                      <WatchLaterOutlinedIcon className='icon' /> {program.numberOfDays} days
                    </p>
                  </div>
                  <div className="button">
                    <Link to="/programs" onClick={e => e.preventDefault()} >
                      <button onClick={() => {
                        if (user) {
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.changeContent());
                          })
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.redirectUser({ id: program.id, type: "Programs" }));

                          })
                          handleRedirects(user)
                        } else {
                          setIsOpen(true)
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.changeContent());

                          })
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.redirectUser({ id: program.id, type: "Programs" }));

                          })
                        }
                      }}>SUBSCRIBE NOW</button>
                    </Link>
                  </div>
                </div>
              </Grid>
            })}
          </Carousel>
          :
          <Carousel
            disableArrowsOnEnd={true}
            itemPadding={[0, 0]}
            // itemsToShow={1}
            outerSpacing={200}
            className='carousel'
          >
            {programs && programs.map((program, idx) => {
              return <Grid key={idx} item container direction='column' alignItems='center' className='image'>
                <img src={program.imageUrl} alt="" />
                <div className="section">
                  <div className="info">
                    <p>{program.displayName.toUpperCase()}</p>
                    <p>
                      <WatchLaterOutlinedIcon className='icon' /> {program.numberOfDays} days
                    </p>
                  </div>
                  <div className="button">
                    <Link to="/programs" onClick={e => e.preventDefault()} >
                      <button onClick={() => {
                        if (user) {
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.changeContent());

                          })
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.redirectUser({ id: program.id, type: "Programs" }));

                          })
                          handleRedirects(user)
                        } else {
                          setIsOpen(true)
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.changeContent());

                          })
                          import('../../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.redirectUser({ id: program.id, type: "Programs" }));

                          })
                        }
                      }}>SUBSCRIBE NOW</button>
                    </Link>
                  </div>
                </div>
              </Grid>
            })}
          </Carousel>
        }
      </Grid>
    </Grid >
  )
}

import React from 'react'
import { Wrapper } from './styles/footerStyles'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import { GoMail } from "react-icons/go";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { useSelector } from 'react-redux';
import { AiFillApple } from "react-icons/ai";
import googlePlay from "../assets/googlePlay.png"
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";

const menu = [
    { name: "Home", url: '/' },
    { name: "Blog", url: '/journal' },
    { name: "MyAccess", url: '/classes' },
    { name: "Shop", url: '/shop' },
]

const aboutInflow = [
    { name: "Sportlight on our leader", url: '/' },
    { name: "The idea behind INFLOW", url: '/' },
]

const Footer = () => {
    const { homeData } = useSelector(state => state.appReducer);
    const { user } = useSelector(state => state.userReducer);

    if (!homeData) return null;
    const footer = homeData.sections.filter(section => section.widgetType?.toLowerCase() === 'footer')[0].items[0];
    return <Wrapper>
        <div className='main-container' style={{ backgroundColor: footer.backgroundColor ? footer.backgroundColor : 'aliceblue' }}>
            <div className="container">
                <div className='linksSection'>
                    <div className="heading">
                        <h2>{footer?.leftLinks?.heading}</h2>
                    </div>
                    <div className="links">
                        {footer?.leftLinks?.values?.map((val, idx) => {
                            if (!user && val?.url === "/classes") return <></>
                            return <Link key={idx} to={val.url} >{val.name}</Link>
                        })}
                    </div>
                </div>
                <div className='linksSection'>
                    <div className="heading">
                        <h2>{footer?.middleLinks?.heading}</h2>
                    </div>
                    <div className="links">
                        {footer?.middleLinks?.values?.map((val, idx) => {
                            return <Link key={idx} to={val.url}>{val.name}</Link>
                        })}
                    </div>
                </div>
                {/* <div className="storySection">
                    <div className="heading">
                        <h2>Our Story</h2>
                    </div>
                    <div className='content'>
                        <div dangerouslySetInnerHTML={{ __html: footer.content }} />
                    </div>
                </div>
                <div className="logoSection">
                    <img src={footer.image || logo} alt="logo" />
                </div> */}
                <div className="linksSection">
                    <div className="heading">
                        <h2>{footer?.rightLinks?.heading}</h2>
                    </div>
                    <div className="links">
                        {footer?.rightLinks?.values.map((val, idx) => {
                            return <Link to={val.url} key={idx}>{val.name}</Link>
                        })}
                    </div>
                </div>
                <div className="contactSection">
                    <div className="heading">
                        <h2>Contact Us</h2>
                    </div>
                    <div className="content-container">
                        {footer.contactUs.phoneNo &&
                            <div className="data">
                                <BiPhoneCall className='mail' />
                                <p className='bold'>{footer.contactUs.phoneNo} </p>
                            </div>
                        }
                        {footer.contactUs.email &&
                            <div className="data">
                                <GoMail className='mail' />
                                <p>{footer.contactUs.email}</p>
                            </div>
                        }
                        {footer.contactUs.address &&
                            <div className="data">
                                <HiOutlineLocationMarker className='address' />
                                <p>{footer.contactUs.address}</p>
                            </div>
                        }

                        <div className='socials'>
                            <p>Follow Us</p>
                            <div className="icons-container">
                                {footer.socialMediaLinks?.facebook !== '' &&
                                    <a href={footer.socialMediaLinks.facebook} target='_blank' rel="noopener noreferrer" >
                                        <AiFillFacebook className='social-icons' />
                                    </a>
                                }
                                {footer.socialMediaLinks?.instagram !== '' &&
                                    <a href={footer.socialMediaLinks.instagram} target='_blank' rel="noopener noreferrer" >
                                        <AiFillInstagram className='social-icons' />
                                    </a>
                                }
                                {footer.socialMediaLinks?.tiktok !== '' &&
                                    <a href={footer.socialMediaLinks?.tiktok} target='_blank' rel="noopener noreferrer" >
                                        <FaTiktok className='social-icons' />
                                    </a>
                                }
                                {footer.socialMediaLinks.youtube && footer.socialMediaLinks?.youtube !== '' &&
                                    <a href={footer.socialMediaLinks?.youtube} target='_blank' rel="noopener noreferrer" >
                                        <AiFillYoutube className='social-icons' />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line" />
            <div className="copyrightSection">
                <div className='copyright-container'>
                    <p>&copy; 2022 copyright. All Right Reserved</p>
                </div>
                <div className='copyright-container flex'>
                    <p>DOWNLOAD APPS</p>
                    <div className='icons-container'>
                        <a href={footer.socialMediaLinks.playstore} target='_blank' rel='noreferrer'>
                            <div className='btn-icon'>
                                <img src={googlePlay} alt="play store" className='playIcon' />
                                <div className='verticalContent'>
                                    <p className='download'>Available on the</p>
                                    <p className='store'>Google Play</p>
                                </div>
                            </div>
                        </a>
                        <a href={footer.socialMediaLinks.appstore} target='_blank' rel='noreferrer'>
                            <div className='btn-icon size'>
                                <AiFillApple className='apple' />
                                <div className='verticalContent'>
                                    <p className='download'>Download on the</p>
                                    <p className='store'>App Store</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Wrapper >
}

const MemoizedFooter = React.memo(Footer);

export default MemoizedFooter;

import { getFunctions, httpsCallable } from 'firebase/functions';
import React from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const urlEncode = function (data) {
    ''
    var str = [];
    for (var p in data) {
        if (data.hasOwnProperty(p) && (!(data[p] == undefined || data[p] == null))) {
            str.push(encodeURIComponent(p) + "=" + (data[p] ? encodeURIComponent(data[p]) : ""));
        }
    }
    return str.join("&");
}

export const DemoPage = () => {
    return <div>
        <p>Dear ___,</p>
        <br />
        <p>Thank you for your recent purchase.</p>
        <br />
        <p>Please find attached receipt for this payment</p>
        <br />
        <p>Please note: Your receipt contains important product/service delivery information.</p>
        <br />
        <p>You can view your order here: </p>
        <br />
        <p>If you have any queries, please email our office.</p>
        <br />
        <p>Kind regards</p>
        <p>The Core Sculpt Team</p>
        <p>E: support@inflowactive.com</p>
        <br />
        <br />
        <strong>Privacy and Confidentiality</strong>
        <p>The information contained in this email is intended for the named recipients only. It may contain privileged and
            confidential information and if you are not an intended recipient, you must not print, copy, save, distribute or take any
            action in reliance on it, the information in it or any attachments to it. If you have received this email in error, please notify
            us immediately by reply email and delete the email, its contents and any associated attachments. You should also scan this
            email and any file attached for viruses. Core Sculpt Pty Ltd accepts no liability for any loss, damage or consequence
            resulting directly or indirectly from use of any attached file</p>
    </div>
}

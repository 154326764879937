/* eslint-disable default-case */
import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrapper } from "./HomeStyles"
import { useSelector, useDispatch } from 'react-redux'
import {
    query,
    getDocs,
    getDoc,
    collection,
    where,
    doc,
} from "firebase/firestore";
import { db } from '../../firebase-config'
import { Loading } from "../../components/Loading"
import { Banner } from '../../components/Home/Banner'
import { InlineBanner } from '../../components/Home/InlineBanner'
import { ProgramSection } from '../../components/Home/ProgramSection'
import { Products } from '../../components/Home/Products'
import { Features } from '../../components/Home/Features'
import { NewsLetter } from '../../components/Home/NewsLetter'
import { Classes } from '../../components/Home/Classes'
import { Testimonials } from '../../components/Home/Testimonials'
import { Blogs } from '../../components/Home/Blogs'
import { Helmet } from 'react-helmet'

export const Home = ({ setIsOpen }) => {
    const { products, blogs, workouts, homeData, allPrograms, education, recipes } = useSelector(state => state.appReducer);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id, type } = useSelector(state => state.redirectReducer)

    const handleRedirects = (user, dataType, dataId) => {
        console.log('acasv', dataType,type);
        let data;
        switch (dataType || type) {
            case "Education":
                data = education.filter(workout => workout.id === dataId || id)
                clearRedirect()
                return navigate(`/education/${data[0].id}`, { state: { workout: data[0], heading: 'EDUCATION' } })
            case "Products":
                data = products.filter(workout => workout.id === dataId || id)
                clearRedirect()
                return console.log(data[0]);
            case "Programs":
                clearRedirect()
                return navigate('/programs')
            case "Blogs":
                data = blogs.filter(workout => workout.id === dataId || id)
                clearRedirect()
                return navigate(`/journal/article/${data[0].id}`, { state: { blog: data[0] } });
            case "Recipes":
                data = recipes.filter(workout => workout.id === dataId || id)
                if (user.accessType.toLowerCase() === 'platinum') {
                    clearRedirect()
                    return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                } else if (user.accessType.toLowerCase() === 'gold') {
                    if (data[0].accessType.toLowerCase() === 'platinum ') {
                        clearRedirect()
                        return navigate(`/shop`);
                    } else {
                        clearRedirect()
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    }
                } else {
                    if (data[0].accessType.toLowerCase() === 'free') {
                        clearRedirect()
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    } else {
                        clearRedirect()
                        return navigate('/shop')
                    }
                }
            case "Workouts":
                data = workouts.filter(workout => workout.id === dataId || id)
                return navigate(`/workouts/${data[0].id}`, { state: { workout: data[0], heading: 'CLASSES' } })
            case "Classes":
                data = workouts.filter(workout => workout.id === dataId || id)
                return navigate(`/workouts/${data[0].id}`, { state: { workout: data[0], heading: 'CLASSES' } })
            default:
        }

    }

    const clearRedirect = () => {
        import('../../app/actions/userActions').then(userActions => {
            return dispatch(userActions.redirectUser({ id: '', type: "" }));
        })
    }



    const getHome = async () => {
        try {
            setLoading(true);
            const userRef = doc(db, 'Pages', 'home');
            const docs = await getDoc(userRef);
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.homePage(docs.data()))
            })
        } catch (error) {
            console.log(error);
        }
    }

    const getEducationList = async () => {
        let arr = []
        const collectionRef = await getDocs(collection(db, "Education"));
        collectionRef.forEach((doc) => {
            arr.push(doc.data())
        })
        import('../../app/actions/userActions').then(userActions => {
            return dispatch(userActions.educationGet(arr))
        })
    }


    const getRecipes = async () => {
        let arr = []
        const q = await getDocs(collection(db, "Recipes"));
        q.forEach((doc) => {
            arr.push(doc.data())
        })
        import('../../app/actions/userActions').then(userActions => {
            return dispatch(userActions.gettingRecipes(arr))
        })
    }

    const getOtherPrograms = async () => {
        try {
            let arr = []
            const collectionRef = await getDocs(collection(db, "Programs"));
            collectionRef.forEach((doc) => {
                arr.push(doc.data())
            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.getAllPrograms(arr))
            })
        } catch (error) {
            console.log(error);
        }
    }
    const getBlogs = async () => {
        try {
            let arr = []
            const collectionRef = await getDocs(collection(db, "Blogs"));
            collectionRef.forEach((doc) => {
                arr.push(doc.data())
            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.gettingBlogs(arr))
            })
            return setLoading(false)
        } catch (error) {
            console.log(error);
            return setLoading(false)
        }
    }

    const fetchProducts = async () => {
        try {
            const collectionRef = query(collection(db, `Products`), where("status", "==", true));
            const querySnapshot = await getDocs(collectionRef);
            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push(doc.data())
            });
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.productsSuccess(arr))
            })
        } catch (error) {
            console.log(error);
        }
    }
    const getWorkouts = async () => {
        try {
            setLoading(true)
            let arr = []
            const collectionRef = await getDocs(collection(db, "Workouts"));
            collectionRef.forEach((doc) => {
                arr.push(doc.data())
            })
            import('../../app/actions/userActions').then(userActions => {
                return dispatch(userActions.gettingWorkouts(arr))
            })
            getOtherPrograms();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!homeData) {
            getHome()
        }
        if (!blogs) {
            getBlogs();
        }
        if (!workouts) {
            getWorkouts();
        }
        if (!allPrograms) {
            getOtherPrograms();
        }
        if (!recipes) {
            getRecipes()
        }
        if (!education) {
            getEducationList();
        }
        if (!products) {
            fetchProducts();
        }
    }, [])


    return (
        <>
            <Helmet>
                <title>Core Sculpt</title>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {loading || !products || !blogs || !workouts || !homeData || !allPrograms ?
                <Loading />
                :
                <Wrapper>
                    <Grid container className='main-container' direction='column' >
                        {
                            homeData &&
                            homeData.sections.map((section, idx) => {
                                let arr = [];
                                switch (section.widgetType) {
                                    case 'banner-slider':
                                        return <Banner handleRedirects={handleRedirects} setIsOpen={setIsOpen} key={idx} banners={section.banners} />
                                    case "inline-banners":
                                        return <InlineBanner key={idx} heading={section.heading} bgColor={section.bgColor ? section.bgColor : false} subHeading={section.subHeading} inline={section.items} />
                                    case "programs":
                                        section.items.map(programId => {
                                            let ar = allPrograms.filter(programs => {
                                                return programs.id === programId.id
                                            })
                                            arr.push(...ar)
                                        })
                                        return <ProgramSection handleRedirects={handleRedirects} setIsOpen={setIsOpen} key={idx} programs={arr} heading={section.heading} subHeading={section.subHeading} bgColor={section.bgColor} />
                                    case "products":
                                        section.items.map(productId => {
                                            let ar = products.filter(product => {
                                                return product.id === productId.id
                                            })
                                            arr.push(...ar)
                                        })
                                        return <Products bgColor={section.bgColor ? section.bgColor : false} key={idx} products={arr} heading={section.heading} subHeading={section.subHeading} />
                                    case "features":
                                        return <Features handleRedirects={handleRedirects} bgColor={section.bgColor ? section.bgColor : false} setIsOpen={setIsOpen} key={idx} heading={section.heading} subHeading={section.subHeading} features={section.items} />
                                    case "newsletter":
                                        return <NewsLetter bgColor={section.items[0].backgroundColor ? section.items[0].backgroundColor : false} key={idx} data={section.items} />
                                    case "testimonial":
                                        return <Testimonials bgImage={section.bgImage ? section.bgImage : false} key={idx} testimonial={section.testimonials} heading={section.heading} subHeading={section.subHeading} />
                                    case "workouts":
                                        section.items.map(workoutId => {
                                            let ar = workouts.filter(product => {
                                                return product.id === workoutId.id
                                            })
                                            return arr.push(...ar)
                                        })
                                        return <Classes handleRedirects={handleRedirects} bgColor={section.color ? section.color : false} setIsOpen={setIsOpen} key={idx} workouts={arr} heading={section.heading} subHeading={section.subHeading} />
                                    case "blogs":
                                        section.items.map(blogId => {
                                            let ar = blogs.filter(product => {
                                                return product.id === blogId.id
                                            })
                                            arr.push(...ar)
                                        })
                                        return <Blogs bgColor={section.bgColor ? section.bgColor : false} blogs={arr} key={idx} heading={section.heading} subHeading={section.subHeading} />

                                }
                            })
                        }
                    </Grid>
                </Wrapper >
            }
        </>
    )
}

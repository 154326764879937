import { Grid, useMediaQuery } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Carousel from 'react-elastic-carousel'

export const Blogs = ({ heading, subHeading, blogs, bgColor }) => {
    const navigate = useNavigate()
    const matcheslg = useMediaQuery('(max-width:993px)');
    const matchesXs = useMediaQuery('(max-width:500px)');
    const handleClick = (blog) => {
        return navigate(`/journal/${blog.id}`, { state: { blog: blog } })
    }

    if (matchesXs) {
        return <Grid style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }} item container direction='column' alignItems='center' className="blog-section">
            <p>{subHeading}</p>
            <h2>{heading}</h2>
            <div className="blogs-container">
                <Carousel itemsToShow={1} className='carousel' >
                    {blogs && blogs.map((blog, idx) => {
                        return <Grid key={idx} item container direction='column' className='workout' onClick={() => handleClick(blog)} >
                            <Link to={`/journal/${blog.id}`} state={{ blog: blog }} style={{ width: '100%', height: '100%' }}>
                                <div className="image-container">
                                    <img src={blog.coverImage} width='100%' alt="blog thumbnail" />
                                </div>
                                <div className="data-container">
                                    <h4 style={{ padding: '0 5px 0 5px' }}>{blog.title}</h4>
                                    <p className="desc">
                                        {matcheslg ? _.slice(blog.description, 0, 70) :
                                            blog.description
                                        }
                                    </p>
                                </div>
                            </Link>
                        </Grid>
                    })}
                </Carousel>
            </div>
        </Grid>

    }
    return (
        <Grid style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }} item container direction='column' alignItems='center' className="blog-section">
            <p>{subHeading}</p>
            <h2>{heading}</h2>
            <div className="blogs-container">
                {blogs && _.slice(blogs).map((blog, idx) => {
                    return <Grid key={idx} item container direction='column' className='workout' onClick={() => handleClick(blog)} >
                        <Link to={`/journal/${blog.id}`} state={{ blog: blog }} style={{ width: '100%', height: '100%' }}>
                            <div className="image-container">
                                <img src={blog.coverImage} width='100%' alt="blog thumbnail" />
                            </div>
                            <div className="data-container">
                                <h4 style={{ padding: '0 5px 0 5px' }}>{blog.title}</h4>
                                <p className="desc">
                                    {matcheslg ? _.slice(blog.description, 0, 70) :
                                        blog.description
                                    }
                                </p>
                            </div>
                        </Link>
                    </Grid>
                })}
            </div>
        </Grid>
    )
}

import { CircularProgress, Grid } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
const CartContainer = ({ handleClose, closeCheck, cart, products, toggleOpen, handleBuy, setIsOpen, total_amount, loading, checkButton, user }) => {
    const dispatch = useDispatch()
    // process.env.NODE_ENV==='development'
    return (
        <Grid item container direction='column' className={`${closeCheck ? 'cart close' : 'cart'}`}>
            <div className="heading-section">
                <h2>Cart</h2>
                <CloseIcon onClick={() => {
                    handleClose()
                }} className='close-icon' />
            </div>
            <div className="underline"></div>
            <div className="cart-items">
                {cart &&
                    cart.map((cartItem, index) => {
                        let item;
                        let message = ''
                        if (products) {
                            let prr = products.filter(prod => prod.id === cartItem.id);
                            if (prr.length === 0) {
                                message = "NOT AVAILABLE"
                                item = null;
                            }
                            else {
                                item = prr[0];
                            }
                        }
                        return <div key={index} className="item">
                            <button className='button' onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.removeItem(cartItem.id)))}>
                                <DeleteIcon className="remove" />
                            </button>
                            <section className="img-container">
                                <Link to={`/product/${cartItem.id}`} state={{ product: item }} >
                                    <img src={item && item.coverImage || cartItem.image} alt="" />
                                </Link>
                            </section>
                            <section className="info-container">
                                <Link to={`/product/${cartItem.id}`} state={{ product: item }} >
                                    <p>{item && item.name || cartItem.name}</p>
                                </Link>
                                <div className="bottom">
                                    <div className="btn-container">
                                        <button onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.toggleAmount(cartItem.id, 'dec', item)))}><RemoveIcon className="btn-icon" /></button>
                                        <p>{cartItem.quantity}</p>
                                        <button onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.toggleAmount(cartItem.id, 'inc', item)))}><AddIcon className="btn-icon" /></button>
                                    </div>
                                    {
                                        message !== '' ?
                                            <p style={{ color: 'red' }}>{message}</p>
                                            :
                                            item.stock <= 0 ?
                                                <p style={{ color: 'red' }}>OUT OF STOCK</p>
                                                :
                                                <p>A$ {(item.discountedPrice || item.price).toFixed(2)}</p>
                                    }
                                </div>
                            </section>
                        </div>
                    })
                }
                {cart.length === 0 &&
                    <div className="no-items">
                        <p>Cart Empty</p>
                        <Link to='/shop' onClick={toggleOpen}>
                            <button>Add items to cart</button>
                        </Link>
                    </div>
                }
            </div>
            <div className="total-container">
                <div className="sub-total">
                    <p>SUBTOTAL</p>
                    <p className="amt">A$ {total_amount.toFixed(2)}</p>
                </div>
                <p className="shipping">
                    Shipping and GST calculated at checkout.
                </p>
                <button disabled={checkButton()} className={`${cart.length === 0 ? "btn-disabled" : ""}`}
                    onClick={() => {
                        if (user) {
                            handleBuy()
                        } else {
                            setIsOpen(true)
                        }
                    }}
                >
                    {loading ?
                        <CircularProgress
                            size={25}
                            style={{ marginTop: '4px', color: '#fff' }}
                        /> : "Checkout"}
                </button>
            </div>
        </Grid>
    )
}

export default CartContainer;
export const colors = {
    fonts: {
        garacie: 'Garacie',
        commuterRegular: 'Commuters-Regular',
        commutersBold: 'Commuters-Bold',
    },
    themeColor: {
        themeBackgroundColor: '#FCFAF6',
        color: '#EC6524',
        lightColor: 'rbga(236, 101, 36,0.8)',
        borderColor: '#CDD9EC',
        buttonColor: "#48AA4B",
        color2: "#F5EDE1",
        // lightColor: '#e2ceb9',
    },
}
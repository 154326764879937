import styled from "styled-components";
import { colors } from "../../UI/Theme"

export const Wrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    left: 1em;
    top: 1.5em;
    border-top:2px solid #fff;
    border-bottom:2px solid #fff;
    .arrow{
        font-size:35px;
        color:#fff;
        &:hover{
            cursor:pointer ;
        }
    }
    @media only screen and (max-width: 992px) {
        top: 1.4em;
        .arrow{
            font-size:26px;
        }
    }
    @media only screen and (max-width: 768px) {
        top: 1.2em;
        .arrow{
            font-size:23px;
        }
    }
    @media only screen and (max-width: 500px) {
        top: 1em;
        .arrow{
            font-size:23px;
        }
    }
`;
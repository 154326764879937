import { Alert, Grid, Snackbar, useMediaQuery } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import noImage from "../../assets/no-image.jpg"
import { Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { addToCart } from '../../app/actions/userActions'
export const Products = ({ heading, subHeading, products, bgColor }) => {
  const dispatch = useDispatch()
  const matches = useMediaQuery('(max-width:768px)');
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid item container direction='column' alignItems='center' className="shop-section" style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }} >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Added to cart
        </Alert>
      </Snackbar>
      <p>{subHeading}</p>
      <h2>{heading}</h2>
      <div className="shop-items-container">
        {products && _.slice(products, 0, 5).map((product, idx) => {
          if (matches) {
            return <Link to={`/product/${product.id}`} state={{ product: product }} key={idx} className="item">
              <img src={product.coverImage || noImage} className="item-img" alt="" />
              <p>{product.name}</p>
              <h4 style={{ fontWeight: '800', marginTop: '5px' }}>{
                product.discountedPrice ?
                  <>
                    <del style={{ color: '#777272' }}>A$ {product.price.toFixed(2)}</del>
                    &nbsp;
                    A$ {product.discountedPrice.toFixed(2)}
                  </>
                  : <>
                    A$ {product.price.toFixed(2)}
                  </>
              }</h4>
            </Link>
          } else {
            return <div className="item" key={idx}>
              <a href={`/product/${product.id}`} onClick={e => e.preventDefault()}>
                <div className="icons-container">
                  <div className="cart" onClick={() => {
                    if (product.stock !== 0) {
                      import('../../app/actions/userActions').then(userActions => {
                        return dispatch(userActions.addToCart(product.id, 'red', 1, product, product.shippingCost));
                      })
                    }
                    handleClick();
                  }}>
                    <i className="flaticon-shopping-bag"></i>
                  </div>
                  <Link to={`/product/${product.id}`} state={{ product: product }} className="eye">
                    <i className="flaticon-eye"></i>
                  </Link>
                </div>
                <img src={product.coverImage || noImage} className="item-img" alt="" />
                <p>{product.name}</p>
                <h4 style={{ fontWeight: '800', marginTop: '5px' }}>
                  {
                    product.discountedPrice ?
                      <>
                        <del style={{ color: '#777272' }}>A$ {product.price.toFixed(2)}</del>
                        &nbsp;
                        A$ {product.discountedPrice.toFixed(2)}
                      </>
                      : <>
                        A$ {product.price.toFixed(2)}
                      </>
                  }
                </h4>
              </a>
            </div>
          }
        })}
      </div >
    </Grid >
  )
}

import { combineReducers } from "@reduxjs/toolkit";
import { appReducer } from "./appReducer"
import { userReducer } from "./userReducer"
import { redirectReducer } from "./redirectReducer"
import { cartReducer } from "./cartReducer"

const reducers = combineReducers({
    appReducer,
    userReducer,
    redirectReducer,
    cartReducer
})
export default reducers
import styled from "styled-components";
import { colors } from '../UI/Theme'

export const Wrapper = styled.div`
    width:100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px;
    h1{
        text-align: center; 
    }
    button{
        width:170px;
        height:50px;
        font-size:18px;
        border:none;
        background-color:${colors.themeColor.color};
        color:#fff;
        border-radius:10px;
        &:hover{ 
            cursor: pointer;
        }
    }
`
import moment from 'moment';
import { db } from '../firebase-config';
import { doc, setDoc } from 'firebase/firestore';

export const createUserChallengeData = (data, challengeDate) => {
    const phases = data.phases.map((res) => {
        return (
            {
                "name": res.name,
                "displayName": res.displayName,
                "startDate": moment(challengeDate, 'YYYY-MM-DD').add(res.startDay - 1, 'days').format('YYYY-MM-DD'),
                "endDate": moment(challengeDate, 'YYYY-MM-DD').add(res.endDay - 1, 'days').format('YYYY-MM-DD'),
                "startDay": res.startDay,
                "endDay": res.endDay,
                "pdfUrl": res.pdfUrl
            }
        )
    })
    const challenge = {
        "name": data.name,
        "displayName": data.displayName,
        "accessType": data.accessType,
        "id": data.id,
        "tag": data.tag,
        "startDate": moment(challengeDate).format('YYYY-MM-DD'),
        "endDate": moment(challengeDate, 'YYYY-MM-DD').add(data.numberOfDays - 1, 'days').format('YYYY-MM-DD'),
        "status": data.status ? data.status : "InActive",
        "phases": phases,
        "workouts": [],
        "onBoardingInfo": data.onBoardingInfo ? data.onBoardingInfo : {},
        "currentProgressInfo": {},
        "createdOn": data.createdOn ? data.createdOn : moment(new Date()).format('YYYY-MM-DD'),
        "numberOfDays": data.numberOfDays,
        "numberOfWeeks": data.numberOfWeeks,
        "imageUrl": data.imageUrl,
        "description": data.description,
        "isSchedule": false
    }
    return challenge
}

export const isExternalLink = (url) => {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
};


export const setLastAccessedDate = async () => {
    const uid = localStorage.getItem("@auth-token");
    if (uid) {
        try {
            const userRef = doc(db, "Users", uid);
            await setDoc(
                userRef,
                { lastAccessedDate: new Date() },
                { merge: true }
            );
        } catch (error) {
            console.log("error", error);
        }
    }
};



import React from 'react'
import { useNavigate } from "react-router-dom"
import { Wrapper } from "./defaultStyles"
export const Default = () => {
    const navigate = useNavigate();
    return (
        <Wrapper >
            <h1>404! <br /> Page not found.</h1>
            <button onClick={() => navigate(-1)}>Go Back</button>
        </Wrapper>
    )
}

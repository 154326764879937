import { Grid } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Wrapper } from "./styles/NavigationStyles"
import { BackButton } from "./BackButton"
import img from '../assets/navImage.png'
import { useSelector } from 'react-redux'


export const Navigation = ({
    heading,
    subHeading,
    navigation,
    backButton,
    navigationBgImage,
    navigationBgColor,
    bgColor,
    bgImage,
    href,
}) => {
    const location = useLocation()
    const { homeData } = useSelector(state => state.appReducer);
    const navData = homeData?.sections.filter(data => data?.widgetType.toLowerCase() === 'navigation')[0];
    if (navigation) {
        return (
            <Wrapper imgUrl={navData.bgNavigateImage || img} bgColor={navData.bgColor || "rgba(244, 159, 128, 0.7)"}  >
                {backButton && <BackButton  />}
                <Grid container className='main-container' direction='column' >
                    <nav>
                        <Grid item container direction='row' className="header" >
                            <Grid item container className='header-links' justifyContent="space-around">
                                <Link className={`${location.pathname.search(new RegExp('workouts')) === 1 ||
                                    location.pathname.search(new RegExp('program/workouts')) === 1 ||
                                    location.pathname.search(new RegExp('classes')) === 1 ||
                                    location.pathname === '/' ? 'selected' : 'link'}`} to='/classes'><p>CLASSES</p></Link >
                                <Link className={`${location.pathname.search(new RegExp('programs')) === 1 ? 'selected' : 'link'}`} to='/programs'>
                                    <p>PROGRAMS</p>
                                </Link>
                                <Link className={`${location.pathname.search(new RegExp('recipes')) === 1 ||
                                    location.pathname.search(new RegExp('program/recipes')) === 1
                                    ? 'selected' : 'link'}`} to='/recipes'>
                                    <p>NUTRITION</p>
                                </Link>
                                <Link className={`${location.pathname.search(new RegExp('education')) === 1 ? 'selected' : 'link'}`} to='/education'>
                                    <p>EDUCATION</p>
                                </Link>
                                {/* edit this last link */}
                                <Link className={`${location.pathname === '/calender' ? 'selected' : 'link'}`} to='/calender'>
                                    <p>CALENDAR</p>
                                </Link>
                            </Grid>
                        </Grid>
                    </nav>
                </Grid>
            </Wrapper >
        )
    }
    return <Wrapper imgUrl={bgImage || navData?.bgNavigateImage || img} bgColor={bgColor || navData?.bgColor || "rgba(244, 159, 128, 0.7)"}>
        {backButton && <BackButton href={href} />}
        <Grid container className='main-container' direction='column' >
            <Grid item container direction='row' className="header" >
                <div className="headings-container">
                    <p>{subHeading}</p>
                    <h3>{heading}</h3>
                </div>
            </Grid>
        </Grid>
    </Wrapper >
}

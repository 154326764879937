import styled from "styled-components";
import { colors } from "../../UI/Theme";
import layover from "../../assets/logo-background-layover.png";

export const Wrapper = styled.div`
  .main-container {
    width: 100%;
    height: 100%;
    font-weight: 500;
    .image-container {
      width: 100%;
      position: relative;
      height: 600px;
      .car {
        width: 100%;
        height: 100%;
        .slick-list {
          height: 600px !important;
        }
        .slick-track {
          height: 600px !important;
          div {
            height: 599.7px !important;
            position: relative;
            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: fill !important;
            }
            .abso {
              position: absolute;
              width: 100%;
              height: 90%;
              background: rgba(0, 0, 0, 0.3);
              z-index: 10;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: #fff;
              gap: 10px;
              top: 0;
              padding: 0 5em;
              h1 {
                font-size: 60px;
                max-width: 40%;
                font-family: ${colors.fonts.garacie};
              }
              h5 {
                font-size: 30px;
                max-width: 50%;
              }
              .link-btn {
                width: 20%;
                margin-top: 1em;
                button {
                  width: 100%;
                  height: 45px;
                  border-radius: 5px;
                  border: none;
                  font-size: 16px;
                  font-weight: 800;
                  color: #fff;
                  background-color: ${colors.themeColor.color};
                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .slick-dots {
          width: auto;
          right: 0.5em;
          transform: rotate(90deg);
          bottom: 300px;
          li {
            margin: 2px 5px;
            button {
              border-radius: 100%;
              width: 10px;
              height: 10px;
              background: #fff;
              margin: 0;
              margin-bottom: 0;
              &:before {
                font-size: 0px;
              }
            }
          }
          li.slick-active {
            button {
              background: ${colors.themeColor.color};
            }
          }
        }
      }

      .title-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 10px;
        left: 92px;
        top: 150px;
        h1 {
          font-weight: 500;
          font-size: 90px;
          font-family: ${colors.fonts.garacie};
          line-height: 99px;
          color: #ffffff;
          /* border: 2px solid #EC6524; */
        }
        h2 {
          font-weight: 500;
          font-size: 44px;
          line-height: 48px;
          letter-spacing: 0.08em;
          font-family: ${colors.fonts.garacie};
          color: #fff;
          background-color: rgba(0, 0, 0, 0.8);
          padding: 0.4em 0.4em 0em 0.5em;
        }
        h3 {
          font-family: ${colors.fonts.garacie};
          font-weight: 500;
          font-size: 28px;
          line-height: 31px;
          color: #fff;
          letter-spacing: 0.06em;
          background-color: rgba(0, 0, 0, 0.8);
          padding: 0.4em -0em 0em 0.7em;
        }
        button {
          width: 156px;
          margin-top: 2em;
          height: 52px;
          color: #fff;
          left: 92px;
          top: 482px;
          background: ${colors.themeColor.color};
          border: 2px solid #f49f80;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          font-weight: 500;
          font-size: 16px;
          font-family: ${colors.fonts.commuterRegular};
          line-height: 14px;
          letter-spacing: 0.02em;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }
      }
      @media only screen and (max-width: 1200px) {
        height: 400px;
        .car {
          .slick-dots {
            bottom: 200px;
          }
          .slick-list {
            height: 400px !important;
          }
          .slick-track {
            height: 400px !important;
            div {
              height: 400px !important;
              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
              }
              .abso {
                h1 {
                  font-size: 50px;
                  max-width: 50%;
                  font-family: ${colors.fonts.garacie};
                }
                h5 {
                  font-size: 25px;
                  max-width: 50%;
                }
              }
            }
          }
        }
      }
      @media only screen and (max-width: 992px) {
        height: 300px;
        .car {
          .slick-dots {
            bottom: 120px;
          }
          .slick-list {
            height: 300px !important;
          }
          .slick-track {
            height: 300px !important;
            div {
              height: 300px !important;
              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
              }
              .abso {
                gap: 5px;
                padding: 0 3em;
                h1 {
                  font-size: 40px;
                  max-width: 40%;
                  font-family: ${colors.fonts.garacie};
                }
                h5 {
                  font-size: 20px;
                  max-width: 50%;
                }
                .link-btn {
                  width: 30%;
                  button {
                    width: 100%;
                    height: 40px;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        height: 200px;
        .car {
          .slick-dots {
            bottom: 85px;
            li {
              margin: 0px 0px;
              button {
                width: 0px;
                height: 0px;
              }
            }
          }
          .slick-list {
            height: 200px !important;
          }
          .slick-track {
            height: 200px !important;
            div {
              height: 200px !important;
              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover !important;
              }
              .abso {
                gap: 3px;
                padding: 0 2em;
                h1 {
                  font-size: 30px;
                  max-width: 50%;
                  font-family: ${colors.fonts.garacie};
                }
                h5 {
                  font-size: 15px;
                  max-width: 70%;
                }
                .link-btn {
                  width: 32%;
                  margin-top: 0.5em;
                  button {
                    width: 100%;
                    height: 30px;
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
        height: 162px;
        .car {
          .slick-dots {
            bottom: 65px;
            li {
              margin: 0px 0px;
              button {
                width: 0px;
                height: 0px;
              }
            }
          }
          .slick-list {
            height: 162px !important;
          }
          .slick-track {
            height: 162px !important;
            div {
              height: 162px !important;
              img {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover !important;
              }
              .abso {
                gap: 3px;
                padding: 0 2em;
                h1 {
                  font-size: 22px;
                  max-width: 50%;
                  font-family: ${colors.fonts.garacie};
                }
                h5 {
                  font-size: 12px;
                  max-width: 70%;
                }
                .link-btn {
                  width: fit-content;
                  margin-top: 0.5em;
                  button {
                    width: 100%;
                    height: 26px;
                    padding: 0.5em 1.3em;
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .offer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5em 0 0em;
      p {
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }
      h2 {
        margin-top: 0.7em;
        font-weight: 500;
        font-size: 28px;
        line-height: 31px;
        font-family: ${colors.fonts.garacie};
        letter-spacing: 0.05em;
      }
      .row-container {
        margin: 4em 0;
        .inline {
          img {
            width: 100px;
            height: 100px;
            object-fit: fill;
            margin-bottom: 1em;
          }
        }
        h3 {
          margin: 0em 0 1.2em 0;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.05em;
        }
        p {
          width: 364px;
          text-align: center;
          font-weight: 400;
          font-size: 13px;
          line-height: 22px;
        }
      }
      @media only screen and (max-width: 1200px) {
        .row-container {
          margin: 3em 0;
          p {
            width: 320px;
          }
        }
      }
      @media only screen and (max-width: 992px) {
        p {
          font-size: 16px;
        }
        h2 {
          font-size: 24px;
        }
        .row-container {
          margin: 2em 0;
          .inline {
            img {
              width: 70px;
              height: 70px;
            }
          }
          h3 {
            margin: 0em 0 0.5em 0;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.05em;
          }
          p {
            width: 255px;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        margin-top: -3em;
        margin-bottom: 0em;
        padding-bottom: 2em;
        p {
          font-size: 12px;
        }
        h2 {
          font-size: 20px;
          margin-top: 10px;
        }
        .row-container {
          margin: 2em 0;
          .inline {
            img {
              width: 40px;
              height: 40px;
              margin-bottom: 10px;
            }
          }
          h3 {
            margin: 0em 0 0em 0;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.05em;
          }
          p {
            width: 160px;
            font-size: 10px;
            line-height: 15px;
          }
        }
      }
      @media only screen and (max-width: 500px) {
        margin-top: -3em;
        padding-bottom: 0;
        p {
          text-align: center;
          font-size: 14px;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.05em;
        }
        .row-container {
          margin: 2em 0;
          gap: 20px;
          .inline {
            img {
              width: 40px;
              height: 40px;
              margin-bottom: 10px;
            }
          }
          h3 {
            font-weight: 600;
            font-size: 15px;
            line-height: 19px;
          }
          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            width: 95%;
          }
        }
      }
    }

    .training-section {
      height: 760px;
      p {
        margin-top: 5em;
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }
      h2 {
        margin-top: 0.7em;
        margin-bottom: 1em;
        font-weight: 500;
        font-size: 28px;
        line-height: 31px;
        font-family: ${colors.fonts.garacie};
        letter-spacing: 0.05em;
      }
      .carousel {
        .rec-dot_active {
          background-color: ${colors.themeColor.color};
          box-shadow: 0 0 0px 2px ${colors.themeColor.color};
        }
        .rec-arrow {
          position: absolute;
          box-shadow: 0 0 0px 0px #333;
          font-size: 40px;
          color: ${colors.themeColor.color};
          background-color: rgba(255, 255, 255, 0);
        }
        .rec-arrow-right {
          right: 1em;
          margin-top: -1.5 !important;
          border: none;
        }
        .rec-arrow-left {
          margin-top: -1.5 !important;
          left: 1em !important;
          /* background-color:#fff; */
          z-index: 2;
        }
      }
      .image {
        width: 97%;
        height: 478px;
        border-radius: 10px;
        position: relative;
        user-select: none;
        padding: 0;
        img {
          pointer-events: none;
          width: 100%;
          height: 100%;
          user-select: none;
          object-fit: cover;
          border-radius: 10px;
        }
        .section {
          position: absolute;
          bottom: 20px;
          border-radius: 10px;
          background-color: rgba(225, 225, 225, 0.9);
          width: 70%;
          height: 80px;
          display: flex;
          .info {
            flex: 3;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 15px;
            gap: 6px;
            p:first-child {
              font-weight: 600;
              margin-top: 0em;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.02em;
              color: #111;
            }
            p {
              margin: 0;
              color: ${colors.themeColor.color};
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.02em;
              display: flex;
              align-items: center;
              gap: 5px;
              .icon {
                font-size: 1.3em;
                margin-top: -1px;
              }
            }
          }
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1.5;
            button {
              width: 180px;
              height: 46px;
              border-radius: 5px;
              background-color: ${colors.themeColor.color};
              border: none;
              color: #fff;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.02em;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1200px) {
        .image {
          .section {
            bottom: 15px;
            width: 95%;
            height: 75px;
            .info {
              padding: 0 13px;
              p:first-child {
                font-size: 14px;
              }
              p {
                font-size: 12px;
              }
            }
            .button {
              button {
                width: 140px;
                height: 40px;
                font-size: 12px;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 992px) {
        height: 580px;
        p {
          font-size: 16px;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 1em;
        }
        .carousel {
          .rec-arrow {
            font-size: 30px;
          }
          .rec-arrow-right {
            right: 0.5em;
          }
          .rec-arrow-left {
            left: 0.5em;
          }
        }
        .image {
          height: 290px;
          .section {
            bottom: 10px;
            width: 95%;
            height: 70px;
            .info {
              padding: 0 10px;
              p:first-child {
                font-size: 11px;
              }
              p {
                font-size: 9px;
              }
            }
            .button {
              button {
                width: 100px;
                height: 35px;
                font-size: 10px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        height: 500px;
        p {
          font-size: 12px;
        }
        h2 {
          font-size: 20px;
          margin-bottom: 1em;
        }
        .carousel {
          .rec-arrow {
            font-size: 30px;
          }
          .rec-arrow-right {
            right: 0.5em;
          }
          .rec-arrow-left {
            left: 0.5em;
          }
        }
        .image {
          height: 250px;
          .section {
            .button {
              button {
                font-size: 9px;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
        height: 437px;
        p {
            text-align: center;
          font-size: 14px;
          margin-top: 2.5em;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.05em;
        }
        .carousel {
          .rec-arrow {
            font-size: 30px;
            display: none;
          }
          .rec-arrow-right {
            /* right:-0.6em; */
          }
          .rec-arrow-left {
            left: -0.59em !important;
          }
        }
      }
    }

    .shop-section {
      padding: 2em 0 5em;
      p {
        margin-top: 1em;
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }
      h2 {
        margin-top: 0.7em;
        font-family: ${colors.fonts.garacie};
        margin-bottom: 2em;
        font-weight: 500;
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 0.05em;
      }
      .shop-items-container {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 30px;
        .item {
          height:auto ;
          /* box-shadow: 0 0 5px 1px #999; */
          width: 245px;
          padding:1em 0;
          position: relative;
          margin: 1em 0;
          border-radius: 10px;
          .icons-container {
            display: none;
          }
          &:hover {
            .icons-container {
              width: 100%;
              z-index: 5;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 90%;
              gap: 20px;
              position: absolute;
              .cart,
              .eye {
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                background-color: #fff;
                border-radius: 100%;
                .flaticon-shopping-bag,
                .flaticon-eye {
                  font-size: 28px;
                  color: #000;
                  margin-top: 0.2em;
                }
                &:hover {
                  background-color: ${colors.themeColor.color};
                  .flaticon-shopping-bag,
                  .flaticon-eye {
                    font-size: 28px;
                    color: #fff;
                    margin-top: 0.2em;
                  }
                }
              }
            }
          }
          .item-img {
            border-radius: 10px;
            width: 100%;
            /* box-shadow:0 0 5px 1px #999; */
            position: relative;
            height: 80%;
            object-fit: cover;
          }
          p,
          h4 {
            width: 227px;
            margin: 0.6em auto;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            /* font-weight:500; */
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      @media only screen and (max-width: 992px) {
        p {
          font-size: 16px;
          margin-top: 2em;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 1em;
        }
        .shop-items-container {
          gap: 20px;
          .item {
            width: 190px;
            margin: 1.8em 0;
            .item-img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            p,
            h4 {
              font-size: 12px;
              width: 100%;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        p {
          font-size: 12px;
        }
        h2 {
          font-size: 20px;
          margin-bottom: 1em;
        }
        .shop-items-container {
          gap: 10px;
          .item {
            width: 140px;
            border-radius: 10px;
            .item-img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
            p,
            h4 {
              font-size: 10px;
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
        padding-top: 0.6em;
        padding-bottom: 2em;
        p {
          text-align: center;
          font-size: 14px;
          margin-top: 1.5em;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.05em;
        }
        .shop-items-container {
          justify-content:start;
          gap: 10px;
          .item {
            margin: 1em auto 0;
            width: 47%;
            padding:3em 0 !important ;
            /* height: fit-content; */
            border-radius: 10px;
            .item-img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
            h4 {
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .main {
      p {
        /* margin-top:5em; */
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 0px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      h2 {
        margin-top: 0.7em;
        font-family: ${colors.fonts.garacie};
        margin-bottom: 1em;
        font-weight: 500;
        font-size: 28px;
        line-height: 0px;
        letter-spacing: 0.05em;
        text-align: center;
      }
      @media only screen and (max-width: 992px) {
        p {
          font-size: 16px;
          margin-top: 2em;
        }
        h2 {
          font-size: 24px;
          margin-bottom: 1em;
        }
      }
      @media only screen and (max-width: 768px) {
        p {
          font-size: 12px;
        }
        h2 {
          font-size: 20px;
          margin-bottom: 1em;
        }
      }
      @media only screen and (max-width: 500px) {
        p {
          text-align: center;

          font-size: 14px;
          margin-top: 1.5em;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.05em;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .workout-section {
      width: 100%;
      padding: 5em 6em 5em;
      margin: 0em auto;
      gap: 20px;
      .recipe {
        position: relative;
        height: 340px;
        width:100%;
        flex:1;
        overflow:hidden ;
        .imge-container{
          width:100% ;
        }
        a {
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          &:hover {
            box-shadow: 0 0 4px 0px #555;
          }
        }
        .abso {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.3);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          gap: 10px;
          h1 {
            font-size: 45px;
            max-width: 100%;
            font-family: ${colors.fonts.garacie};
          }
          h5 {
            font-size: 22px;
            max-width: 100%;
          }
        }
      }

      @media only screen and (max-width: 1200px) {
        padding: 3em 1em 2em;
        .recipe {
          height: 260px;
          p {
            font-size: 16px;
          }
          .abso {
            h1 {
              font-size: 40px;
            }
            h5 {
              font-size: 20px;
            }
          }
        }
      }
      @media only screen and (max-width: 992px) {
        padding: 4em 1em 4em;
        .recipe {
          height: 195px;
          p {
            font-size: 16px;
          }
          .abso {
            h1 {
              font-size: 35px;
            }
            h5 {
              font-size: 18px;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        padding: 2em 1em 2em;
        p {
          margin-top: 0.5em;
        }
        h2 {
          margin-bottom: 0.5em;
        }
        .recipe {
          height: 143px;
          .flaticon-dumbbell,
          .flaticon-recipe {
            font-size: 45px;
          }
          p {
            font-size: 12px;
          }
          .abso {
            gap: 5px;
            h1 {
              font-size: 20px;
              max-width: 100%;
              font-family: ${colors.fonts.garacie};
            }
            h5 {
              font-size: 12px;
              max-width: 100%;
            }
          }
        }
      }
      @media only screen and (max-width: 500px) {
        padding: 2em 1em 2em;
        justify-content: center;
        align-items: center;
        h2 {
          margin-bottom: 0;
        }
        .recipe {
          height: 126px;
          justify-content: center;
          align-items: center;
          a {
            img {
              width: 100%;
              height:100% ;
              object-fit:cover ;
            }
          }
          .abso {
            h1 {
              font-size: 30px;
            }
            h5 {
              font-size: 16px;
            }
          }
        }
      }
    }

    .login-section {
      width: 100%;
      height: 158px;
      background-color: #f49f80;
      background-image: linear-gradient(
          rgba(244, 159, 128, 0.5),
          rgba(244, 159, 128, 0.5)
        ),
        url(${layover});
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0em auto 0em;
      display: flex;
      justify-content: center;
      align-items: center;
      .data-container {
        width: 90%;
        display: flex;
        justify-content: space-between;
        h2 {
          font-weight: 500;
          font-size: 26px;
          line-height: 29px;
          color: #fff;
          font-family: ${colors.fonts.garacie};
          padding: 0.6em 0 0 0;
        }
        .button {
          width: 178px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 54px;
          background-color: #fff;
          color: ${colors.themeColor.color};
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          font-family: ${colors.fonts.commutersBold};
          border: none;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        height: 120px;
        margin: 0em auto 0em;
        .data-container {
          width: 95%;
          h2 {
            font-size: 20px;
          }
          .button {
            width: 150px;
            height: 44px;
            font-size: 16px;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        height: 100px;
        margin: 0em auto 0em;
        .data-container {
          width: 97%;
          /* display: flex; */
          align-items: center;
          h2 {
            font-size: 14px;
          }
          .button {
            width: 100px;
            height: 38px;
            font-size: 10px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        height: 80px;
        margin: 0em auto 0em;
        .data-container {
          padding: 15px;
          width: 97%;
          /* display: flex; */
          align-items: center;
          h2 {
            font-size: 11px;
            padding: 0;
            line-height: 18px;
          }
          .button {
            width: 83px;
            height: 28px;
            font-size: 9px;
          }
        }
      }
    }

    .workouts-container {
      width: 100%;
      margin: 0 auto 0em auto;
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      padding: 2em 0;
      p {
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }
      h2 {
        margin-top: 0em;
        font-weight: 500;
        font-size: 28px;
        line-height: 31px;
        font-family: ${colors.fonts.garacie};
        letter-spacing: 0.05em;
      }
      .carousel {
        .rec-pagination {
          display: none;
        }
        .rec-arrow {
          position: absolute;
          box-shadow: 0 0 0 #fff;
          font-size: 40px;
          color: #111;
          background: #ffffff99;
          margin-top: -2em;
          &:hover {
            background: #111;
            color: #fff;
          }
        }
        .rec-arrow-right {
          position: absolute;
          z-index: 2;
          border-radius: 5px;
          right: 1em;
        }
        .rec-arrow-left {
          position: absolute;
          z-index: 2;
          border-radius: 5px;
          left: 1em;
        }
      }
      .link {
        width: 100%;
        height: 100%;
      }
      .workout {
        width: 100%;
        height: 308px;
        border-radius: 10px;
        h4 {
          margin-top: 10px;
          line-height: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 14px;
          font-family: ${colors.fonts.commutersBold};
        }
        &:hover {
          cursor: pointer;
        }
      }
      .data-container {
        flex: 1;
      }
      .image-container {
        position: relative;
        border-radius: 10px;
        /* flex: 2; */
        width: 100%;
        height: 57%;
        object-fit: cover;
        img {
          border-radius: 10px;
          height: 100%;
        }
        .timer {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 42px;
          height: 42px;
          top: 7px;
          right: 7px;
          color: #fff;
          background-color: rgba(0, 0, 0);
          border-radius: 5px;
          p:first-child {
            font-size: 16px;
            font-family: ${colors.fonts.commutersBold};
          }
          p {
            font-weight: 600;
            color: #fff;
            font-size: 12px;
            line-height: 14px;
            font-family: ${colors.fonts.commuterRegular};
          }
        }
      }
      .desc {
        margin-top: 7px;
        padding: 0 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #2c2b22;
      }
      @media only screen and (max-width: 992px) {
        padding: 1.5em 0;
        p {
          font-size: 16px;
        }
        h2 {
          margin-top: -0.5em;
          font-size: 24px;
        }
        .carousel {
          .rec-arrow {
            font-size: 30px;
          }
          .rec-arrow-right {
            right: 0.5em;
          }
          .rec-arrow-left {
            left: 0.5em;
          }
          .iVQili {
            min-width: 10px;
            width: 40px;
            display: flex;
            align-items: center;
            height: 40px;
            justify-content: center;
          }
        }
        .link {
          width: 100%;
          height: 100%;
        }
        .workout {
          width: 100%;
          height: 230px;
        }
        .data-container {
          h4 {
            font-size: 14px;
            margin-top: 6px;
          }
        }
        .image-container {
          .timer {
            width: 37px;
            height: 37px;
            p:first-child {
              font-size: 13px;
            }
            p {
              font-size: 10px;
            }
          }
        }
        .desc {
          font-size: 10px;
          line-height: 14px;
          margin-top: 4px;
        }
      }
      @media only screen and (max-width: 768px) {
        padding: 1em 0;
        p {
          font-size: 12px;
          margin-top: 0.5em;
        }
        h2 {
          font-size: 20px;
          margin-top: -0.7em;
        }
        .carousel {
          .rec-arrow {
            font-size: 20px;
          }
          .sc-gsnTZi {
            min-width: 10px;
            width: 25px;
            display: flex;
            align-items: center;
            height: 25px;
            justify-content: center;
          }
        }
        .workout {
          width: 100%;
          height: 230px;
        }
        .image-container {
          .timer {
            width: 37px;
            height: 37px;
            p:first-child {
              font-size: 11px;
            }
            p {
              font-size: 8px;
            }
          }
        }
        .desc {
          font-size: 10px;
        }
      }

      @media only screen and (max-width: 500px) {
        padding: 0.5em 0 1em;
        p {
          font-size: 14px;
        }
        h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 0.05em;
          margin-top: -6px;
          /* margin-top:-15px; */
        }
        .workout {
          width: 155px;
          height: 207px;
        }
        .image-container {
          .timer {
            width: 37px;
            height: 37px;
            p:first-child {
              font-size: 11px;
            }
            p {
              margin-top: 0;
              font-size: 8px;
            }
          }
        }
        .desc {
          font-size: 10px;
        }
      }
    }

    .testimonial-section {
      min-height: 450px;
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
       
        .data {
          width: 70%;
          margin: -3.7em 0 0 auto;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            font-family: ${colors.fonts.commutersBold};
            margin-bottom: 2em;
          }
          h3 {
            font-weight: 500;
            font-size: 32px;
            line-height: 58px;
            font-family: ${colors.fonts.garacie};
            color: #fff;
          }
        }
        
      }
      .tests {
        .scrollContent{
          margin-top:10px;
          font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            font-family: ${colors.fonts.commutersBold};
            margin-bottom: 2em;
          /* width: 70%;
          overflow:auto;
          margin: -3.7em 0 0 auto;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            font-family: ${colors.fonts.commutersBold};
            margin-bottom: 2em;
          }
          h3 {
            font-weight: 500;
            font-size: 32px;
            line-height: 58px;
            font-family: ${colors.fonts.garacie};
            color: #fff;
          } */
        }
        .car {
          position: relative;
          width: 90%;
          height: 80%;
          .css-hn784z {
            right: 100px;
          }
          .MuiSvgIcon-root {
            font-size: 44px;
            &:hover {
              color: ${colors.themeColor.color};
            }
          }
        }
        .testimonial {
          .image {
            margin-top: 1em;
            display: flex;
            gap: 10px;
            align-items: center;
            .img {
              width: 100px;
              height: 100px;
              background-color: #fff;
            }
            .user {
              display: flex;
              flex-direction: column;
              color: #fff;
              p {
                font-weight: 600;
                font-size: 18px;
                margin-top: -1em;
                line-height: 54px;
                font-family: ${colors.fonts.commutersBold};
              }
              .stars {
                width: 250px;
                height: 20px;
                /* background-color:#fff ; */
                .star-ratings {
                  margin-top: -1em;
                  width: 250px !important;
                  height: 100% !important;
                }
                .star-container {
                  padding-right: 0 !important ;
                }
                .widget-svg {
                  width: 25px !important;
                  height: 25px !important;
                }
              }
            }
          }
          .data {
            margin-top: 2em;
            p {
              font-weight: 400;
              width: 90%;
              font-size: 15px;
              line-height: 28px;
            }
          }
        }
      }
      @media only screen and (max-width: 993px) {
        min-height: 350px;
        .info {
          .data {
            width: 75%;
            p {
              font-size: 16px;
              margin-bottom: 1em;
            }
            h3 {
              font-size: 26px;
            }
          }
        }
        .tests {
          .car {
            width: 95%;
            height: 65%;
            .css-hn784z {
              right: 40px;
            }
            .MuiSvgIcon-root {
              font-size: 38px;
            }
          }
          .testimonial {
            .image {
              gap: 5px;
              .img {
                width: 70px;
                height: 70px;
              }
              .user {
                p {
                  font-size: 16px;
                }
                .stars {
                  .widget-svg {
                    width: 20px !important;
                    height: 20px !important;
                  }
                }
              }
            }
            .data {
              margin-top: 1em;
              p {
                width: 90%;
                font-size: 13px;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        /* flex-direction:column !important ; */
        align-items:center ;
        justify-content:center !important ;
        .info {
          padding-top:2em ;
          .data {
            width: 100%;
            margin: 0em 0 0 0;
            p {
              text-align: center;
              font-size: 12px;
              margin-bottom: 0em;
            }
            h3 {
              text-align: center;
              font-size: 20px;
              margin-bottom: -3em;
            }
          }
        }
        .tests {
          margin-top:5em ;
          .car {
            width: 95%;
            height: 350px !important;
            .css-hn784z {
              right: 40px;
 
            }
            .MuiSvgIcon-root {
              font-size: 30px;
              top:0 !important
            }
          }
          .testimonial {
            .image {
              .img {
                width: 60px;
                height: 60px;
              }
              .user {
                p {
                  font-size: 14px;
                }
                .stars {
                  .widget-svg {
                    width: 15px !important;
                    height: 15px !important;
                  }
                }
              }
            }
            .data {
              p {
                font-size: 11px;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 500px) {
       min-height: 300px;
        .info {
          .data {
            width: 100%;
            margin: 0em 0 0 0;
            p {
              text-align: center;
              font-size: 12px;
              margin-bottom: 0em;
            }
            h3 {
              text-align: center;
              font-size: 18px;
              margin-bottom: -3em;
              line-height: 45px;
            }
          }
        }
        .tests {
          padding: 0 7px;
          .car {
            width: 95%;
            height: 75%;
            .css-hn784z {
              /* left:0px !important; */
            }
            .MuiSvgIcon-root {
              font-size: 30px;
              &:hover {
                color: ${colors.themeColor.color};
              }
            }
          }
          .testimonial {
            .image {
              .img {
                width: 60px;
                height: 60px;
              }
              .user {
                p {
                  font-size: 14px;
                }
                .stars {
                  .widget-svg {
                    width: 15px !important;
                    height: 15px !important;
                  }
                }
              }
            }
            .data {
              p {
                font-size: 11px;
              }
            }
          }
        }
      }
    }

    .blog-section {
      padding: 1em 0 4em;
      p {
        margin-top: 3em;
        color: #555;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
      }
      h2 {
        margin-top: 0.7em;
        font-family: ${colors.fonts.garacie};
        margin-bottom: 2em;
        font-weight: 500;
        font-size: 28px;
        line-height: 31px;
        letter-spacing: 0.05em;
      }
      .blogs-container {
        display: flex;
        width: 90%;
        margin: 0 auto;
        flex-wrap:wrap ;
        gap: 20px;
        .workout {
          width: 23%;
          max-width: 378px;
          height: 378px;
          border-radius: 10px;
          h4 {
            margin-top: 12px;
            line-height: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            font-family: ${colors.fonts.commutersBold};
          }
          &:hover {
            cursor: pointer;
          }
        }
        .data-container {
          flex: 1;
        }
        .image-container {
          position: relative;
          border-radius: 10px;
          flex: 2;
          width: 100%;
          height: 57%;
          object-fit: cover;
          img {
            border-radius: 10px;
            height: 100%;
          }
        }
        .desc {
          margin-top: 10px;
          padding: 0 5px;
          font-weight: 400;
          font-size: 13px;
          line-height: 17px;
          color: #2c2b22;
        }
      }
      @media only screen and (max-width: 993px) {
        min-height: 490px;
        margin: 0;
        padding: 2em 0;
        flex-direction:column ;
        p {
          font-size: 16px;
        }
        h2 {
          font-size: 24px;
        }
        .blogs-container {
          width: 95%;
          gap: 10px;
          justify-content:center ;
          .workout {
            width: 300px;
            height: auto;
            h4 {
              font-size: 12px;
              margin-top: 6px;
              line-height: 15px;
            }
          }
          .image-container {
            flex: 1;
          }
          .desc {
            font-size: 10px;
            margin-top: 5px;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        height: auto;
        margin: 0em 0 0em;
        padding: 0em 0;
        p {
          font-size: 16px;
        }
        h2 {
          font-size: 24px;
        }
        .blogs-container {
          display: flex;
          justify-content: space-around;
          width: 95%;
          height: auto;
          gap: 10px;
          flex-wrap: wrap;
          .workout {
            flex: 0;
            width: 300px;
            height: 267px;
            a {
              width: 240px !important;
            }
            h4 {
              font-size: 12px;
              margin-top: 6px;
              line-height: 15px;
            }
          }
          .image-container {
            flex: 1;
            height: 67%;
          }
          .desc {
            font-size: 10px;
            margin-top: 5px;
          }
        }
      }

      @media only screen and (max-width: 500px) {
        height: auto;
        margin: 0em 0 0em;
        padding: 0em 0 2em;
        p {
          text-align: center;

          margin-top: 1.5em;
          font-size: 14px;
        }
        h2 {
          font-size: 20px;
          margin-bottom: 1em;
        }
        .blogs-container {
          .carousel {
            width: 100%;
            .rec-dot_active {
              background-color: ${colors.themeColor.color};
              box-shadow: 0 0 0px 2px ${colors.themeColor.color};
            }
            .rec-arrow {
              display: none;
            }
          }
          .workout {
            flex: 0;
            height: 100%;
            a {
              width: 305px !important;
            }
            h4 {
              font-size: 12px;
              margin-top: 6px;
              line-height: 15px;
              text-align: center;
            }
          }
          .image-container {
            flex: 1;
            height: 67%;
          }
          .desc {
            font-size: 10px;
            margin-top: 5px;
            text-align: center;
          }
        }
      }
    }

    .footer {
      width: 100%;
      height: 401px;
      .container {
        width: 90%;
        height: 80%;
        margin: auto;
        display: flex;
        .left {
          flex: 1;
          display: flex;
          align-items: center;
          .image{
            width:90px ;
            img{
              width:100%;
              height:100%;
              object-fit:cover ;
            }
          }
          .line {
            height: 170px;
            width: 1.5px;
            margin: 0 25px;
            background-color: #fff;
          }
          .data {
            p {
              font-weight: 500;
              font-size: 38px;
              line-height: 56px;
              font-family: ${colors.fonts.garacie};
              color: #fff;
            }
            .para {
              font-weight: 600;
              font-size: 16px;
              line-height: 27px;
              width: 95%;
              font-family: ${colors.fonts.commutersBold};
            }
          }
        }
        .right {
          .form {
            margin-top: 2em;
            display: flex;
            gap: 10px;
            flex-direction: column;
            .input {
              height: 50px;
              width: 461px;
              border: none;
              padding: 0 18px;
              font-weight: 400;
              font-size: 14px;
              font-family: ${colors.fonts.commuterRegular};
              line-height: 22px;
              &::placeholder {
                color: #928e88;
              }
            }
            .submit {
              width: 100%;
              height: 50px;
              border: none;
              background-color: #000;
              color: #fff;
              font-weight: 600;
              font-size: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 22px;
              font-family: ${colors.fonts.commutersBold};
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .footer {
        .container {
          width: 95%;
          .left {
            .flaticon-newsletter {
              font-size: 60px;
            }
            .data {
              p {
                font-size: 36px;
              }
              .para {
                font-size: 17px;
              }
            }
          }
          .right {
            .input {
              width: 80%;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 993px) {
      .footer {
        .container {
          width: 95%;
          .left {
            .flaticon-newsletter {
              font-size: 50px;
              margin-left: 20px;
            }
            .data {
              p {
                font-size: 28px;
              }
              .para {
                font-size: 12px;
              }
            }
          }
          .right {
            width: 40%;
            .form {
              margin-top: 4em;
              gap: 7px;
              .input {
                height: 40px;
                padding: 0 10px;
                width: 100%;
                font-size: 12px;
                line-height: 20px;
                &::placeholder {
                  color: #928e88;
                }
              }
              .submit {
                width: 100%;
                height: 40px;
                font-size: 12px;
                font-family: ${colors.fonts.commutersBold};
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .footer {
        height: auto;
        .container {
          width: 97%;
          flex-direction: column;
          .left {
            padding-top: 1em;
            .line {
              height: 100px;
            }
            .flaticon-newsletter {
              font-size: 40px;
              margin-left: 25px;
            }
            .data {
              p {
                font-size: 24px;
              }
              .para {
                font-size: 10px;
              }
            }
          }
          .right {
            width: 60%;
            height: fit-content;
            margin: 0 auto;
            .form {
              margin-top: 1em;
              margin-bottom: 1em;
              gap: 7px;
              .input {
                height: 40px;
                padding: 0 10px;
                width: 100%;
                font-size: 12px;
                line-height: 20px;
                &::placeholder {
                  color: #928e88;
                }
              }
              .submit {
                width: 100%;
                height: 40px;
                font-size: 12px;
                font-family: ${colors.fonts.commutersBold};
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 500px) {
      .footer {
        height: auto;
        margin-top: 0em;
        .container {
          width: 97%;
          flex-direction: column;
          .left {
            .line {
              height: 100px;
            }
            .flaticon-newsletter {
              font-size: 45px;
              margin-left: 25px;
            }
            .data {
              p {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
              }
              .para {
                font-weight: 600;
                /* margin-top:10px; */
                font-size: 11px;
                line-height: 24px;
              }
            }
          }
          .form {
            gap: 7px;
            .input {
              height: 30px !important;
              width: 90%;
              font-size: 12px;
              line-height: 20px;
              &::placeholder {
                color: #928e88;
              }
            }
            .submit {
              width: 90%;
              height: 30px !important;
              font-size: 12px;
              font-family: ${colors.fonts.commutersBold};
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;

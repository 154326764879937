import styled from "styled-components";
import { colors } from '../../UI/Theme'


export const Wrapper = styled.section`
.main-container{
    padding-bottom:2em ;
    display:flex ;
    flex-direction:row ;
    justify-content: center;
    align-items: center;
    gap: 10em;
    .images-container{
        padding:0em 0;
        .left-image-container{
            margin:2em 0;
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            height:80%;
            width:100% ;
            .image{
                width:auto;
                align-self:center ;
                height:100%
            }
        }
    }
    .success-images{
        .left-image-container{
            margin-top:0.1em;
            height:70%;
            .image2{
                width:auto;
                height:100%
            }
        }
    }
    .message-container{
        background:aliceblue ;
        display:flex ;
        flex-direction:column ;
        flex:1;
        margin-right:1em;
        padding:2em;
            h4{
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color:#FF0032;
        }
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top:7px;
            text-align:center ;
        }
        button{
            width: 170px;
            height: 44px;
            margin-top:1em;
            border:none ;
            align-self:center ;
            background-color: #2C2B22;
            border-radius:5px ;
            a{
                color:#fff;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    .msg-container{
        margin:1em 0;
        align-self:center ;
    }
    .success{
        h4{
            color:#38BB3C;
            text-align:center ;
        }
        .note{
            width:100% ;
            margin-top:1em ;
            margin-bottom:1em ;
            display: flex;
            justify-content: center;
            flex-direction:column ;
            align-items: center;
            input{
                width:50%;
                padding:10px 0px 10px 10px;
            }
            button{
                    width: 170px;
                    height: 44px;
                    margin-top:1em;
                    border:none ;
                    background-color: #2C2B22;
                    border-radius:5px ;
                    a{
                        color:#fff;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
            .btn{
                color:#fff ;
            }
        }
    }
}

@media only screen and (max-width:1200px){
    .main-container{
        gap:2em;
     .success-images{
        .left-image-container{
            height:60%;
            .image2{
                width:80%;
            }
        }
    }
    .message-container{
        background:aliceblue ;
        display:flex ;
        flex-direction:column ;
        flex:2;
        margin-right:1em;
        padding:2em;
    
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top:7px;
            text-align:center ;
        }
        button{
            width: 170px;
            height: 44px;
            margin-top:1em;
            border:none ;
            align-self:center ;
            background-color: #2C2B22;
            border-radius:5px ;
            a{
                color:#fff;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    .msg-container{
        margin:1em 0;
        align-self:center ;
    }
}
}

@media only screen and (max-width:993px){
    .main-container{
        flex-direction:column ;
    .images-container{
        height:auto;
        .left-image-container{
            justify-content: center;
            margin-top:1em;
            height:80%;
            width:100% ;
            .image{
                width:auto;
                align-self:center ;
                height:90%
            }
            
        }
    }
    .success-images{
        .left-image-container{
            height:80%;
            .image2{
                width:50%;
            }
        }
    }
    .message-container{
        background:aliceblue ;
        display:flex ;
        flex-direction:column ;
        width:80vw ;
        margin-right:1em;
        padding:2em;
    
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top:7px;
            text-align:center ;
        }
        button{
            width: 170px;
            height: 44px;
            margin-top:1em;
            border:none ;
            align-self:center ;
            background-color: #2C2B22;
            border-radius:5px ;
            a{
                color:#fff;
            }
            &:hover{
                cursor: pointer;
            }
        }
    }
    .success{
        margin-top:-2em;
        h4{
            color:#38BB3C;
        }
        .note{
            width:100% ;
            input{
                width:80% ;
            }
        }
    }
}
}


@media only screen and (max-width:768px){
    .main-container{
    .images-container{
        height:auto;
        .left-image-container{
            margin:0em 0;
            width:100%;
            .image{
                width:auto;
                height:80%;
            }
        }
    }
    .success-images{
        .left-image-container{
            height:80%;
            .image2{
                width:40%;
                height:100% ;
            }
        }
    }
    .message-container{
        margin-top:-3.5em;
        h4{
            font-size: 16px;
            color:#FF0032;
        }
        p{
            font-size: 12px;
            margin-top:7px;
        }
        button{
            width: 140px;
            height: 40px;
            margin-top:0.6em;
            a{
                font-size:12px;
            }
        }
    }
    .success{
        margin-top:-1em;
        h4{
            color:#38BB3C;
        }
        .note{
            input{
                margin-top:20px;
                margin-left:10px ;
                width:70% ;
            }
        }
    }
}
}


@media only screen and (max-width:500px){
    .main-container{
    .images-container{
        height:auto;
        .left-image-container{
            margin:1em 0 0;
            width:100%;
            .image{
                width:40%;
                align-self:center ;
                height:100%
            }
        }
    }
    .success-images{
        .left-image-container{
            height:100%;
            .image2{
                width:70%;
                height:100% ;
            }
        }
    }
    .message-container{
        margin-top:0em;
        h4{
            font-size: 16px;
            color:#FF0032;
        }
        p{
            font-size: 12px;
            margin-top:7px;
        }
        button{
            width: 140px;
            height: 40px;
            margin-top:0.6em;
            a{
                font-size:12px;
            }
        }
    }
    .msg-container{
    font-size:12px ;
    }
    .success{
        margin-top:2em;
        h4{
            color:#38BB3C;
        }
        .note{
            input{
                width:70% !important ;
            }
        }
    }
}
}
`;
import { Grid } from '@mui/material'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Wrapper } from '../Shop/PaymentSuccessFailedStyles'
import paymentFailed2 from "../../assets/payment-failed-2.png"
import { Navigation } from '../../components/Navigation'

 const SubscriptionFailed = () => {
    const navigate = useNavigate()
    return (
        <>
            <Navigation heading="SUBSCRIPTION UPDATE FAILED" />
            <Wrapper>
                <Grid container className='main-container'>
                    <Grid item container className='images-container' justifyContent='center'>
                        {/* <div className="left-image-container">
                            <img src={paymentFailed1} alt="" className="image2" />
                        </div> */}
                        <div className="left-image-container">
                            <img src={paymentFailed2} className='image' alt="" />
                        </div>
                    </Grid>
                    <Grid item container className='message-container' alignItems="center" direction='column'>
                        <p>Unfortunately Subscription Update Failed.</p>
                        <button
                            onClick={() => navigate('/subscriptions')}
                        >
                            <Link to='/subscriptions'>
                                Try Again
                            </Link>
                        </button>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    )
}
export default SubscriptionFailed;
import { ActionTypes } from "../constants/action-types"
const initialState = {
    user: null,
    userLoading: false,
    userOrders: null
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.USER_LOADING
            :
            return {
                ...state, userLoading: true
            }
        case ActionTypes.USER_SUCCESS:
            return {
                ...state, userLoading: false, user: action.payload
            }
        case ActionTypes.LOGOUT:
            return {
                ...state, user: null
            }
        case ActionTypes.USER_ORDERS:
            return {
                ...state, userOrders: action.payload
            }
        default:
            return state
    }
}
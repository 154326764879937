import { Backdrop, Grid, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Wrapper } from "./styles/HeaderStyles"
import { Login } from './Login';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import {
    signOut,
} from "firebase/auth";
import { auth } from '../firebase-config';
import logo from '../assets/logo.png'
import mobile_logo from '../assets/mobile_logo.png'
import mobile_menu from '../assets/mobile_menu.png'
import Ripples from 'react-ripples'

export const Header = ({ isOpen, setIsOpen, setSnackBarOpen, setIsSubscriptionModalOpen }) => {
    const matches = useMediaQuery('(max-width:768px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const open = Boolean(anchorEl);
    const handlePress = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.userReducer)
    const { cart } = useSelector((state) => state.cartReducer)
    const { products } = useSelector((state) => state.appReducer)
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchOpenSecond, setSearchOpenSecond] = useState(false);

    const handleClick = () => {
        import('../app/actions/userActions').then(userActions => {
            return dispatch(userActions.redirectUser({ id: '', type: '' }));
        })
        setIsOpen(true)
        return
    }
    const handleLogout = () => {
        signOut(auth).then(() => {
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.logoutUser())
            })
            localStorage.removeItem('@auth-token')
            navigate('/')
        }).catch(error => {
            console.log('logout err', error);
        })
    }

    const handleChange = (val) => {
        setSearchQuery(val);
    }


    const renderResults = () => {
        let arr = products.filter(product => product.name.toLowerCase().startsWith(searchQuery.toLowerCase()))
        return <div className="search-items-container">
            {arr.length !== 0 ? arr.map((product, idx) => {
                return <div className="order" key={idx} onClick={() => {
                    setSearchOpen(false);
                    setSearchQuery('')
                }}>
                    <Link to={`/product/${product.id}`} className="order">
                        <div className="img-container">
                            <img src={product.coverImage} alt={product.name} />
                        </div>
                        <div className="order-info">
                            <div className="product-info">
                                <h4>{product.name}</h4>
                                <div className="info">
                                    <p>
                                        A${product.discountedPrice ? product.discountedPrice.toFixed(2) : product.price.toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            }) : <div className="no-result">
                <h4>No result found</h4>
            </div>
            }
        </div >
    }




    if (matches) {
        return <Wrapper>
            <Grid container className='main-container'>
                <>
                    <Grid flex={1} item container className='menu'>
                        <Ripples className='ripples'>
                            <button className='menu-btn' onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.toggleMenu()))}>
                                <img src={mobile_menu} alt="" />
                            </button>
                        </Ripples>
                    </Grid>
                    <Grid item container justifyContent='center' className='header' flex={2}>
                        <Link to='/'>
                            <img src={`${matches ? mobile_logo : logo}`} alt='logo' className='logo' />
                        </Link>
                        <Backdrop
                            style={{ zIndex: '2' }}
                            open={isOpen}
                        >
                            <Login setIsOpen={setIsOpen} setSnackBarOpen={setSnackBarOpen} setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
                        </Backdrop>
                    </Grid>
                    <Grid item container direction='row' justifyContent="center" alignItems='center' flex={1} className='right-header' >
                        <div className='links-container' >
                            <i className="flaticon-search"
                                onClick={() => {
                                    setSearchOpen(true);
                                    setSearchOpenSecond(true)
                                }}
                            ></i>
                            {!matches &&
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    {
                                        user ?
                                            <>
                                                <button className='button' onClick={handlePress}>
                                                    {/* <AccountCircleOutlinedIcon className="icon-size" /> */}
                                                    <i className="flaticon-user"></i>
                                                    My Account
                                                    <i className="flaticon-down-arrow"></i>
                                                    {/* <KeyboardArrowDownIcon /> */}
                                                </button>
                                            </>
                                            :
                                            <button className='button' onClick={handleClick}>
                                                <i className="flaticon-user"></i>
                                            </button>
                                    }
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {user && <MenuItem onClick={handleClose}>{user.firstName + " " + user.lastName}</MenuItem>}
                                        <MenuItem onClick={handleClose}>My account</MenuItem>
                                        <MenuItem>
                                            <Link to='/orders-history'>
                                                My Orders
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={() => { handleLogout(); handleClose(); }}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            }
                            <i onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.cartOpen()))} className="flaticon-shopping-bag">
                                {cart.length > 0 &&
                                    <div className="dot"></div>
                                }
                            </i>
                        </div>
                    </Grid>
                    {searchOpen &&
                        <>
                            <div className={`${searchOpenSecond ? 'search-bar' : 'search-bar close'}`}>
                                <CloseIcon className='close-search' onClick={() => {
                                    setSearchOpenSecond(false);
                                    setTimeout(() => {
                                        setSearchOpen(false);
                                    }, 500)
                                    setSearchQuery('');
                                }} />
                                <form className="search" onSubmit={(e) => {
                                    e.preventDefault();
                                    console.log('asbcusaigvtg')
                                }}>
                                    <input type="text" placeholder="Search" value={searchQuery} onChange={(e => handleChange(e.target.value))} />
                                </form>
                                <i className="flaticon-search"></i>
                            </div>
                        </>
                    }

                    {searchOpen && searchQuery !== '' &&
                        renderResults()
                    }
                </>
            </Grid>
        </Wrapper >
    } else {
        return (
            <Wrapper>
                <Grid container className='main-container'>
                    <Grid item container direction='row' className="header" >
                        <Grid item container className='header-links' justifyContent="flex-start" flex={2}>
                            <Link className={`${location.pathname === '/' ?
                                'link selected' :
                                'link'
                                }`}
                                to='/'>Home</Link>
                            {user &&
                                <Link className={`${location.pathname === '/classes' ||
                                    location.pathname.search(new RegExp('recipes')) === 1 ||
                                    location.pathname.search(new RegExp('workouts')) === 1 ||
                                    location.pathname.search(new RegExp('program')) === 1 ||
                                    location.pathname.search(new RegExp('education')) === 1 ||
                                    location.pathname.search(new RegExp('classes')) === 1 ||
                                    location.pathname.search(new RegExp('calender')) === 1 ?
                                    'link selected' :
                                    'link'
                                    }`}
                                    to='/classes'>My Access</Link>
                            }
                            <Link className={`${location.pathname.search(new RegExp('journal')) === 1 ? 'link selected' : 'link'}`} to='/journal'>
                                Journal
                            </Link>
                            <Link className={`${location.pathname.search(new RegExp('shop')) === 1 ||
                                location.pathname.search(new RegExp('product')) === 1 ||
                                location.pathname.search(new RegExp('orders')) === 1 ||
                                location.pathname.search(new RegExp('order')) === 1 ||
                                location.pathname.search(new RegExp('payment')) === 1
                                ? 'link selected' : 'link'}`} to='/shop'>
                                Shop
                            </Link>
                        </Grid>
                        <Grid item container justifyContent="center" flex={1}>
                            <Link to='/'>
                                <img src={logo} alt='logo' className='logo' />
                            </Link>
                        </Grid>
                        <Grid item container direction='row' justifyContent="center" alignItems='center' flex={2} className='right-header' >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                                {/* <FiSearch className="icon-size" /> */}
                                <i className="flaticon-search" onClick={() => {
                                    setSearchOpen(true);
                                    setSearchOpenSecond(true)
                                }}></i>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    {
                                        user ?
                                            <>
                                                <button className='button' onClick={handlePress}>
                                                    {/* <AccountCircleOutlinedIcon className="icon-size" /> */}
                                                    <i className="flaticon-user"></i>
                                                    My Account
                                                    <i className="flaticon-down-arrow"></i>
                                                    {/* <KeyboardArrowDownIcon /> */}
                                                </button>
                                            </>
                                            :
                                            <button className='button' onClick={handleClick}>
                                                <i className="flaticon-user"></i>
                                            </button>
                                    }
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {user && <MenuItem onClick={handleClose} style={{ fontFamily: 'Commuters-Regular' }}>{user.firstName + " " + user.lastName}</MenuItem>}
                                        <Link to='/profile' onClick={handleClose}>
                                            <MenuItem onClick={handleClose} style={{ fontFamily: 'Commuters-Regular' }}>
                                                Profile
                                            </MenuItem>
                                        </Link>
                                        <Link to='/orders-history' onClick={handleClose}>
                                            <MenuItem style={{ fontFamily: 'Commuters-Regular' }}>
                                                My orders
                                            </MenuItem>
                                        </Link>
                                        <MenuItem onClick={() => { handleLogout(); handleClose(); }} style={{ fontFamily: 'Commuters-Regular' }}>Logout</MenuItem>
                                    </Menu>
                                </div>
                                <i onClick={() => import('../app/actions/userActions').then(userActions => dispatch(userActions.cartOpen()))} className="flaticon-shopping-bag">
                                    {cart.length > 0 &&
                                        <div className="dot"></div>
                                    }
                                </i>
                            </div >
                        </Grid >
                        <Backdrop
                            style={{ zIndex: '2' }}
                            open={isOpen}
                        >
                            <Login setIsOpen={setIsOpen} setIsSubscriptionModalOpen={setIsSubscriptionModalOpen} />
                        </Backdrop>
                    </Grid >

                    {/* searchbar */}
                    {searchOpen &&
                        <>
                            <div className={`${searchOpenSecond ? 'search-bar' : 'search-bar close'}`}>
                                <CloseIcon className='close-search' onClick={() => {
                                    setSearchOpenSecond(false);
                                    setTimeout(() => {
                                        setSearchOpen(false);
                                    }, 500)
                                    setSearchQuery('');
                                }} />
                                <form className="search" onSubmit={(e) => {
                                    e.preventDefault();
                                    console.log('asbcusaigvtg')
                                }}>
                                    <input type="text" placeholder="Search" value={searchQuery} onChange={(e => handleChange(e.target.value))} />
                                </form>
                                <i className="flaticon-search"></i>
                            </div>
                        </>
                    }

                    {searchOpen && searchQuery !== '' &&
                        renderResults()
                    }
                    {/* searchbar end */}
                </Grid>
            </Wrapper >
        )
    }
}




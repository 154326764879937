import styled, { keyframes } from "styled-components";
import { colors } from '../../UI/Theme'
import { slideOutLeft } from 'react-animations'
import { slideInLeft } from 'react-animations'


const slideRight = keyframes`${slideInLeft}`;
const slideOut = keyframes`${slideOutLeft}`;

export const Wrapper = styled.section`
position:relative ;
.main-container{
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    margin:0 ;
    z-index: 10;
    height: 100vh;
    background:rgba(0,0,0,0.5) ;
    .cart{
        width:80%;
        height:100vh;
        position:absolute;
        left:0;
        z-index: 10;
        background-color:rgba(255,255,255,1) ;
        animation:0.3s ${slideRight};
        /* animation:0.3s ${slideOut}; */
        .heading-section{
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height:13%;
           img{
            width:170px;
           }
            p{
                position: absolute;
                bottom:5px;
                left:5px;
            }
            
        }
        .links-container{
            overflow:auto ;
            height:87%;
            display: flex;
            flex-direction: column;
            gap:0px;
            /* background-color:pink ; */
            .MuiListItemButton-root{
                padding:0 !important;
            }
            a{
                /* background-color:red; */
                width:100%;
                padding:1.5em 1em;
                display: flex;
                justify-content:space-between;
                align-items:center;
                font-weight:800 ;
                border-top:1px solid #cecccc;
            }
            .nested-links{
                padding:0.5em 0 0.5em 1em;
                justify-content:flex-start ;
                gap:10px;
                font-size:16px;
                border:none;
                color:#7c7a7a;
                span{
                    width:30px;
                    height:1px;
                    background-color:#7c7a7a ;
                }
            }
            .main-link-selected{
                color:${colors.themeColor.color};
            }
            .selected{
                color: #000;
                span{
                    background-color:#000 ;
                }
            }
            .last-item{
                pointer-events:none ;
                .contact-info{
                    margin-top:1em;
                    display: flex;
                    flex-direction:column;
                    gap:10px;
                    p{
                        color:#7c7a7a;
                        display: flex;
                        align-items: center;
                        font-size:14px;
                        gap:10px;
                        .phone{
                            font-size:22px ;
                        }
                    }
                }
            }
            .help{
                display:block;
                padding-top:1em;
            }
        }
    }
    .close{
        animation:0.3s ${slideOut};
    }
}
.close-icon{
                position: absolute;
                z-index: 10;
                font-size:35px;
                color:#fff;
                top:5px;
                right:5px;
                &:hover{
                    cursor: pointer;
                }
            }
`;
import { Grid } from '@mui/material'
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import { isExternalLink } from '../../utils/services';
export const Banner = ({ banners, setIsOpen, handleRedirects }) => {
    const { blogs, products } = useSelector(state => state.appReducer)
    const { user } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    return (
        <Grid item container className='image-container'>
            <Slider infinite={true}
                autoplay={true}
                autoplaySpeed={3000}
                vertical={true}
                dots={true}
                arrows={false}
                swipeToSlide={true}
                className='car'>
                {banners.map((b, idx) => {
                    return <div key={idx}>
                        <div onClick={e => e.preventDefault()}>
                            <img src={b.url} alt="yoga" />
                        </div>
                        {b.heading && b.subHeading && b.btnText &&
                            <div className='abso'>
                                <h1>{b.heading}</h1>
                                <h5>{b.subHeading}</h5>
                                {b.link.type === 'External Link' ?
                                    <a href={b.link.externalLink} target='_blank' className="link-btn" rel="noopener noreferrer">
                                        <button>{b.btnText}</button>
                                    </a>
                                    :
                                    <Link className="link-btn" onClick={(e => e.preventDefault())} to={
                                        `${b.link.type === "Blogs" ? `journal/${b.link.id}` :
                                            b.link.type === "Programs" ? 'programs' :
                                                b.link.type === "Education" ? `education/${b.link.id}` :
                                                    b.link.type === "Products" ? `product/${b.link.id}` :
                                                        b.link.type === "Recipes" ? `recipes/${b.link.id}` :
                                                            b.link.type === "Classes" ? `workouts/${b.link.id}` :
                                                                null
                                        }`
                                    }><button
                                        onClick={() => {
                                            if (b.link.type === 'Blogs') {
                                                let data = blogs.filter(workout => workout.id === b.link.id)
                                                navigate(`/journal/${b.link.id}`, { state: { blog: data[0] } })
                                            } else if (b.link.type === "Products") {
                                                let data = products.filter(workout => workout.id === b.link.id)
                                                navigate(`/product/${b.link.id}`, { state: { product: data[0] } })
                                            } else if (user) {
                                                import('../../app/actions/userActions').then(userActions => {
                                                    return dispatch(userActions.redirectUser({ id: b.link.id, type: b.link.type }));
                                                })
                                                import('../../app/actions/userActions').then(userActions => {
                                                    return dispatch(userActions.changeContent());
                                                })
                                                handleRedirects(user, b.link.type, b.link.id)
                                            }
                                            else {
                                                setIsOpen(true);
                                                import('../../app/actions/userActions').then(userActions => {
                                                    return dispatch(userActions.changeContent());
                                                })
                                                import('../../app/actions/userActions').then(userActions => {
                                                    return dispatch(userActions.redirectUser({ id: b.link.id, type: b.link.type }));
                                                })
                                            }
                                        }}
                                    >{b.btnText}</button></Link>
                                }
                            </div>
                        }
                    </div>
                })}
            </Slider>
        </Grid>
    )
}

import { Grid } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import Carousel from 'react-elastic-carousel'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { changeContent, redirectUser } from '../../app/actions/userActions';
import { Link } from 'react-router-dom';

export const Classes = ({ handleRedirects, workouts, setIsOpen, bgColor, heading, subHeading }) => {
    const matches = useMediaQuery('(max-width:768px)');
    const matchesSM = useMediaQuery('(max-width:580px)');
    const matchesXs = useMediaQuery('(max-width:500px)');
    const { user } = useSelector(state => state.userReducer)
    const dispatch = useDispatch()
    return (
        <div >
            <Grid item container direction='row' className='login-section'>
                <div className="data-container">
                    <h2>Choose Your Classes and Start Your Traning</h2>
                    {user ?
                        <Link className='button' to='/classes'>START NOW</Link>
                        :
                        <button className='button' onClick={() => setIsOpen(true)}>LOGIN NOW</button>
                    }
                </div>
            </Grid>
            <div className="workouts-container" style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#fff'}` }}>
                <p>{subHeading}</p>
                <h2>{heading}</h2>
                <Carousel itemPadding={[10, 10]} itemsToShow={matchesXs ? 2 : matchesSM ? 2 : matches ? 3 : 4} className="carousel">
                    {workouts &&
                        workouts.map((workout, idx) => {
                            return <Link to={`/workouts/${workout.id}`} key={idx} onClick={(e) => { e.preventDefault(); }} className='link'>
                                <Grid item key={idx} container direction='column' className='workout' onClick={() => {
                                    if (user) {
                                        import('../../app/actions/userActions').then(userActions => {
                                            return dispatch(userActions.changeContent());

                                        })
                                        import('../../app/actions/userActions').then(userActions => {
                                            return dispatch(userActions.redirectUser({ id: workout.id, type: "Workouts" }));

                                        })
                                        handleRedirects(user)
                                    } else {
                                        setIsOpen(true)
                                        import('../../app/actions/userActions').then(userActions => {
                                            return dispatch(userActions.changeContent());

                                        })
                                        import('../../app/actions/userActions').then(userActions => {
                                            return dispatch(userActions.redirectUser({ id: workout.id, type: "Workouts" }));

                                        })
                                    }
                                }}>
                                    <div className="image-container">
                                        <img src={workout.coverImage} width='100%' height="100%" alt="workout thumbnail" />
                                        <div className="timer">
                                            <p>{workout.workoutTime}</p>
                                            <p>Mins</p>
                                        </div>
                                    </div>
                                    <div className="data-container">
                                        <h4 style={{ padding: '0 5px 0 5px' }}>{workout.displayName}</h4>
                                        <p className="desc">
                                            {_.slice(workout.description, 0, 60)}...
                                        </p>
                                    </div>
                                </Grid>
                            </Link>
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}


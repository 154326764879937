import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { Navigation } from '../../components/Navigation'
import { Wrapper } from '../Shop/PaymentSuccessFailedStyles'
import paymentSuccess2 from "../../assets/payment-success-2.png"
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import addMixpanelEvent from '../../utils/useMixPanel'
import { useSelector } from 'react-redux'


const SubscriptionSuccess = () => {
    const [urlParams] = useSearchParams();
    const { user } = useSelector(state => state.userReducer)

    useEffect(() => {
        if (user) {
            addMixpanelEvent({ event: `Subscription upgrade to ${urlParams.get('subName')} successful`, uid: user?.id, firstName: user?.firstName, lastName: user?.lastName })
        }
    }, [])
    const navigate = useNavigate();
    return (
        <>
            <Navigation heading="SUBSCRIPTION UPDATED" />
            <Wrapper>
                <Grid container className='main-container'>
                    <Grid item container className='images-container success-images' justifyContent='center'>
                        <div className="left-image-container">
                            <img src={paymentSuccess2} className='image2' alt="" />
                        </div>
                    </Grid>
                    <Grid item container className='message-container success' alignItems="center" direction='column'>
                        <h4>Subscription Updated Successfully</h4>
                        <button
                            onClick={() => navigate('/')}
                        >
                            <Link to='/'>
                                Go to home
                            </Link>
                        </button>
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    )
}
export default SubscriptionSuccess;
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Wrapper } from './styles/BackButtonStyles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export const BackButton = ({ href }) => {
    const navigate = useNavigate();
    console.log('sss', { href });
    return (
        <Wrapper>
            <ArrowBackIcon className='arrow' onClick={() => href ? navigate(href) : navigate(-1)} />
        </Wrapper>
    )
}

import React, { useEffect, useState } from 'react'
import { Wrapper } from "./styles/LoginStyles"
import { FcGoogle } from "react-icons/fc";
import CloseIcon from '@mui/icons-material/Close';
import facebook from "../assets/facebook.png"
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import {
    collection,
    addDoc,
    doc,
    getDoc,
    query,
    getDocs,
    where,
    setDoc,
    serverTimestamp,
    onSnapshot,
} from "firebase/firestore";
import { auth, db, functions } from '../firebase-config'
import { Alert, CircularProgress, Grid, Snackbar } from '@mui/material';
import logo from "../assets/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { changeContent, userFound } from '../app/actions/userActions';
import { createUserChallengeData, setLastAccessedDate } from "../utils/services"
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import addMixpanelEvent from '../utils/useMixPanel';

export const Login = ({ setIsOpen, setIsSubscriptionModalOpen }) => {
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
    const { id, type } = useSelector(state => state.redirectReducer)
    const { workouts, recipes, education, products, blogs, content } = useSelector(state => state.appReducer)
    const [selected, setSelected] = useState(true)
    const dispatch = useDispatch()
    const sendWelcomeMail = httpsCallable(functions, 'sendWelcomeEmail');
    const [isSignup, setIsSignup] = useState(false)
    const navigate = useNavigate();
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [state, setState] = useState({
        email: '',
        password: '',
        error: null,
        loading: false,
        firstName: '',
        lastName: "",
    })



    const handleKeypress = (e, prop) => {
        if (e.which === 13) {
            if (prop === 'login') {
                signInWithEmail();
            } else {
                handleSignIn();
            }
        }
    };

    useEffect(() => {
        setSelected(true)
    }, [])

    const handleRedirects = (user) => {
        let data;
        switch (type) {
            case "Education":
                data = education.filter(workout => workout.id === id)
                return navigate(`/education/${education.id}`, { state: { workout: data[0], heading: 'EDUCATION' } })
            case "Products":
                data = products.filter(workout => workout.id === id)
                return console.log(data[0]);
            case "Programs":
                return navigate('/programs')
            case "Blogs":
                data = blogs.filter(workout => workout.id === id)
                return navigate(`/journal/article/${data[0].id}`, { state: { blog: data[0] } });
            case "Recipes":
                data = recipes.filter(workout => workout.id === id)
                if (user.accessType.toLowerCase() === 'platinum') {
                    return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                } else if (user.accessType.toLowerCase() === 'gold') {
                    if (data[0].accessType.toLowerCase() === 'platinum ') {
                        return navigate(`/shop`);
                    } else {
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    }
                } else {
                    if (data[0].accessType.toLowerCase() === 'free') {
                        return navigate(`/recipes/nutrition/${data[0].id}`, { state: { recipe: data[0], heading: data[0].title, subHeading: "Nutrition" } })
                    } else {
                        return navigate('/shop')
                    }
                }
            case "Workouts":
                data = workouts.filter(workout => workout.id === id)
                return navigate(`/workouts/${data[0].id}`, { state: { workout: data[0], heading: 'CLASSES' } })
            default:
                return
        }
    }


    const handleSignIn = () => {
        const { email, password, firstName, lastName } = state;
        if (email === '' || password === '' || firstName === '' || lastName === '') {
            setState({ ...state, error: 'Please fill all fields.' });
            return
        }
        setState({ ...state, loading: true });
        signUpWithEmail();
    }

    const signUpWithEmail = () => {
        const { email, password } = state;
        createUserWithEmailAndPassword(auth, email, password)
            .then((response) => {
                const { uid } = response.user;
                setUser(uid);
            })
            .catch(error => {
                if (error.message.includes('Error (auth/email-already-in-use)')) {
                    window.alert('Email already registred!')
                }
                setState({ ...state, loading: false });
            });
    }

    const setUser = (uid = null) => {
        if (uid === null) {
            setState({ ...state, loading: false });
            return
        }
        const { email, firstName, lastName } = state;
        let data = {
            id: uid,
            firstName,
            lastName,
            email: email.toLowerCase(),
            accessType: "free",
            country: 'unavailable',
            signUpDate: serverTimestamp(),
            fitnessLevel: 1,
        };
        addUser(data).then(async res => {
            await addMixpanelEvent({ event: "User Signed Up", uid: uid, firstName: firstName, lastName: lastName })
            setProgramByProgramHistoryByEmail(data.email)
                .then(
                    async res => {
                        setState({ ...state, loading: false });
                        // makeSignIn(uid);
                        await signInWithEmail()
                        //  Alert.alert("Success!","Program restored successfully");
                    }
                )
                .catch(err => {
                    setState({ ...state, loading: false });
                    signInWithEmail()
                    // makeSignIn(uid);
                })
        })
            .catch((err) => {
                console.log(err)
            })
    }
    const addUser = async (data) => {
        await setDoc(doc(db, "Users", data.id), data).then(docRef => {
            console.log("Document Field has been updated successfully");
        }).catch(error => {
            setState({ ...state, loading: false });
            console.log(error);
        })
    }

    const setProgramByProgramHistoryByEmail = async (email) => {
        const uid = await localStorage.getItem('@auth-token');
        let promiseList = [];
        const collectionRef = query(collection(db, `ProgramPurchaseHistory`), where("email", "==", email));
        const querySnapshot = await getDocs(collectionRef);
        // querySnapshot.forEach((doc) => {
        //     arr.push(doc.data())
        // });
        promiseList = querySnapshot.map((res) => {
            const data = res.data();
            return new Promise(async (resolve, reject) => {
                const programData = await getProgramByShopifyId(data.shopify_product_id);
                if (programData) {
                    const checkUserProgram = await checkIfUserHasAlreadyChallenge(uid, programData.id);
                    if (checkUserProgram) {
                        resolve("already has program")
                    }
                    else {
                        const addProgram = await addProgramToUser(programData);
                        if (addProgram)
                            resolve("Program restore");
                        else
                            resolve("Program not restore")
                    }
                }
            })
        }
        )

        Promise.all(promiseList)
            .then(res => {
                console.log(",,,,", res)
                return true;
            })
            .catch(res => {
                console.log(res);
                return false;
            })
    }


    const getProgramByShopifyId = async (shopify_id) => {
        const collectionRef = query(collection(db, `Programs`), where("shopifyProductId", "==", shopify_id));
        const querySnapshot = await getDocs(collectionRef);
        if (querySnapshot.length > 0) {
            return querySnapshot[0].data();
        } else {
            return false;
        }
    }
    const checkIfUserHasAlreadyChallenge = async (uid, programId) => {
        const collectionRef = query(collection(db, `Users/${String(uid)}/Programs`), where("id", "==", programId));
        const querySnapshot = await getDocs(collectionRef);
        if (querySnapshot.length > 0) {
            return querySnapshot[0].data();
        } else {
            return false;
        }
    }
    const addProgramToUser = async (programData) => {
        let uid = await localStorage.getItem('@auth-token');
        const data = createUserChallengeData(programData, new Date());
        const q = query(collection(db, `Users/${uid}/Programs`, data.id));
        setDoc(q, { data }, { merge: true })
            .then(docRef => {
                console.log("Document Field has been updated successfully");
            })
            .catch(error => {
                console.log(error);
            })
        // userRef.doc(data.id).set(data).then((res) => {
        //     return true
        // }).catch((err) => {
        //     return false
        // })
    }



    const setError = (error) => {
        if (error.code === 'auth/invalid-email') {
            setState({ ...state, error: 'That email address is invalid!' });
        }
        if (error.code === 'auth/wrong-password') {
            setState({ ...state, error: 'The password is invalid or the user does not have a password.' });
        }
        if (error.code === 'auth/user-not-found') {
            setState({ ...state, error: ' There is no user record corresponding to this email. The user may have been deleted.' });
        }
    }

    const signInWithGoogle = async () => {
        try {
            const res = await signInWithPopup(auth, googleProvider);
            const user = res.user;
            try {
                localStorage.setItem('@auth-token', user.uid)
                setLastAccessedDate()
            } catch (error) {
                console.log(error);
            }
            const q = await checkUserInDb(user.uid);
            console.log({ q });
            if (!q) {
                await addMixpanelEvent({ event: "User Signed Up", uid: user?.uid, firstName: user?.displayName, lastName: '' })
                await addDoc(collection(db, "Users"), {
                    accessType: "free",
                    country: 'unavailable',
                    signUpDate: serverTimestamp(),
                    fitnessLevel: 1,
                    uid: user.uid,
                    firstName: user.displayName,
                    lastName: "",
                    authProvider: "google",
                    email: user.email,
                });
            }
            checkUserInDb(user.uid)
                .then(res => {
                    console.log(res.data());
                    import('../app/actions/userActions').then(userActions => {
                        return dispatch(userActions.userFound(res.data()))
                    })
                    setIsOpen(false)
                    import('../app/actions/userActions').then(userActions => {
                        return dispatch(userActions.changeContent())
                    })
                    setState({ ...state, loading: false })
                    if (res)
                        console.log('signed in');
                    else
                        setError({ code: 'auth/user-not-found' });
                })
            setIsOpen(false)
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.changeContent())
            })
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };
    const signInWithFacebook = async () => {
        try {
            const res = await signInWithPopup(auth, facebookProvider);
            console.log('in with facebook');
            const user = res.user;
            try {
                localStorage.setItem('@auth-token', user.uid)
                await setLastAccessedDate();
            } catch (error) {
                console.log(error);
            }
            const q = await checkUserInDb(user.uid);
            if (!q) {
                await addMixpanelEvent({ event: "User Signed Up", uid: user?.uid, firstName: user?.displayName, lastName: '' })
                await addDoc(collection(db, "Users"), {
                    accessType: "free",
                    country: 'unavailable',
                    signUpDate: serverTimestamp(),
                    fitnessLevel: 1,
                    uid: user.uid,
                    firstName: user.displayName,
                    lastName: "",
                    authProvider: "Facebook",
                    email: user.email,
                });
            }
            checkUserInDb(user.uid)
                .then(res => {
                    import('../app/actions/userActions').then(userActions => {
                        return dispatch(userActions.userFound(res.data()))
                    })
                    setIsOpen(false)
                    import('../app/actions/userActions').then(userActions => {
                        return dispatch(userActions.changeContent())
                    })
                    setState({ ...state, loading: false })
                    if (res)
                        console.log('signed in');
                    else
                        setError({ code: 'auth/user-not-found' });
                })
            setIsOpen(false)
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.changeContent())
            })
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
    };

    const checkUserInDb = async (uid) => {
        const userRef = doc(db, 'Users', uid);
        const docs = await getDoc(userRef);
        if (!docs.exists) {
            return false
        } else {
            console.log(docs.data());
            return docs;
        }
    }

    const signInWithEmail = async (uid) => {
        setState({ ...state, loading: true })
        const { email, password } = state
        await signInWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                const uid = res.user.uid
                try {
                    localStorage.setItem('@auth-token', uid)
                    await setLastAccessedDate();
                } catch (error) {
                    console.log(error);
                }
                checkUserInDb(uid)
                    .then(res => {
                        console.log(res.data());

                        import('../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.userFound(res.data()))
                        })
                        if (id && type) {
                            handleRedirects(res)
                        }
                        setState({ ...state, loading: false })
                        setIsOpen(false)
                        if (state.firstName) {
                            sendWelcomeMail({ email: email, name: res?.firstName })
                            setIsSubscriptionModalOpen(true);
                            // navigate('/subscriptions');
                        }
                        if (content) {
                            import('../app/actions/userActions').then(userActions => {
                                return dispatch(userActions.changeContent())
                            })
                        }
                        setState({
                            email: '',
                            password: '',
                            firstName: '',
                            lastName: ''
                        })
                        if (res)
                            console.log('signed in');
                        else
                            setError({ code: 'auth/user-not-found' });
                    })
            })
            .catch(error => {
                console.log('err', error);
                setState({ ...state, loading: false })
                setError(error);
            })
    }


    const handleForgotPassword = async () => {
        if (state.email === '') {
            window.alert('Enter Email!');
            return
        }
        sendPasswordResetEmail(auth, state.email)
            .then(() => {
                setSnackBarOpen(true);
                console.log('pasword updated');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                // ..
            });
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };
    return (
        <Wrapper>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackBarOpen} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Password Reset Email Sent!
                </Alert>
            </Snackbar>
            <Grid container className='main-container' direction='column' alignItems='center' >
                <CloseIcon className='close' onClick={() => {
                    setIsOpen(false);
                    if (content) {
                        import('../app/actions/userActions').then(userActions => {
                            return dispatch(userActions.changeContent())
                        })
                    }
                }} />
                <p>Core Sculpt</p>
                <div className="inner-section">
                    <div className="tab-container">
                        <div onClick={() => setSelected(true)} className={`${selected ? 'login-btn selected' : 'login-btn'}`}>Sign Up</div>
                        <div onClick={() => setSelected(false)} className={`${!selected ? 'login-btn selected' : 'login-btn'}`}>Sign In</div>
                    </div>
                    {!selected ?
                        <div className="login-container">
                            <Grid item container flexDirection='column' className='login-container' >
                                {content ? <>
                                    <img src={logo} alt="logo" className='logo' />
                                    <h4 >Please sign in to activate/purchase this content.</h4>
                                </>
                                    :
                                    <img src={logo} alt="logo" className='logo' />
                                }
                                <input placeholder='Email' type="text" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                                <input placeholder="Password" onKeyPress={(e) => handleKeypress(e, 'login')} type="password" value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} />
                                <a href="/#" onClick={handleForgotPassword} > <p>Forgot Password?</p></a>
                                <div className='error-container'>
                                    <p className='error'>{state.error}</p>
                                </div>
                                <button className="signin-btn" onClick={signInWithEmail}>
                                    {state.loading ?
                                        <CircularProgress
                                            size={25}
                                            style={{ marginTop: '4px', color: '#fff' }}
                                        /> : "Sign In"}
                                </button>
                                <div className="or">
                                    <div className="line"></div>
                                    <p>or</p>
                                    <div className="line"></div>
                                </div>
                                <div className='logos-container'>
                                    <button onClick={() => signInWithGoogle()} style={{ border: 'none', backgroundColor: '#fff' }}>
                                        <FcGoogle className='hover' />
                                    </button>
                                    {/* add signinWithFacebook() */}
                                    <button onClick={() => { signInWithFacebook() }} style={{ border: 'none', backgroundColor: '#fff' }}>
                                        <img src={facebook} className="hover size" alt="" />
                                    </button>
                                </div>
                            </Grid>
                        </div>
                        :
                        <div className="login-container">
                            <Grid item container flexDirection='column' className='login-container' >
                                {content ? <>
                                    <img src={logo} alt="logo" className='logo' />
                                    <h4 >Please sign in to activate/purchase this content.</h4>
                                </>
                                    :
                                    <img src={logo} alt="logo" className='logo' />
                                }
                                <input placeholder='First Name' type="text" value={state.firstName} onChange={(e) => setState({ ...state, firstName: e.target.value })} />
                                <input placeholder='Last Name' type="text" value={state.lastName} onChange={(e) => setState({ ...state, lastName: e.target.value })} />
                                <input placeholder='Email' type="text" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                                <input placeholder="Password" type="password" onKeyPress={(e) => handleKeypress(e)} value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} />
                                {/* <p>Forgot Password?</p> */}
                                <button className="signin-btn" onClick={handleSignIn}>
                                    {state.loading ?
                                        <CircularProgress
                                            size={25}
                                            style={{ marginTop: '4px', color: '#fff' }}
                                        /> : "Sign Up"}
                                </button>
                                <div className="or">
                                    <div className="line"></div>
                                    <p>or</p>
                                    <div className="line"></div>
                                </div>
                                <div className='logos-container'>
                                    <button onClick={() => signInWithGoogle()} style={{ border: 'none', backgroundColor: '#fff' }}>
                                        <FcGoogle className='hover' />
                                    </button>
                                    {/* add signinWithFacebook() */}
                                    <button onClick={() => { signInWithFacebook() }} style={{ border: 'none', backgroundColor: '#fff' }}>
                                        <img src={facebook} className="hover size" alt="" />
                                    </button>
                                </div>
                            </Grid>
                        </div>
                    }
                </div>
            </Grid>
        </Wrapper >
    )
}


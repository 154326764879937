export const ActionTypes = {
    WORKOUTS_LOADING: "WORKOUTS_LOADING",
    RECIPES_LOADING: "RECIPES_LOADING",
    PROGRAMS_LOADING: "PROGRAMS_LOADING",
    WORKOUTS: "WORKOUTS",
    RECIPES: "RECIPES",
    PROGRAMS: "PROGRAMS",
    USER_LOADING: "USER_LOADING",
    USER_SUCCESS: "USER_SUCCESS",
    LOGOUT: "LOGOUT",
    ACTIVE_PROGRAM: "ACTIVE_PROGRAM",
    USER_PROGRAMS: 'USER_PROGRAMS',
    ALL_PROGRAMS: 'ALL_PROGRAMS',
    OTHER_PROGRAMS: 'OTHER_PROGRAMS',
    ACTIVE_WORKOUTS: "ACTIVE_WORKOUTS",
    GOLD_WORKOUTS: "GOLD_WORKOUTS",
    PREMIUM_WORKOUTS: "PREMIUM_WORKOUTS",
    FREE_WORKOUTS: "FREE_WORKOUTS",
    ACTIVE_RECIPES: "ACTIVE_RECIPES",
    GOLD_RECIPES: "GOLD_RECIPES",
    PREMIUM_RECIPES: "PREMIUM_RECIPES",
    PLANS: "PLANS",
    FREE_RECIPES: "FREE_RECIPES",
    GET_JOURNAL_DATA: "GET_JOURNAL_DATA",
    GOLD_EDUCATION: "GOLD_EDUCATION",
    PREMIUM_EDUCATION: "PREMIUM_EDUCATION",
    FREE_EDUCATION: "FREE_EDUCATION",
    CALENDER_DATA: "CALENDER_DATA",
    PAID_PROGRAMS: "PAID_PROGRAMS",
    NEXT_DAY: "NEXT_DAY",
    PREVIOUS_DAY: "PREVIOUS_DAY",
    EDUCATION: "EDUCATION",
    ACTIVE_PROGRAMS_USER_INFORMATION: "ACTIVE_PROGRAMS_USER_INFO",
    PRODUCTS: "PRODUCTS",
    HOME_PAGE: "HOME_PAGE",
    BLOGS: "BLOGS",
    WORKOUTS_ID: "WORKOUTS_ID",

    SET_CART: 'SET_CART',

    REDIRECTS: "REDIRECTS",
    CONTENT: "CONTENT",

    USER_ORDERS: 'USER_ORDERS',
    ADD_TO_CART: "ADD_TO_CART",
    REMOVE_CART_ITEMS: "REMOVE_CART_ITEMS",
    TOGGLE_CART_ITEM_AMOUNT: "TOGGLE_CART_ITEM_AMOUNT",
    CLEAR_CART: "CLEAR_CART",
    COUNT_CART_TOTALS: "COUNT_CART_TOTALS",
    CART_OPEN: "CART_OPEN",
    CART_CLOSE: "CART_CLOSE",
    OPEN: "OPEN",
    CLOSE: "CLOSE",
    MENU_OPEN: "MENU_OPEN",
    MENU_CLOSE: "MENU_CLOSE",
    TOGGLE_MENU: "TOGGLE_MENU",

    FROM_CART: "FROM_CART",
    FROM_CART_FALSE: "FROM_CART_FALSE",
}
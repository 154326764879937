import { Alert, Grid, Snackbar, useMediaQuery } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import StarRatings from 'react-star-ratings'

export const Testimonials = ({ testimonial, bgImage, heading, subHeading, }) => {
    const matches = useMediaQuery('(max-width:768px)');

    return (
        <Grid item container direction={`${matches ? 'column' : 'row'}`} className='testimonial-section'
            style={{
                backgroundImage: `${bgImage ? `url(${bgImage})` : null}`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            <Grid item container flex={1} direction='column' className='info'>
                <div className="data">
                    <p>{subHeading}</p>
                    <h3>{heading}</h3>
                </div>
            </Grid>
            <Grid item container flex={2} className='tests' justifyContent='center' alignItems='center'>
                <Carousel
                    className="car"
                    indicators={false}
                    autoPlay={false}
                    navButtonsAlwaysVisible
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'transparent',
                        },
                    }}
                    navButtonsWrapperProps={{
                        style: {
                            bottom: matches ? "100px" : '230px',
                            height: 'auto',
                            left: 'unset',
                        },
                    }}
                >
                    {testimonial &&
                        testimonial.map((tests, idx) => {
                            return <div key={idx} className='testimonial'>
                                <div className="image">
                                    <div className="img">
                                        <img width='100%' height='100%' style={{ objectFit: 'cover' }} src={tests.coverImage} alt="" />
                                    </div>
                                    <div className="user">
                                        <p>{tests.name}</p>
                                        <div className="stars">
                                            <StarRatings
                                                rating={tests.rating}
                                                starEmptyColor='#2C2B22'
                                                isSelectable={false}
                                                starRatedColor='#fff'
                                                numberOfStars={5}
                                                name='rating'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="data">
                                    <p>{tests.message}</p>
                                </div>
                            </div>
                        })
                    }
                </Carousel>
            </Grid>
        </Grid >
    )
}

import styled from "styled-components";
import { colors } from '../../UI/Theme'

export const Wrapper = styled.div`
position: relative;
.main-container{
    width: 100%;
    height: 100%;
}
.header{
    /* width: 100%; */
    margin: 0px auto;
    height:90px;
    align-items:center;
    background-image: 
            linear-gradient(${(props) => props.bgColor}, ${(props) => props.bgColor}),
            url(${(props) => props.imgUrl});
    background-size:cover;
    background-position:center ;
    color: white;
    .headings-container{
        margin:0 auto;
        text-align: center;
        p{
            font-size: 16px;
            line-height: 14px;
            font-family:${colors.fonts.commutersBold} ;
        }
        h3{
            font-family:${colors.fonts.garacie} ;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            letter-spacing: 0.02em;
            margin-top:0.5em;
        }
    }
.header-links{
    gap:35px;
    width:auto;
    margin:0px auto;
    a{
        text-decoration:none;
        color:#fff;
    }
}
.link{
    display:flex ;
    justify-content :center;
    align-items:center;
    width: 120px;
    height: 40px;
    border-radius:6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    p{
        color: #fff;
        letter-spacing: 0.02em;
    }
}
.selected{
    display:flex ;
    justify-content :center;
    align-items:center;
    width: 120px;
    height: 40px;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    border-radius:6px;
    p{
        letter-spacing: 0.02em;
        color:${colors.themeColor.color};
    }
}
}
@media only screen and (max-width: 992px) {
    .header{
    height:80px;
    .headings-container{
        p{
            font-size: 14px;
        }
        h3{
            font-size: 20px;
        }
    }
.header-links{
    gap:10px;
    a{
        font-size:14px;
    }
}
.link{  
    width: 110px;
    height: 35px;
}
.selected{
    width: 110px;
    height: 35px;
}
}
}
@media only screen and (max-width: 768px) {
    .header{
    height:70px;
    background-position:center;
    .headings-container{
        p{
            font-size: 10px;
        }
        h3{
            font-size: 16px;
        }
    }
.header-links{
    gap:5px;
    a{
        font-size:12px;
    }
}
.link{  
    width: 90px;
    height: 30px;
}
.selected{
    width: 90px;
    height: 30px;
}
}
}
@media only screen and (max-width: 600px) {
    .header{
    height:70px;
.header-links{
    gap:5px;
    a{
        font-size:10px;
    }
}
.link{  
    width: 75px;
    height: 27px;
}
.selected{
    width: 75px;
    height: 27px;
}
}
}
@media only screen and (max-width: 500px) {
    .header{
    height:60px;
    flex-wrap:wrap;
    .headings-container{
        h3{
            font-size: 14px;
            line-height: 18px;
        }
    }
    .header-links{
    gap:10px;
    a{
        font-size:9px;
        padding:0 5px;
    }
}
.link{  
    width: 63px;
    height: 27px;
}
.selected{
    width: 63px;
    height: 27px;
}
}
}
`
import { useMediaQuery } from '@mui/material';
import React from 'react'
import ReactLoading from 'react-loading'
import { colors } from '../UI/Theme'

export const Loading = () => {
    const matches = useMediaQuery('(max-width:500px)');
    return (
        <div className="App" style={{ minHeight: '76vh', minWidth: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
            <ReactLoading type='bars' color={colors.themeColor.color} height={matches ? 60 : 100} width={matches ? 60 : 100} />
        </div>
    )
}

import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from "./app/store";
import './UI/Commuters/CommutersSans-Regular.otf'
import './UI/Commuters/CommutersSans-SemiBold.otf'
import './UI/Garacie/Garacie.ttf'
import './assets/font/flaticon.css'

const Main = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Main, rootElement);
} else {
  render(Main, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// "postbuild": "react-spa-prerender"

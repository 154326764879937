import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { Wrapper } from './Home/CartStyles'
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from "firebase/functions";
import _ from 'lodash';

import CartContainer from './CartContainer';

const Cart = ({ toggleOpen, setIsOpen }) => {
    const functions = getFunctions();
    const { products } = useSelector(state => state.appReducer)
    const { user } = useSelector(state => state.userReducer)
    const { cart, total_amount, closeCheck } = useSelector(state => state.cartReducer);
    const [loading, setLoading] = useState(false)
    const startCheckout = httpsCallable(functions, 'createStripeCheckout');

    const checkButton = () => {
        let arr = []
        cart.map(item => {
            return products.forEach(element => {
                if (item.id === element.id) {
                    arr.push(element)
                } else return
            });
        })
        if (cart.length === 0) {
            return true
        } else {
            if (arr.length === 0) {
                return console.log(true)
            } else if (arr.length !== cart.length) {
                return true
            }
            let bool;
            arr.map(item => {
                if (item.stock <= 0) {
                    bool = true
                } else {
                    bool = false
                }
            })
            return bool;
        }
    }

    const dispatch = useDispatch()
    const handleClose = () => {
        import('../app/actions/userActions').then(userActions => {
            return dispatch(userActions.close())
        })
        setTimeout(() => {
            toggleOpen();
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.open())
            })
        }, 300);
    }

    const handleBuy = async () => {
        const uid = localStorage.getItem('@auth-token')
        setLoading(true)
        let arr = cart.map((item) => {
            return {
                price_data: {
                    currency: 'aud',
                    unit_amount: (item.price) * 100,
                    product_data: {
                        name: item.name,
                        images: [item.image]
                    },
                },
                quantity: item.quantity,
            }
        });


        let productArr = cart.map((item) => {
            return {
                id: item.id,
                quantity: item.quantity,
                price: item.price,
                selectProgramId: item.selectProgramId
            }
        })

        let shipping = cart.reduce((accumulator, currentValue) => {
            let res = accumulator + currentValue.shippingCost
            return res;
        }, 0)

        const id = cart.map((item) => item.id)


        let successUrl = (window.location.origin + `/payment-success/xV_12907_f8F765s_123/${id.join('&')}`);
        let failedUrl = (window.location.origin + window.location.pathname);
        const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
        startCheckout({ arr, uid, productArr, successUrl, failedUrl, shippingCost: shipping }).then(res => {
            const { id } = res.data;
            import('../app/actions/userActions').then(userActions => {
                return dispatch(userActions.makeFromCartTrue())
            })
            stripePromise.redirectToCheckout({ sessionId: id })
            setTimeout(() => {
                setLoading(false);
            }, 1000)
        }).catch(err => {
            console.log('error', err);
            setLoading(false)
        })
    }





    return (
        <Wrapper>
            <Grid container className='main-container' direction='column'>
                <CartContainer
                    handleClose={handleClose}
                    closeCheck={closeCheck}
                    cart={cart}
                    products={products}
                    toggleOpen={toggleOpen}
                    handleBuy={handleBuy}
                    setIsOpen={setIsOpen}
                    total_amount={total_amount}
                    loading={loading}
                    checkButton={checkButton}
                    user={user}
                />
            </Grid>
        </Wrapper >
    )
}
export default Cart;

import { Alert, Grid, Snackbar } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import {
    collection,
    doc,
    setDoc,
} from "firebase/firestore";
import ReactLoading from 'react-loading';
import { db } from '../../firebase-config';
export const NewsLetter = ({ bgColor, data }) => {
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
    })
    const [loading, setLoading] = useState(false)
    const handleChange = (value, key) => {
        setState({ ...state, [key]: value })
    }

    const addDoc = async () => {
        setLoading(true)
        if (state.email === '' || state.firstName === '' || state.lastName === '') {
            setLoading(false)
            return
        }
        try {
            const newCityRef = doc(collection(db, "Newsletter"));
            await setDoc(newCityRef, { ...state, createdAt: new Date() });

            // const newCityRef = doc(db, "Newsletter",);
            // await setDoc(newCityRef, state);
            handleClick();
            setState({ ...state, firstName: '', lastName: '', email: '' });
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }


    const handleClick = () => {
        console.log('rsdfhgjkn');
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Grid item container className='footer' style={{ backgroundColor: `${bgColor ? `${bgColor}` : '#F2C5D5'}` }}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Registration Successful
                </Alert>
            </Snackbar>
            <div className="container">
                <div className="left">
                    <div className="image">
                        <img src={data[0].url} alt="" />
                    </div>
                    <div className="line" style={{ background: `${data[0].textColor ? data[0].textColor : '#fff'}` }} />
                    <div className="data">
                        <p style={{ color: `${data[0].textColor ? data[0].textColor : '#fff'}` }}>{data[0].heading}</p>
                        <p style={{ color: `${data[0].textColor ? data[0].textColor : '#fff'}` }} className="para">{data[0].subHeading}</p>
                    </div>
                </div>
                <div className="right">
                    <div className="form">
                        <input type="text" value={state.firstName}
                            onChange={(e => handleChange(e.target.value, 'firstName'))}
                            placeholder="First Name" className='input' />
                        <input type="text"
                            onChange={(e => handleChange(e.target.value, 'lastName'))}
                            value={state.lastName} placeholder="Last Name" className='input' />
                        <input type="text"
                            onChange={(e => handleChange(e.target.value, 'email'))}
                            placeholder="Email" value={state.email} className='input' />
                        <button className="submit" onClick={addDoc} style={{ color: `${data[0].textColor ? data[0].textColor : '#fff'}` }}>
                            {loading ?
                                <ReactLoading type='spin' color='#fff' height={30} width={30} />
                                :
                                `${data[0].btnText}`
                            }
                        </button>
                    </div>
                </div>
            </div>
        </Grid>
    )
}

import styled from "styled-components";
import { colors } from "../../UI/Theme";

export const Wrapper = styled.div`
border-top:1px solid rgba(0, 0, 0, 0.1) ;
  position: relative;
  .main-container {
    padding: 4em 1.5em 2em;
    background-color: aliceblue;
    .container {
      display: flex;
      padding: 0 8vw;
      gap: 5px;
      h2 {
        font-family: ${colors.fonts.garacie};
      }
      /* .storySection {
        flex: 2;
        overflow :hidden ;
        .content {
          margin-top: 1em;
          color: grey;
        }
      } */
      /* .logoSection {
        padding: 0 15px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 45%;
        }
      } */
      .linksSection {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .heading {
          width: 100%;
          h2{
            line-height:32px;
        }
        }
        .links {
          margin-top: 1em;
          width: 100%;
          display: flex;
          flex-direction: column;
          /* align-items:center ; */
          gap: 1em;
          a {
            color: grey;
          }
        }
      }
      .contactSection {
        flex: 1;
        .content-container {
          margin-top:1em ;
          display: flex;
          flex-direction:column ;
          gap:1em;
          .data {
            display: flex;
            gap: 15px;
            color:grey ;
            align-items: center;
            .address{
              font-size:45px ;
            }
            .mail{
              font-size:30px ;
            }
            .bold{
              font-weight:700 ;
              color:#000 ;
            }
          }
          .socials{
            margin-top:10px ;
            font-weight:600 ;
          }
          .icons-container{
            display: flex;
            gap:20px;
            margin-top:10px ;
            align-items: center;
            .social-icons{
              font-size:24px ;
            }
          }
        }
      }
    }

    .line{
      width:100% ;
      height:1px ;
      margin:2em 0 ;
      background-color:rgba(0, 0, 0, 0.1) ;
    }
    .copyrightSection{
      display: flex;
      /* justify-content:space-between ; */
      justify-content:space-between;
      align-items: center;
      .copyright-container{
        font-size:15px;
        font-weight:700 ;
        color:grey ;
      }
      .flex,.icons-container,.btn-icon{
        display: flex;
        align-items: center;
      }
      .flex{
        gap:1em;
        padding-right:2em ;
        p{
          color:#000;
        }
        .icons-container{
          gap:0.5em;
          .btn-icon{
            background-color:#000 ;
            padding:5px 3px ;
            border-radius:7px ;
            .apple{
              font-size:30px ;
              color: #fff;
            }
            .playIcon{
              width:20%;
            }
            .verticalContent{
              display: flex;
              flex-direction:column ;
              align-items: center;
              .download{
                color:grey;
                font-weight:100 ;
                font-size:12px ;
              }
              .store{
                color:#fff;
              }
            }
          }
          .size{
            padding-right:10px ;
          }
        }
      }
    }


    @media only screen and (max-width: 1100px) {
      padding: 3em 1.5em 2em;
    .container {
      .logoSection {
        img {
          width: 180px;
        }
      }
      
      .contactSection {
        flex: 1.6;
        .content-container {
          margin-top:1em ;
          display: flex;
          flex-direction:column ;
          gap:1em;
          .data {
            display: flex;
            gap: 15px;
            color:grey ;
            align-items: center;
            .address{
              font-size:85px ;
            }
          }
        }
      }
    }
    }
    @media only screen and (max-width: 993px) {
      padding: 4em 1.5em 2em;
    .container {
        flex-direction :column;
        gap: 4em;
      h2 {
        text-align:center ;
        font-size:20px ;
      }
      .storySection {
        overflow :visible ;
        .content {
          text-align: center;
        }
      }
      .logoSection {
        img {
          width: 100px;
        }
      }
      .linksSection {
        justify-content: center;
        .heading {
          width: 100%;
        }
        .links {
          align-items:center ;
        }
      }
      .contactSection {
        .content-container {
        align-items :center ;
          .data {
            gap: 10px;
            color:grey ;
            font-size:14px ;
            align-items: center;
            /* padding: 0 5em ; */
            text-align:center ;
            .address{
              font-size:30px ;
            }
          }
          .socials{
            margin-top:10px ;
            justify-content: center;
            text-align:center ;
          }
        }
      }
    }

    .line{
      width:100% ;
      height:1px ;
      margin:2em 0 ;
      background-color:rgba(0, 0, 0, 0.1) ;
    }
    /* .copyrightSection{
      display: flex;
      justify-content:space-between ;
      align-items: center;
      .copyright-container{
        font-size:15px;
        font-weight:700 ;
        color:grey ;
      }
    } */


    .copyrightSection{
      display: flex;
      justify-content:space-between;
      .copyright-container{
        font-size:14px;
      }
      .flex{
        flex-direction:column ;
        gap:0.4em;
        padding-right:0em ;
      }
    }




  }
    @media only screen and (max-width: 768px) {
      padding: 3em 1.5em 2em;
    .container {
      h2 {
        font-size:19px ;
      }
      .logoSection {
        img {
          width: 200px;
        }
      }
      .storySection {
        .content {
          font-size:14px ;
        }
      }
      .linksSection {
        .links {
          font-size:14px ;
        }
      }
      .contactSection {
        .content-container {
          .data {
            .address{
              font-size:40px ;
            }
          }
        }
      }
    }
    .copyrightSection{
      flex-direction:column ;
      gap:1em;
      .copyright-container{
        font-size:13px;
      }
    }
    }
    @media only screen and (max-width: 500px) {
      padding: 2em 1.5em ;
    .container {
      gap:3em ;
      .storySection {
        .content {
          text-align:justify ;
        }
      }
      .logoSection {
        img {
          width: 70px;
        }
      }
      .contactSection {
        .content-container {
          .data {
            .address{
              font-size:55px ;
            }
          }
        }
      }
    }
    .copyrightSection{
      flex-direction:column ;
      gap:1em;
      .copyright-container{
        font-size:13px;
      }
    }
    
    }

  }
`;
